import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import WindowLayoutOutlet from "./components/WindowLayoutOutlet.jsx";

import Login from "./components/Login.jsx";
import Logout from "./components/Logout.jsx";
import SignUp from "./components/SignUp.jsx";
import NewOrder from "./components/NewOrder.jsx";
import AddSolution from "./components/AddSolution.jsx";
import OrderHistory from "./components/OrderHistory.jsx";
import AdminAddProfile from "./components/AdminAddProfile.jsx";
import SupportOrders from "./components/SupportOrders.jsx";
import Invoice from "./components/Invoice.jsx";
import FaultFinder from "./components/FaultFinder.jsx";
import InvoiceHistory from "./components/InvoiceHistory.jsx";
import LandingPage from "./components/LandingPage.jsx";
import AdminDashboard from "./components/AdminDashboard.jsx";
import FaultFinderData from "./components/FaultFinderData.jsx";
import AddFaultSolution from "./components/AddFaultSolution.jsx";
import AllSolution from "./components/AllSolution.jsx";
import EditAllSolution from "./components/EditAllSolution.jsx";
import AddSpecificFaultSolution from "./components/AddSpecificFaultSol.jsx";
import SellorBuy from "./components/SellorBuy.jsx";
import SpecificFaultData from "./components/SpecificFaultData.jsx";
import FaultFinderPublic from "./components/FaultFinderPublic.jsx";
import UserFaultFinder from "./components/UserFaultFinder.jsx";
import Payment from "./components/PaymentSidebar.jsx";
import ResetPassword from "./components/ResetPassword.jsx";
import ForgotPassword from "./components/ForgotPassword.jsx";
import ShopUserWebsite from "./components/ShopUserWebsite.jsx";
import ShopFaultData from "./components/ShopFaultData.jsx";
import ShopFaultRemark from "./components/ShopFaultRemark.jsx";
import OrderData from "./components/OrderData.jsx";
import ViewQuotation from "./components/ViewQuotation.jsx";
import ShopCredit from "./components/ShopCredit.jsx";

function App() {
  console.log(process.env);

  const [roleId, setRoleId] = useState(0);
  const [name, setName] = useState("");
  const [meetLink, setMeetLink] = useState("");
  const [isTrainer, setIsTrainer] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  useEffect(() => {
    // const trainerValue = localStorage.getItem("isTrainer");
    const paymentStatus = localStorage.getItem("paymentStatus");
    setPaymentStatus(paymentStatus === "true");
    // console.log(isTrainer);
  }, []);
  return (
    <Router>
      <Routes>
        {/* <Route path='/' element={< Login setRoleId={setRoleId} setName={setName} setMeetLink={setMeetLink} />}></Route> */}
        <Route path="/" element={<LandingPage />}></Route>
        {/* <Route path='publicFaultFinder' element={< FaultFinderPublic/>}/> */}
        <Route
          path="/login"
          element={
            <Login
              setRoleId={setRoleId}
              setName={setName}
              setIsTrainer={setIsTrainer}
              setPaymentStatus={setPaymentStatus}
            />
          }
        ></Route>
        <Route
          path="/shoplogin"
          element={
            <Login
              setRoleId={setRoleId}
              setName={setName}
              setIsTrainer={setIsTrainer}
              setPaymentStatus={setPaymentStatus}
            />
          }
        ></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="/signup" element={<SignUp />}></Route>
        <Route path="/visit-shop/:id" element={<ShopUserWebsite />} />
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route
          path="/forgot-password/:id/:token"
          element={<ForgotPassword />}
        ></Route>
        <Route
          path="/"
          element={
            <WindowLayoutOutlet
              roleId={roleId}
              name={name}
              meetLink={meetLink}
            />
          }
        >
          {/* For creating an order */}
          <Route path="newOrder" element={<NewOrder />} />
          {/* Admin Routes */}
          <Route path="adminDashboard" element={<AdminDashboard />} />
          <Route path="faultFinderData" element={<FaultFinderData />} />
          <Route path="addFaultSolution" element={<AddFaultSolution />} />
          <Route path="allSolution" element={<AllSolution />} />
          <Route path="editAllSolution" element={<EditAllSolution />} />
          <Route
            path="addSpecificFaultSolution"
            element={<AddSpecificFaultSolution />}
          />
          <Route path="SpecificFaultData" element={<SpecificFaultData />} />
          {/* Customer Support Pages */}
          <Route path="supportOrders" element={<SupportOrders />} />
          <Route path="FaultFinder" element={<FaultFinder />} />
          <Route path="shopFaultData" element={<ShopFaultData />} />
          <Route path="shopFaultRemark" element={<ShopFaultRemark />} />
          <Route path="Payment" element={<Payment />} />
          {/* {paymentStatus && (
            <> */}
          <Route path="Invoice" element={<Invoice />} />
          <Route path="InvoiceHistory" element={<InvoiceHistory />} />
          <Route path="SellorBuy" element={<SellorBuy />} />
          {/* </>
          )} */}
          <Route
            path="addSolution/:orderId/:contactNumber/:name"
            element={<AddSolution />}
          />
          <Route path="orders" element={<OrderHistory />} />
          <Route path="orderData" element={<OrderData />} />
          <Route path="viewQuotation" element={<ViewQuotation />} />
          <Route path="shopCredit" element={<ShopCredit />} />
          {/* Admin Page */}
          <Route
            path="addProfile"
            roleId={roleId}
            element={<AdminAddProfile />}
          />
          <Route path="userFaultFinderData" element={<UserFaultFinder />} />

          {/* <Route path='publicFaultFinder' element={< FaultFinderPublic/>}/> */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
