import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-tabulator/lib/styles.css";
import { ReactTabulator } from "react-tabulator";
import { Navigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
const ViewQuotation = () => {
  const [data, setData] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { rowData } = location.state;
  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticationToken");

    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/solution/getSolutionList";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

    if (loggedInUser) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: API,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
        data: {
          orderId: rowData.orderId,
        },
      };
      axios
        .request(config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.length === 0) {
            setShowSuccessModal(true);
          }
          setData(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      return <Navigate replace to="/login" />;
    }
  }, []);
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/orderData");
  };
  const columns = [
    { title: "S_No.", formatter: "rownum", hozAlign: "left", width: 40 },

    {
      title: "Problem_Desc",
      field: "problemDesc",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Detail_Solution",
      field: "detailSolution",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Estimated_Price",
      field: "estimatedPrice",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Shop_Name",
      field: "shopName",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Shop_Email",
      field: "shopEmail",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
  ];

  return (
    <div>
      <ReactTabulator
        data={data}
        columns={columns}
        options={{
          layout: "fitColumns",
          pagination: "local", // Enable pagination
          paginationSize: 15, // Number of rows per page
          movableColumns: true, // Allow columns to be reordered
          paginationCounter: "rows",
          tooltips: true,
          resizableColumnWidths: false, // Set to false to prevent resize errors
          resizableRowWidths: false,
          movableRows: true,
          resizableColumns: true,
          // resizableRows: true,
          resizableColumnFit: true,
        }}
      />
      <Modal
        show={showSuccessModal}
        onHide={handleCloseSuccessModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "darkgreen" }}>
            View Quotation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#f0f0f0" }}>
          <p className="fw-bolder fs-5 text-cyan-600">No Quotation Found.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-outline-dark fw-semibold"
            onClick={handleCloseSuccessModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ViewQuotation;
