import React from "react";
import waveImg from "../../assets/customersupprt_icon.png";
import customerSupportImg from "../../assets/customersupprt_icon.png";
import googleMeet from "../../assets/googleMeet.png";
import phoneNumber from "../../assets/phoneNumber.png";
import whatsApp from "../../assets/whatsApp.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

export default function CustomerSupport({ orderId }) {
  const [orderDetails, setOrderDetails] = useState({});

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticationToken");

    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/orders/getOrdersBy";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    const data = JSON.stringify({
      orderId: orderId,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loggedInUser}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then(function (response) {
        console.log(response.data);
        setOrderDetails(response.data[0]);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [orderId]);

  return (
    <div className="p-15 text-xl">
      <div className="grid grid-cols-2 pb-10 grid-flow-col gap-4">
        <div className="col-span-2 border border-1 border-gray-300 bg-gray-100 rounded-lg">
          <div
            className="text-xl py-5  px-5 flex"
            style={{ alignItems: "center" }}
          >
            <div className="" style={{ flex: "1" }}>
              <div className="flex">
                <div className="text-2xl">Hello, {orderDetails.name} </div>
                <span>
                  <img className="w-22 ml-2" src={waveImg} alt="waveImg" />
                </span>
              </div>
              I am {orderDetails.supportName} from support team!
            </div>
            <span>
              <img
                className="w-22"
                src={customerSupportImg}
                alt="customerSupportImg"
              ></img>
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-col text-xl text-black-400 py-5 px-1">
        Connecting by
      </div>
      <div className="grid grid-cols-3 gap-0 pb-16 items-center">
        <div className="  col col-12  ">
          <button
            type="button"
            className=' px-5 py-4  font-medium border-1  bg-gray-100 
             rounded-lg border-1 border-gray-300 hover:bg-slate-70 hover:text-indigo-500 border hover:border-indigo-500 transition 
             duration-2oo ease-in-out"'
          >
            <img className="w-22" src={googleMeet} alt="googleMeet"></img>
            <span className="text-xs">{orderDetails.supportMeetLink} </span>
            <link></link>
          </button>
        </div>
        <div className=" col col-12 ">
          <button
            className='px-5 py-4   font-medium border-1  bg-gray-100 
             rounded-lg border-1 border-gray-300 hover:bg-slate-70 hover:text-indigo-500 border hover:border-indigo-500 transition 
             duration-2oo ease-in-out"'
          >
            <img className="w-22" src={phoneNumber} alt="phoneNumber"></img>
            <span className=""> {orderDetails.supportContactNumber} </span>
          </button>
        </div>
        <div className=" col col-12 ">
          <button
            className=' px-5 py-4  font-medium border-1  bg-gray-100 
             rounded-lg border-1 border-gray-300 hover:bg-slate-70 hover:text-indigo-500 border hover:border-indigo-500 transition 
             duration-2oo ease-in-out"'
          >
            <img className="w-22" src={whatsApp} alt="whatsApp"></img>
            <span className=""> {orderDetails.supportContactNumber} </span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-3  gap-5">
        <div className="col-span-2 border border-1 border-gray-300 bg-gray-100 p-5">
          <div className="text-2xl ">Personal Information</div>
          <div className="grid grid-cols-2 p-5  gap-2 grid-rows-4">
            <div className="col-start-1 row-start-1">Name:</div>
            <div className="col-start-2 row-start-1">{orderDetails.name}</div>
            <div className="col-start-1 row-start-2">Email Address:</div>
            <div className="col-start-2 row-start-2">{orderDetails.email}</div>
            <div className="col-start-1 row-start-3">Phone:</div>
            <div className="col-start-2 row-start-3">
              {orderDetails.userContactNumber}
            </div>
            <div className="col-start-1 row-start-4">Address:</div>
            <div className="col-start-2 row-start-4">
              {orderDetails.userAddress} - {orderDetails.zipcode}
            </div>
            <div className="col-start-1 row-start-5">Device Model:</div>
            <div className="col-start-2 row-start-5">
              {orderDetails.mobileBrand}
            </div>
          </div>
        </div>

        <div className="p-5 border border-1 border-gray-300 bg-gray-100">
          <div className="text-2xl pb-2">Your Queries</div>
          <div className=""> {orderDetails.userDeviceIssue} </div>
        </div>
        {/* <button
          className=" col-start-3 p-5 bg-blue-800 text-white uppercase py-5 px-10 rounded-xs font-semibold 
                    cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hower:text-white transition 
                    duration-2oo ease-in-out inset-y-0 right-0"
        >
          Continue
        </button> */}
      </div>
    </div>
  );
}
