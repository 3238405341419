import React from "react";
import { NavLink } from "react-router-dom";

export default function SidebarItem({ path, desc, children, tooltip }) {
  const to = `/${path}`;
  return (
    <div>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? "space-x items-center text-white flex bg-blue-500 sidebarLink"
            : "space-x items-center text-white flex sidebarLink"
        }
        to={to}
        title={tooltip}
      >
        {children}
        {desc}
      </NavLink>
    </div>
  );
}
