import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Invoice = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    address: "",
    phoneNumber: "",
    mobileBrandModel: "",
    imeiNumber1: "",
    imeiNumber2: "",
    faultDetails: "",
    costOfRepairing: "",
    aadhaarCard: null,
    consentForm: null,
    customerPhoto: null,
    shopId: localStorage.getItem("Id"),
    orderId: "",
    emailId: "",
  });
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const handleFileRead = async (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      [name]: file,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const isFormValid = () => {
    const requiredFields = [
      "fullName",
      "address",
      "phoneNumber",
      "mobileBrandModel",
      "imeiNumber1",
      "imeiNumber2",
      "faultDetails",
      "costOfRepairing",
      "aadhaarCard",
      "consentForm",
      "customerPhoto",
      "orderId",
      "emailId",
    ];

    return requiredFields.every((field) => formData[field]);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsButtonPressed(true);
    if (!isFormValid()) {
      toast.error("Please fill in all the required fields.");
      setIsButtonPressed(false);
      return;
    }
    // console.log(formData);
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    try {
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/invoices/upload";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.post(API, formDataToSend, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response.data);
      alert("Invoice created successfully");
      setFormData({
        fullName: "",
        address: "",
        phoneNumber: "",
        mobileBrandModel: "",
        imeiNumber1: "",
        imeiNumber2: "",
        faultDetails: "",
        costOfRepairing: "",
        aadhaarCard: null,
        consentForm: null,
        customerPhoto: null,
        orderId: "",
        emailId: "",
      });
      event.target.elements["aadhaarCard"].value = null;
      event.target.elements["consentForm"].value = null;
      event.target.elements["customerPhoto"].value = null;
      setIsButtonPressed(false);
    } catch (error) {
      console.error("Error:", error);
      setIsButtonPressed(false);
      alert("Error creating invoice. Please try again.");
      // setFormData({
      //   fullName: "",
      //   address: "",
      //   phoneNumber: "",
      //   mobileBrandModel: "",
      //   imeiNumber1: "",
      //   imeiNumber2: "",
      //   faultDetails: "",
      //   costOfRepairing: "",
      //   aadhaarCard: null,
      //   consentForm: null,
      //   customerPhoto: null,
      //   orderId: "",
      //   emailId: "",
      // });
      // event.target.elements["aadhaarCard"].value = null;
      // event.target.elements["consentForm"].value = null;
      // event.target.elements["customerPhoto"].value = null;
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // You can handle form submission here, such as sending data to an API
  //   console.log(formData);
  //   const formDataToSend = new FormData();
  //   for (const key in formData) {
  //     formDataToSend.append(key, formData[key]);
  //   }
  //   // try {
  //   //   const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
  //   //   const API_ENDPOINT = "/api/invoices";
  //   //   const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
  //   //   // const response = await axios.post(API, formData);
  //   //   const response = await axios.post(
  //   //     "http://localhost:5002/api/invoices/",
  //   //     formDataToSend
  //   //   );
  //   //   console.log(response.data); // Log response from the server
  //   //   alert("Invoice created successfully");
  //   // } catch (error) {
  //   //   console.error("Error:", error);
  //   //   alert("Error:", error);
  //   // }

  //   try {
  //     const response = await axios.post(
  //       "http://localhost:5002/api/upload",
  //       formDataToSend,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     console.log(response.data);
  //     alert("Files uploaded successfully");
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert("Error:", error.message);
  //   }
  //   // alert("invoice created")
  //   // Optionally, reset the form after successful submission
  //   setFormData({
  //     fullName: "",
  //     address: "",
  //     phoneNumber: "",
  //     mobileBrandModel: "",
  //     imeiNumber1: "",
  //     imeiNumber2: "",
  //     faultDetails: "",
  //     costOfRepairing: "",
  //     aadhaarCard: null,
  //     consentForm: null,
  //     customerPhoto: null,
  //   });
  //   e.target.elements["aadhaarCard"].value = null;
  //   e.target.elements["consentForm"].value = null;
  //   e.target.elements["customerPhoto"].value = null;
  // };

  return (
    <>
      <form onSubmit={handleSubmit} className="invoice-divs">
        <h3 className="text-center text-2xl lg:text-3xl font-semibold mb-6">
          Fill all the details
        </h3>
        <div className="grid gap-6">
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label  sm:w-1/3 lg:w-1/4">
              Full Name:
            </label>

            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">Address:</label>
            <input
              type="text"
              name="address"
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">
              Phone Number:
            </label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">Email ID:</label>
            <input
              type="text"
              name="emailId"
              value={formData.emailId}
              onChange={handleChange}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">Order ID:</label>

            <input
              type="text"
              name="orderId"
              value={formData.orderId}
              onChange={handleChange}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">
              Mobile Brand & Model:
            </label>
            <input
              type="text"
              name="mobileBrandModel"
              value={formData.mobileBrandModel}
              onChange={handleChange}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">
              IMEI Number 1:
            </label>
            <input
              type="text"
              name="imeiNumber1"
              value={formData.imeiNumber1}
              onChange={handleChange}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">
              IMEI Number 2:
            </label>
            <input
              type="text"
              name="imeiNumber2"
              value={formData.imeiNumber2}
              onChange={handleChange}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">
              Briefly describe the issue/fault:
            </label>
            <textarea
              name="faultDetails"
              value={formData.faultDetails}
              onChange={handleChange}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">
              Cost of Repairing:
            </label>
            <input
              type="text"
              name="costOfRepairing"
              value={formData.costOfRepairing}
              onChange={handleChange}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">
              Aadhaar Card:
            </label>
            <input
              type="file"
              accept="image/jpeg"
              name="aadhaarCard"
              onChange={(e) => handleFileRead(e)}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">
              Consent Form:
            </label>
            <input
              type="file"
              accept="image/jpeg"
              name="consentForm"
              onChange={(e) => handleFileRead(e)}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex flex-col sm:flex-row items-center">
            <label className="invoice-label sm:w-1/3 lg:w-1/4">
              Customer Photo:
            </label>
            <input
              type="file"
              accept="image/jpeg"
              name="customerPhoto"
              onChange={(e) => handleFileRead(e)}
              required
              className="flex-grow mt-1 sm:mt-0 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 justify-center items-center mt-6">
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            <a
              href="https://zipnet.delhipolice.gov.in/index.php?page=missing_mobile_phones"
              target="_blank"
              rel="noopener noreferrer"
              className="anchor"
            >
              IMEI Checker 1
            </a>
          </button>
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            <a
              href="https://www.ceir.gov.in/Home/index.jsp"
              target="_blank"
              rel="noopener noreferrer"
              className="anchor"
            >
              IMEI Checker 2
            </a>
          </button>
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.app.cdot.KYM&pli=1"
              target="_blank"
              rel="noopener noreferrer"
              className="anchor"
            >
              Download App
            </a>
          </button>
        </div>
        <div className="flex justify-center mt-6">
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            style={{
              pointerEvents: isButtonPressed ? "none" : "auto",
              backgroundColor: isButtonPressed ? "grey" : "",
            }}
          >
            Submit
          </button>
        </div>
      </form>
      <ToastContainer />
    </>
  );
};

export default Invoice;
