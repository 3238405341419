import React from "react";

const StepperControl=({handleClick,currentStep,steps})=>{
    return <div className="container flex justify-around mt-2 mb-8">
        {/**backbutton */}
        <button 
        onClick={()=>handleClick()}
        className={`bg-blue-800 text-white uppercase py-2 px-4 rounded-xl font-semibold 
        cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hower:text-white transition 
        duration-2oo ease-in-out
        ${currentStep===1?"opacity-50 cursor-not-allowed":""}
        `}>
            Back
        </button>

        {/**next button */}
        <button
         onClick={()=>handleClick("next")}
         id="next-btn"
          className="bg-blue-800 text-white uppercase py-2 px-4 rounded-xl font-semibold 
        cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hower:text-white transition 
        duration-2oo ease-in-out"
        >
        {currentStep===steps.length-1?"Available Shops":"Next"}    
        </button>
    </div>
};

export default StepperControl;
