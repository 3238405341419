import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";



export default function Details({ orderId }) {
    
    const [orderDetails, setOrderDetails] = useState({});
    
    useEffect(() => {
        const loggedInUser = localStorage.getItem("authenticationToken");

        const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`
        const API_ENDPOINT = "/api/orders/getOrdersBy"
        const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`

        const data = JSON.stringify({
            "orderId": orderId
        });

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: API,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedInUser}`
            },
            data: data
        };

        axios.request(config).then(function (response) {
            console.log(response.data);
            setOrderDetails(response.data[0]);

        }).catch(function (error) {
            console.error(error);
        });
    }, [orderId]);

    return (
        <div>
            <div className="grid grid-cols-3 gap-16 text-xl grid-flow-row">
                <div className="col-start-2 col-span-2 bg-blue-800 p-5 rounded-tl-3xl rounded-bl-3xl rounded-br-3xl text-white">
                <div className="text-2xl underline mb-1">
                        Order Details:
                    </div>
                    <div className="grid grid-cols-2 p-2 pb-6 gap-2 grid-rows-4">
                        <div className="col-start-1 row-start-1"> Name: </div>
                        <div className="col-start-2 row-start-1"> {orderDetails.name} </div>
                        <div className="col-start-1 row-start-2"> Email Address: </div>
                        <div className="col-start-2 row-start-2"> {orderDetails.email} </div>
                        <div className="col-start-1 row-start-3"> Phone: </div>
                        <div className="col-start-2 row-start-3"> {orderDetails.userContactNumber} </div>
                        <div className="col-start-1 row-start-4"> Address: </div>
                        <div className="col-start-2 row-start-4"> {orderDetails.userAddress} - {orderDetails.zipcode} </div>
                        <div className="col-start-1 row-start-5"> Device Model: </div>
                        <div className="col-start-2 row-start-5"> {orderDetails.mobileBrand} </div>
                    </div>
                    <div className="text-2xl underline mb-1">
                        Device Issue:
                    </div>
                    <div> {orderDetails.userDeviceIssue} </div>
                </div>

                <div className="col-start-1 text-xl col-span-2 bg-lime-500 p-5 text-black  rounded-tr-3xl rounded-bl-3xl rounded-br-3xl">
                    <div className="text-2xl underline mb-1">
                        Solution:
                    </div>
                    <div>
                        {(orderDetails.supportFeedback)?orderDetails.supportFeedback:"Your RMM Support Expert's Comments will appear here after your meeting." }
                    </div>
                </div>

                <div>
                </div>
            </div>
        </div>
    )
}