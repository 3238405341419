import React from "react";
import { Navigate } from "react-router-dom";

export default function Logout() {
  if (localStorage.getItem("authenticated")) {
    localStorage.removeItem("authenticated");
    localStorage.removeItem("authenticationToken");
    localStorage.clear();
  }
  return <Navigate replace to="/" />;
}
