import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";
import { PhoneArrowDownLeftIcon } from "@heroicons/react/24/solid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";
export default function AddSolution() {
  const { orderId, contactNumber, name } = useParams();
  const navigate = useNavigate();
  const [price, setPrice] = useState(0.0);
  const [problemDescription, setProblemDescription] = useState("");
  const [problemSolution, setProblemSolution] = useState("");
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const loggedInUser = localStorage.getItem("authenticationToken");
  const getCreditDetails = async () => {
    setLoading(true);
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/shop/credit";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    axios
      .get(API, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "authenticationToken"
          )}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        if (response.data.credit < 10) {
          setShowCreditModal(true);
          setLoading(false);
        } else {
          setLoading(false);
          handleSubCredit();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Failed to get credit balance");
        setLoading(false);
      });
  };

  const handleCloseCreditModal = () => {
    setShowCreditModal(false);
    navigate("/orders");
  };

  const handleSubCredit = () => {
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/shop/subtractCredit";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

    const body = {
      creditToSubtract: 10,
    };
    axios
      .put(API, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "authenticationToken"
          )}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        addSolutionToOrder();
      })
      .catch((error) => {
        console.error("Failed to subtract credit:", error);
      });
  };

  //   function addSolutionToOrder() {
  //     const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
  //     const API_ENDPOINT = "/api/orders/modify/";
  //     const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: API,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${loggedInUser}`,
  //       },
  //       data: {
  //         orderId: orderId,
  //         supportFeedback: `Diagnosis of Probelem: ${problemDescription} - Recommended Solution: ${problemSolution} - Approximate Cost: ${price}`,
  //       },
  //     };

  //     axios
  //       .request(config)
  //       .then(function (response) {
  //         console.log(response.data);
  //         alert("Solution and price has been added to the order details.");
  //         // navigate(`/supportOrders`)
  //       })
  //       .catch(function (error) {
  //         console.error(error);
  //       });
  //   }
  const sendSolution = () => {
    // const url =
    //   "https://live-server-100184.wati.io/api/v1/sendTemplateMessages";
    // const options = {
    //   method: "POST",
    //   headers: {
    //     "content-type": "text/json",
    //     Authorization:
    //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5MTA1YmE5Ny1mYTg3LTRjN2UtODJmZi1kOWNkMzM5MzkyZmMiLCJ1bmlxdWVfbmFtZSI6ImRldi5yZXBhaXJteW1vYmlsZUBnbWFpbC5jb20iLCJuYW1laWQiOiJkZXYucmVwYWlybXltb2JpbGVAZ21haWwuY29tIiwiZW1haWwiOiJkZXYucmVwYWlybXltb2JpbGVAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDgvMTUvMjAyMyAxMjowNToyOCIsImRiX25hbWUiOiIxMDAxODQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.sH0AG-rtpPUR-PJxZiG6wx_gHkRwdI4Q_ZuFSDRsSyE",
    //   },
    //   body: JSON.stringify({
    //     broadcast_name: "test",
    //     template_name: "test",
    //     receivers: [{ whatsappNumber: "8409213373" }],
    //     source: [{ whatsappNumber: "9123293062" }],
    //   }),
    // };
    // fetch(url, options)
    //   .then((res) => res.json())
    //   .then((json) => console.log(json))
    //   .catch((err) => console.error("error:" + err));
    // const url =
    //   "https://live-server-100184.wati.io/api/v1/sendTemplateMessage?whatsappNumber=919123293062";
    // const options = {
    //   method: "POST",
    //   headers: {
    //     "content-type": "text/json",
    //     Authorization:
    //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5MTA1YmE5Ny1mYTg3LTRjN2UtODJmZi1kOWNkMzM5MzkyZmMiLCJ1bmlxdWVfbmFtZSI6ImRldi5yZXBhaXJteW1vYmlsZUBnbWFpbC5jb20iLCJuYW1laWQiOiJkZXYucmVwYWlybXltb2JpbGVAZ21haWwuY29tIiwiZW1haWwiOiJkZXYucmVwYWlybXltb2JpbGVAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDgvMTUvMjAyMyAxMjowNToyOCIsImRiX25hbWUiOiIxMDAxODQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.sH0AG-rtpPUR-PJxZiG6wx_gHkRwdI4Q_ZuFSDRsSyE",
    //   },
    //   body: JSON.stringify({
    //     broadcast_name: "skillcamreview",
    //     template_name: "skillcamreview",
    //   }),
    // };

    // fetch(url, options)
    //   .then((res) => res.json())
    //   .then((json) => console.log(json))
    //   .catch((err) => console.error("error:" + err));
    // const fetch = require("node-fetch");

    const url =
      // "https://live-server-100184.wati.io/api/v1/sendTemplateMessages";
      "https://live-mt-server.wati.io/329176/api/v1/sendTemplateMessages";
    const options = {
      method: "POST",
      headers: {
        "content-type": "text/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwZTE2NzliZS0zNmI4LTQyNGEtYWUyZi02ZjU3OTc0ZWFiNTIiLCJ1bmlxdWVfbmFtZSI6InJlcGFpcm15bW9iaWxlLmNvbUBnbWFpbC5jb20iLCJuYW1laWQiOiJyZXBhaXJteW1vYmlsZS5jb21AZ21haWwuY29tIiwiZW1haWwiOiJyZXBhaXJteW1vYmlsZS5jb21AZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDgvMDEvMjAyNCAxMDowODoxOCIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMjkxNzYiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.8sZtxVBxpI0soTwwOOgT-RnAb3gEqD69G3-AIgVK_AY",
      },
      body: JSON.stringify({
        broadcast_name: "test",
        receivers: [
          {
            whatsappNumber: `${contactNumber}`,
            customParams: [
              { name: "name", value: "User" },
              { name: "quote_price", value: price },
              { name: "shop", value: localStorage.getItem("shopName") },
              { name: "shop_contact", value: localStorage.getItem("contactNumber") },
              {
                name: "shop_address",
                value: localStorage.getItem("shopAddress"),
              },
            ],
          },
        ],
        template_name: "apisol2",
      }),
    };

    fetch(url, options)
      .then((res) => res.json())
      .then((json) => console.log(json))
      .catch((err) => console.error("error:" + err));
    // axios
    //   .get(url, options)
    //   .then((response) => {
    //     // Handle the successful response
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     // Handle errors
    //     console.error("Error:", error);
    //   });
  };

  function addSolutionToOrder() {
    sendSolution();

    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/solution/create/";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

    let data = JSON.stringify({
      orderId: orderId,
      estimatedPrice: price,
      detailSolution: problemSolution,
      problemDesc: problemDescription,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loggedInUser}`,
      },
      //   data: {
      //     orderId: orderId,
      //     supportFeedback: `Diagnosis of Probelem: ${problemDescription} - Recommended Solution: ${problemSolution} - Approximate Cost: ${price}`,
      //   },
      data: data,
    };

    axios
      .request(config)
      .then(function (response) {
        console.log(response.data);
        alert("Solution and price has been added to the order details.");

        setPrice(0.0);
        setProblemDescription("");
        setProblemSolution("");
        navigate(`/supportOrders`);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  // console.log(orderId,location)
  return (
    <>
      {loading && <Loader />}

      <div className="space-y-2">
        <div className="text-3xl lg:text-4xl p-5">
          Send Your Estimated Costing
        </div>
        {/* <div className="text-xl p-5 m-0">
                <div className="text-3xl pb-5"> { orders[0]["name"] }</div>
                <div> {orders[0]["mobileBrand"]} - {orders[0]["userDeviceIssue"] } </div>
            </div> */}
        <div className="font-bold space-y-5 p-5 m-2">
          <label htmlFor="" className="text-3xl">
            {" "}
            Estimated Price{" "}
          </label>
          <br />
          <input
            value={price}
            onChange={(event) => setPrice(event.target.value)}
            className="rounded-xs text-2xl bg-gray-100 border border-t-2 border-gray-300 p-2 focus:border-black"
            type="number"
          />
        </div>
        <div className="font-bold space-y-5 p-5 m-2">
          <label htmlFor="" className="text-3xl">
            {" "}
            Problem Description
          </label>
          <br></br>
          <input
            value={problemDescription}
            onChange={(event) => setProblemDescription(event.target.value)}
            className="rounded-xs p-3 pb-7 w-4/5 bg-gray-100  border border-t-2 border-gray-300 focus:border-black text-xl "
            type="text"
          />
        </div>
        <div className="text-3xl font-bold space-y-5 p-5 pb-7">
          Detail Solution
          <br></br>
          <div>
            <textArea
              value={problemSolution}
              row="7"
              onChange={(event) => setProblemSolution(event.target.value)}
              className="rounded-xs p-3 pb-7  w-4/5 bg-gray-100  border border-t-2 border-gray-300 text-xl focus:border-black  "
              type="textArea"
            ></textArea>
            {/* <textarea name="" id="" cols="30" rows="10">adjshcvjhadvjh*/}
          </div>
        </div>
        <div className="grid grid-cols-5">
          <div className="col-start-4">
            <button
              className="bg-blue-800 text-white uppercase py-3 px-10 rounded-xs font-semibold 
                    cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hower:text-white transition 
                    duration-2oo ease-in-out float-right"
              onClick={getCreditDetails}
            >
              Send
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={showCreditModal}
        onHide={handleCloseCreditModal}
        centered
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Your Credit Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-danger fw-bold">Insufficient Credits</h5>
          <p className="mt-3">
            You currently have <strong>{data ? data.credit : 0}</strong>{" "}
            credits. Additional credits are required to continue accessing our
            services.
          </p>
          <p className="mt-2 fw-medium text-primary-emphasis">
            Please contact our customer support to discuss additional credit
            packages or for any billing inquiries. Our team is ready to assist
            you with various options to suit your needs.
          </p>
          <div className="mt-4">
            <Button
              variant="outline-primary"
              className="d-flex align-items-center"
            >
              <PhoneArrowDownLeftIcon className="h-6 w-6 mr-2" />
              <span className="fw-bold">+91-9060 2999 19</span>
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreditModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}
