import React from "react";

export default function ShopItem({ shopId, shopDetails }) {
    return (
        <div key={shopId} className="grid grid-cols-4 space-x-10  border-t-2 border-t-gray-500 grid-rows-4 gap-1 p-5 bg-white border-y-5  h-150">
            <div className="flex col-start-1 row-span-4">
                <img className="" style={{height:"150px",width:"150px"}} src={shopDetails.shopImage} alt={shopDetails.shopName} />
            </div>
            <div className="flex col-start-2 font-bold rtl">
                    Shop's Name:
                </div>
                <div className="flex col-start-3 ">
                    {shopDetails.shopName}
                </div>
                <div className="flex col-start-2 font-bold rtl">
                    Phone No::
                </div>
                <div className="flex col-start-3 ">
                    {shopDetails.contactNumber}
                </div>
                {/* <div className="flex col-start-2 font-bold rtl">
                   Rating:
                </div>
                <div className="flex col-start-3 ">
                    {shopDetails.shopRating}
                </div>
                <div className="flex col-start-2 font-bold rtl">
                   Rating:
                </div> */}
                <div className="flex col-start-2 font-bold rtl">
                   Address:
                </div>
                <div className="flex col-start-3 ">
                    {shopDetails.shopAddress}
                </div>
                <div className="flex col-start-2 font-bold rtl">
                shop Address Zipcode:
                </div>
                <div className="flex col-start-3 ">
                    {shopDetails.shopAddressZipcode}
                </div>
            {/* <div className="flex col-start-4 row-4">
                <button className="bg-blue-800 text-white uppercase py-2 px-4 rounded-xs font-semibold 
        cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hower:text-white transition 
        duration-2oo ease-in-out">Details</button>
            </div> */}
        </div>
    );
}