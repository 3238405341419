import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "react-tabulator/lib/styles.css";
import { ReactTabulator } from "react-tabulator";

export default function UserFaultFinder() {
  const [data, setData] = useState([]);
  // const navigate = useNavigate();
  useEffect(() => {
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/userFaultFinder";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    axios
      .get(API)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const columns = [
    { title: "S_No.", formatter: "rownum", hozAlign: "left", width: 40 },
    {
      title: "Brand",
      field: "brand",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Model",
      field: "model",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Fault",
      field: "fault",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Pincode",
      field: "pincode",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Contact_Number",
      field: "mobileNo",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
  ];
  return (
    <div>
      <ReactTabulator
        data={data}
        columns={columns}
        options={{
          layout: "fitColumns",
          pagination: "local", // Enable pagination
          paginationSize: 16, // Number of rows per page
          movableColumns: true, // Allow columns to be reordered
          paginationCounter: "rows",
          tooltips: true,
          resizableColumnWidths: false, // Set to false to prevent resize errors
          resizableRowWidths: false,
          movableRows: true,
          resizableColumns: true,
          // resizableRows: true,
          resizableColumnFit: true,
        }}
      />
    </div>
  );
}
