import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {
  UserCircleIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/solid";
import ProfileImg from "../assets/images/ProfileImgShop.webp";
import axios from "axios";
export default function Header({ name }) {
  const navigate = useNavigate();
  const logo = require("../assets/logo.png");
  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [shopAddressZipcode, setShopAddressZipcode] = useState("");
  const [shopImage, setShopImage] = useState(null);
  const [shopBannerImage, setshopBannerImage] = useState(null);
  const [shopGoogleMapLink, setshopGoogleMapLink] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const Name = localStorage.getItem("Name");
  const Email = localStorage.getItem("Email");
  const [showModal, setShowModal] = useState(false);
  const shopId = localStorage.getItem("Id");
  const [data, setData] = useState([]);
  const [shopData, setShopData] = useState({});
  const roleIdLocalStorage = localStorage.getItem("role");
  const roleId = parseInt(roleIdLocalStorage);

  useEffect(() => {
    const GetCreditDetails = async () => {
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/shop/credit";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      axios
        .get(API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "authenticationToken"
            )}`,
          },
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Failed to get credit balance");
        });
    };
    const GetShopDetails = async () => {
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/shop/getShopById";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      try {
        const response = await axios.get(API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "authenticationToken"
            )}`,
          },
        });
        setShopData(response.data);
        setContactNumber(response.data.contactNumber || "");
        setShopName(response.data.shopName || "");
        setShopAddress(response.data.shopAddress || "");
        setShopAddressZipcode(response.data.shopAddressZipcode || "");
        setshopGoogleMapLink(response.data.shopGoogleMapLink || "");
      } catch (error) {
        console.error("Error:", error);
        alert("Failed to get shop details");
      }
    };
    if (roleId === 2) {
      GetCreditDetails();
      GetShopDetails();
    }
  }, []);

  const handleFileRead = async (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    setShopImage(file);
  };
  const handleFileRead1 = async (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    setshopBannerImage(file);
  };
  const handleVisitShop = (shopId) => {
    window.open(`/visit-shop/${shopId}`, "_blank");
  };
  const clearFields = () => {
    setShopName("");
    setShopAddress("");
    setShopAddressZipcode("");
    setShopImage(null);
    setshopBannerImage(null);
    setshopGoogleMapLink("");
    setContactNumber("");
  };

  function UpdateShopUser(event) {
    event.preventDefault();

    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/shop/updateProfile";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

    const formData = new FormData();

    formData.append("contactNumber", contactNumber);
    formData.append("shopName", shopName);
    formData.append("shopAddress", shopAddress);
    formData.append("shopAddressZipcode", shopAddressZipcode);
    formData.append("shopGoogleMapLink", shopGoogleMapLink);
    formData.append("shopImage", shopImage);
    formData.append("shopBannerImage", shopBannerImage);

    const options = {
      method: "PUT",
      mode: "no-cors",
      url: API,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("authenticationToken")}`,
      },
      data: formData,
    };

    axios
      .request(options)
      .then(async function (response) {
        console.log(response.data);
        if (response.data) {
          localStorage.setItem("shopName", response.data.shop.shopName);
          localStorage.setItem("shopAddress", response.data.shop.shopAddress);

          alert("Shop profile updated successfully!");
          clearFields();
          navigate("/supportOrders");
          setShowModal(false);
        }
      })
      .catch(function (error) {
        console.error("Error updating shop profile:", error.response.data);
        alert("Failed to update shop profile.");
      });
  }

  const handleClose = () => setShowModal(false);
  return (
    <header
      className="border-b flex px-8 items-center justify-between bg-slate-100 shadow-lg pb-0 p-2 sticky top-0"
      style={{ zIndex: "99" }}
    >
      {/* <!-- Logo text or image --> */}
      <div className="flex items-center justify-between  md:mb-0">
        <h1 className="leading-none text-2xl text-grey-darkest">
          <Link
            className="no-underline text-grey-darkest hover:text-black"
            to="/"
          >
            <img className="w-32" src={logo} alt="RMM logo"></img>
          </Link>
        </h1>
        {/* <button
          className="text-black hover:text-orange ml-5"
          onClick={toggleSidebar}
        >
          <AdjustmentsHorizontalIcon className="h-20 w-8 mr-2 mb-2" />
        </button> */}
        <Link className="text-black hover:text-orange md:hidden" to="#">
          <i className="fa fa-2x fa-bars"></i>
        </Link>
      </div>
      {/* <!-- END Logo text or image -->*/}

      {/*<!-- Search field --> */}
      {/* <form className="mb-4 w-full md:mb-0 md:w-1/4 ">
        <label className="hidden bg-grey-600" for="search-form">Search</label>

        <input className="bg-grey-600 border-2 focus:border-orange p-2 rounded-lg shadow-inner w-full" placeholder="Search" type="text"></input>

        <button className="hidden bg-grey-600">Submit</button>
      </form> */}
      {/* <!-- END Search field --> */}

      {/* <!-- Global navigation --> */}
      <div className="flex items-center space-x-4">
        {/* <li className="md:ml-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" color="" stroke-width="1.5" stroke="gray" className="w-8 h-8">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
            </svg>
          </li> */}

        {roleId === 2 ? (
          <div
            className="border-t no-underline hover:underline py-2 text-grey-darkest hover:text-black md:border-none md:p-0 flex items-center flex-col"
            style={{ display: "flex", flexDirection: "row" }}
            onClick={() => setShowModal(true)}
          >
            <UserCircleIcon color="grey" className="h-10 w-10" />
            <div> {Name}</div>
          </div>
        ) : (
          <div
            className="border-t no-underline hover:underline py-2 text-grey-darkest hover:text-black md:border-none md:p-0 flex items-center flex-col"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <UserCircleIcon color="grey" className="h-10 w-10" />
            <div> {Name}</div>
          </div>
        )}
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        backdrop="static"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "rgb(30 64 175)" }}>
            Your Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section style={{ backgroundColor: " #eee" }}>
            <div class="container py-5">
              <div class="row">
                <div class="col-lg-4">
                  <div class="card mb-4">
                    <div class="card-body text-center">
                      <img
                        src={ProfileImg}
                        alt="avatar"
                        class="rounded-circle img-fluid"
                      />
                      <h5 class="my-3">{Name}</h5>
                      {/* <p class="text-muted mb-1 fst-italic fs-5">
                        Current Credit: {data ? data.credit : 0}
                      </p> */}
                      <p class="text-muted mb-1 fs-5">{Email}</p>

                      <button
                        onClick={() => handleVisitShop(shopId)}
                        className="btn btn-outline-success mt-3"
                      >
                        Visit Your Shop
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8">
                  <legend className="mb-2 text-2xl"> Shop Details </legend>
                  <input
                    value={shopName}
                    onChange={(event) => setShopName(event.target.value)}
                    className="flex justify-end mb-4 h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none w-[100%]"
                    placeholder="Shop Name"
                  />
                  <input
                    value={shopAddress}
                    onChange={(event) => setShopAddress(event.target.value)}
                    className="flex justify-end mb-4 h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none w-[100%]"
                    placeholder="Shop Address"
                  />

                  {/* <label htmlFor="contactNumber">+91</label> */}
                  <input
                    value={contactNumber}
                    onChange={(event) =>
                      setContactNumber(event.target.value.substring(0, 10))
                    }
                    placeholder="Phone Number"
                    className="flex justify-end mb-4 h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none w-[100%]"
                  />

                  <input
                    value={shopAddressZipcode}
                    onChange={(event) =>
                      setShopAddressZipcode(event.target.value)
                    }
                    className="flex justify-end mb-4 h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none w-[100%]"
                    placeholder="ZipCode"
                  />

                  <textarea
                    value={shopGoogleMapLink}
                    onChange={(event) =>
                      setshopGoogleMapLink(event.target.value)
                    }
                    className="flex justify-end mb-4 h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none w-[100%]"
                    placeholder="Google_Map Link"
                    rows="3"
                  ></textarea>

                  <div className="flex flex-row justify-between w-[100%] mt-10">
                    <label htmlFor="">Shop Image: </label>
                    {/* <input type="file" value={shopImage} onChange={event => setShopImage(event.target.value)} /> */}
                    <input
                      id="originalFileName"
                      type="file"
                      accept="image/*"
                      label="Shop Image"
                      name="originalFileName"
                      onChange={(e) => handleFileRead(e)}
                      variant="standard"
                    />
                  </div>
                  <div className="flex flex-row justify-between w-[100%] mt-10">
                    <label htmlFor="">Shop Banner Image: </label>
                    {/* <input type="file" value={shopImage} onChange={event => setShopImage(event.target.value)} /> */}
                    <input
                      type="file"
                      accept="image/*"
                      label="Shop Banner Image"
                      onChange={(e) => handleFileRead1(e)}
                      variant="standard"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              {/* <button className="text-white font-sans border border-solid border-blue-700 text-center w-96 h-10 bg-blue-800 text-base mt-3.5 mb-6">
                Update Account
              </button> */}
              <button
                type="button"
                class="text-blue-700 hover:text-white border-2 border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                onClick={UpdateShopUser}
              >
                {" "}
                Update Account
              </button>

              <br />
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-outline-dark fw-semibold"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <!-- END Global navigation --> */}
    </header>
  );
}
