import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import map from "../assets/images/address.png";
import call from "../assets/images/call.png";
import mail from "../assets/images/mail.png";
import address from "../assets/images/home1.jpg";
import want1 from "../assets/images/tablet.jpg";
import want2 from "../assets/images/mobile.jpg";
import want3 from "../assets/images/laptop.jpg";
import want4 from "../assets/images/accessory.jpg";
import defaultShopImage from "../assets/images/shopImage2.jpg";
import defaultShopBannerImage from "../assets/images/mobile-repair.webp";
import { Link } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "../App.css";
import Loader from "./Loader";

export default function ShopUserWebsite() {
  const { id } = useParams();
  // const [authenticated, setAuthenticated] = useState(false);
  const [shopDetails, setShopDetails] = useState(null);

  useEffect(() => {
    const fetchShopDetails = async () => {
      try {
        const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
        const API_ENDPOINT = "/api/shop/getShopsBy";
        const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
        const response = await axios.post(API, { _id: id });
        setShopDetails(response.data[0]);
        // setShopDetails(response.data);
        console.log(response.data);
        console.log(shopDetails);
      } catch (error) {
        console.log(error);
      }
    };

    fetchShopDetails();
  }, [id]);

  // If user is not authenticated, redirect to login page
  if (!localStorage.getItem("authenticated")) {
    return <Navigate to="/login" />;
  }

  if (!shopDetails) {
    return <Loader />;
  }
  // const imageURL = `${process.env.REACT_APP_API_BASE_URL}/${shopDetails.shopImage}`;
  // const imagBannereURL = `${process.env.REACT_APP_API_BASE_URL}/${shopDetails.shopBannerImage}`;
  const imageURL = shopDetails.shopImage
    ? `${process.env.REACT_APP_API_BASE_URL}/${shopDetails.shopImage}`
    : defaultShopImage;

  const imagBannereURL = shopDetails.shopBannerImage
    ? `${process.env.REACT_APP_API_BASE_URL}/${shopDetails.shopBannerImage}`
    : defaultShopBannerImage;
  const encodedImageURL = encodeURI(imagBannereURL);
  const encodedShopImageURL = encodeURI(imageURL);
  return (
    <div>
      {/* Landing Page
           <button type="button" class="btn btn-primary">Primary</button> */}
      {shopDetails && (
        <div className="container-fluid">
          <div className="row section-0">
            <div className="col-lg-12">
              <div
                className="Shopsection-1 backgroundImageStyle"
                style={{
                  backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${encodedImageURL})`,
                  position: "relative",
                }}
              >
                <div className="logo-img-div"></div>
                <div className="row hero-section0">
                  <div className="col-md-7 ">
                    <div className="hero-section1" style={{ zIndex: 1 }}>
                      <p className="Welcome-text">
                        Welcome to {shopDetails.shopName}
                      </p>
                      <p className="Welcome-text1">
                        As an affiliate of Repair My Mobile, we're trained and
                        equipped to handle all your phone repair needs. Our
                        expert technicians provide fast and affordable service
                        to get your device back in top shape. Come visit us
                        today!
                      </p>
                      <Link to="/login">
                        <button
                          class="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2"
                          style={{ margin: "65px" }}
                        >
                          Get a Repair
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sectionn">
            <div className="row">
              <div
                className=""
                style={{
                  backgroundColor: "rgb(59 130 246 / 0.5)",
                }}
              >
                <div
                  class="container px-4 py-5"
                  id="hanging-icons"
                  style={{ fontFamily: "math" }}
                >
                  <h2 class="pb-2 border-bottom ">Our Expertise</h2>
                  <div class="row g-4 py-5 row-cols-1 row-cols-md-2 row-cols-lg-4">
                    <div class="col d-flex align-items-start">
                      <div class="icon-square bg-light text-dark flex-shrink-0 me-3"></div>
                      <div>
                        <h3>Expert Repair</h3>
                        <p>
                          Our highly trained technicians use the latest tools
                          and techniques to diagnose and repair your device
                          efficiently.
                        </p>
                      </div>
                    </div>
                    <div class="col d-flex align-items-start">
                      <div class="icon-square bg-light text-dark flex-shrink-0 me-3"></div>
                      <div>
                        <h3>Quality Parts and Materials</h3>
                        <p>
                          We only use high-quality, genuine replacement parts to
                          ensure the longevity and reliability of your repaired
                          device.
                        </p>
                      </div>
                    </div>
                    <div class="col d-flex align-items-start">
                      <div class="icon-square bg-light text-dark flex-shrink-0 me-3"></div>
                      <div>
                        <h3>Advanced Diagnostic Tools</h3>
                        <p>
                          We utilize state-of-the-art diagnostic equipment to
                          identify and address the root cause of your device's
                          problems quickly and accurately.
                        </p>
                      </div>
                    </div>
                    <div class="col d-flex align-items-start">
                      <div class="icon-square bg-light text-dark flex-shrink-0 me-3"></div>
                      <div>
                        <h3>Warranty</h3>
                        <p>
                          All our repairs come with a warranty, giving you peace
                          of mind and protection for your investment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" section-5">
            <div
              class="container my-12 mx-auto px-4 md:px-12"
              style={{ fontFamily: "math" }}
            >
              <h2 class="pb-2 border-bottom ">Our Services</h2>
              <div class="flex flex-wrap -mx-1 lg:-mx-4">
                <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2">
                  <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                    <img
                      class="object-cover w-full rounded-t-lg h-96 md:h-44 md:w-48 md:rounded-none md:rounded-s-lg"
                      src={want2}
                      alt=""
                    />
                    <div class="flex flex-col justify-between p-4 leading-normal">
                      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                        Phone Repair
                      </h5>
                      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        Our skilled technicians can fix a wide range of
                        smartphone issues, from cracked screens to software
                        problems.
                      </p>
                    </div>
                  </p>
                </div>

                <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2">
                  <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                    <img
                      class="object-cover w-full rounded-t-lg h-96 md:h-44 md:w-48 md:rounded-none md:rounded-s-lg"
                      src={want1}
                      alt=""
                    />
                    <div class="flex flex-col justify-between p-4 leading-normal">
                      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                        Android Repair
                      </h5>
                      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        Bring in your damaged android phone and let us restore
                        it to its former glory with our expert android repair
                        services.
                      </p>
                    </div>
                  </p>
                </div>
                <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2">
                  <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                    <img
                      class="object-cover w-full rounded-t-lg h-96 md:h-44 md:w-48 md:rounded-none md:rounded-s-lg"
                      src={want3}
                      alt=""
                    />
                    <div class="flex flex-col justify-between p-4 leading-normal">
                      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                        iPhone Repair
                      </h5>
                      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        From hardware upgrades to virus removal, our iPhone
                        repair experts have the skills to get your device back
                        in working order.
                      </p>
                    </div>
                  </p>
                </div>
                <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/2">
                  <p class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                    <img
                      class="object-cover w-full rounded-t-lg h-96 md:h-44 md:w-48 md:rounded-none md:rounded-s-lg"
                      src={want4}
                      alt=""
                    />
                    <div class="flex flex-col justify-between p-4 leading-normal">
                      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                        Accessory Sales
                      </h5>
                      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        Browse our selection of high-quality phone, tablet, and
                        laptop accessories to enhance your device's
                        functionality and style.
                      </p>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <footer class="py-5">
              <div
                class="row"
                style={{
                  fontFamily: "math",
                  // backgroundColor: "rgb(59 130 246 / 0.5)",
                }}
              >
                <div class="col-md-5  mb-3">
                  <div>
                    {/* <h2 class="pb-2 border-bottom ">Contact Us Today</h2> */}

                    <div class="d-flex flex-column flex-md-row  gap-4 align-items-center justify-content-center">
                      <div>
                        <a
                          href="#"
                          class="list-group-item list-group-item-action d-flex gap-3 py-3"
                          aria-current="true"
                        >
                          <img
                            src={call}
                            alt=""
                            width="50"
                            height="32"
                            class="rounded-circle flex-shrink-0 "
                          />
                          <div class="d-flex gap-2 w-100 justify-content-between">
                            <div>
                              <h5 class="mb-0">Call Us</h5>
                              <p class="mb-0 opacity-75">
                                {shopDetails.contactNumber}
                              </p>
                            </div>
                          </div>
                        </a>
                        <a
                          href="#"
                          class="list-group-item list-group-item-action d-flex gap-3 py-3"
                          aria-current="true"
                        >
                          <img
                            src={mail}
                            alt=""
                            width="50"
                            height="32"
                            class="rounded-circle flex-shrink-0"
                          />
                          <div class="d-flex gap-2 w-100 justify-content-between">
                            <div>
                              <h5 class="mb-0">Book Online</h5>
                              <p class="mb-0 opacity-75">{shopDetails.email}</p>
                            </div>
                          </div>
                        </a>
                        <a
                          href="#"
                          class="list-group-item list-group-item-action d-flex gap-3 py-3"
                          aria-current="true"
                        >
                          <img
                            src={address}
                            alt=""
                            width="50"
                            height="32"
                            class="rounded-circle flex-shrink-0"
                          />
                          <div class="d-flex gap-2 w-100 justify-content-between">
                            <div>
                              <h5 class="mb-0">Visit Us</h5>
                              <p class="mb-0 opacity-75">
                                {shopDetails.shopAddress},{" "}
                                {shopDetails.shopAddressZipcode}
                              </p>
                            </div>
                          </div>
                        </a>
                        <a
                          href="#"
                          class="list-group-item list-group-item-action d-flex gap-3 py-3"
                          aria-current="true"
                        >
                          <img
                            src={map}
                            alt=""
                            width="50"
                            height="32"
                            class=" flex-shrink-0 "
                          />
                          <div class="d-flex gap-2 w-100 justify-content-between">
                            <div>
                              <h5 class="mb-0">Find Us on the Map</h5>
                              <p class="mb-0 opacity-75">
                                <a
                                  href={shopDetails.shopGoogleMapLink}
                                  class="inline-flex items-center justify-center text-base font-medium text-gray-500 rounded-lg bg-gray-50 hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
                                  target="blank"
                                >
                                  <span class="w-full">
                                    Visit us at our centrally located repair
                                    center for fast and reliable service.
                                  </span>
                                </a>
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 offset-md-1 mb-3">
                  <img
                    src={encodedShopImageURL}
                    alt="Shop Image"
                    className="imageFitStyle"
                  />
                </div>
              </div>

              <div class="d-flex flex-column flex-sm-row justify-content-between pt-4 border-top">
                <p>© 2024 Company, Inc. All rights reserved.</p>
              </div>
            </footer>
          </div>
        </div>
      )}
    </div>
  );
}
