export default function OrderHistoryItem({ order, solution }) {
  // console.log(solution, order);
  return (
    <div>
      <span className="text-3xl"> Order: {order.orderId} </span>
      <div
        key={order.orderId}
        className="grid grid-cols-1 md:grid-cols-4 border-t-2 border-b-2 border-gray-300 gap-8 p-5 border-y-5"
      >
        <div className="font-bold rtl lg:text-2xl">Name:</div>
        <div className=" lg:text-2xl">{order.name}</div>
        <div className="font-bold rtl lg:text-2xl">Queries:</div>
        <div className=" lg:text-2xl">
          {order.mobileBrand} - {order.userDeviceIssue}
        </div>
        <div className="font-bold rtl lg:text-2xl">Pincode:</div>
        <div className="lg:text-2xl ">{order.zipcode}</div>
        <div className="font-bold rtl lg:text-2xl">Number:</div>
        <div className=" lg:text-2xl">{order.userContactNumber}</div>
        <div className="font-bold rtl lg:text-2xl">Problem Description:</div>
        <div className=" lg:text-2xl">{solution.problemDesc}</div>
        <div className="font-bold rtl lg:text-2xl">Detail Solution:</div>
        <div className=" lg:text-2xl">{solution.detailSolution}</div>
        <div className="font-bold rtl lg:text-2xl">Estimated Price:</div>
        <div className=" lg:text-2xl">{solution.estimatedPrice}</div>
        {/* <div className="flex col-start-1 justify-self-center font-bold rtl">
          Solution:
        </div>
        <div className="flex col-start-2 col-span-3 ">
          {order.supportFeedback}
        </div> */}

        <div className="text-gray-600">
          <div> Date: {order.createdAt.split("T")[0]}</div>
          <div> Time: {order.createdAt.split("T")[1].split(".")[0]}</div>
        </div>
        {/* <div className="col-start-4 row-start-1">
                2/8/23
            </div> */}
        {/* <div className="col-start-4 row-start-2">
                5:00 pm
            </div>
            <div className="col-start-3 row-start-1 text-gray-600">
                <div >
                    Date: {order.createdAt.split("T")[0]}
                </div>

                <div>
                    Time: {order.createdAt.split("T")[1].split(".")[0]}
                </div>
            </div> */}
        {/* <div className="flex justify-end col-start-3 row-start-4">
                <button className="bg-blue-800 text-white h-12
                uppercase py-2 px-4 
                rounded-xs font-semibold 
                    cursor-pointer 
                    border-2 border-slate-300 hover:bg-slate-700 
                    hower:text-white transition 
                    duration-2oo ease-in-out">
                    Details</button>
            </div> */}
      </div>
    </div>
  );
}
