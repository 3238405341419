import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import Toast from "react-bootstrap/Toast";

import ToastContainer from "react-bootstrap/ToastContainer";
import Loader from "./Loader";

const FaultFinder = () => {
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const [shopDetails, setshopDetails] = useState([]);
  const [formData, setFormData] = useState({
    voltageDC: "NA",
    ampereDC: "NA",
    DM: "NA",
    DP: "NA",
    batteryGR: "NA",
    chargingGR: "NA",
    port: "NA",
    powerKeyPress: "NA",
    brand: "",
    model: "",
    fault: "",
    solutionWorked: "",
    comment: "",
    specificFault: "",
    steps: "",
    shopId: localStorage.getItem("Id"),
    shopName: localStorage.getItem("shopName"),
    shopEmail: localStorage.getItem("Email"),
    AISuggestedSol: "",
    TechSuggestedSol: "",
  });
  const [brands, setBrands] = useState([
    {
      name: "Nokia",
      id: 1,
    },
    {
      name: "Ericsson",
      id: 2,
    },
    {
      name: "Siemens",
      id: 3,
    },
    {
      name: "Motorola",
      id: 4,
    },
    {
      name: "alcatel",
      id: 5,
    },
    {
      name: "Panasonic",
      id: 6,
    },
    {
      name: "Sony",
      id: 7,
    },
    {
      name: "Mitsubishi",
      id: 8,
    },
    {
      name: "Samsung",
      id: 9,
    },
    {
      name: "Bosch",
      id: 10,
    },
    {
      name: "Philips",
      id: 11,
    },
    {
      name: "NEC",
      id: 12,
    },
    {
      name: "Sagem",
      id: 13,
    },
    {
      name: "Maxon",
      id: 14,
    },
    {
      name: "Benefon",
      id: 15,
    },
    {
      name: "Telit",
      id: 16,
    },
    {
      name: "Kyocera",
      id: 17,
    },
    {
      name: "Sendo",
      id: 18,
    },
    {
      name: "Sony Ericsson",
      id: 19,
    },
    {
      name: "LG",
      id: 20,
    },
    {
      name: "Tel.Me.",
      id: 21,
    },
    {
      name: "Neonode",
      id: 22,
    },
    {
      name: "Sharp",
      id: 23,
    },
    {
      name: "Chea",
      id: 24,
    },
    {
      name: "Mitac",
      id: 25,
    },
    {
      name: "Sewon",
      id: 26,
    },
    {
      name: "Palm",
      id: 27,
    },
    {
      name: "Amoi",
      id: 28,
    },
    {
      name: "Innostream",
      id: 29,
    },
    {
      name: "O2",
      id: 30,
    },
    {
      name: "BenQ",
      id: 31,
    },
    {
      name: "Pantech",
      id: 32,
    },
    {
      name: "Haier",
      id: 33,
    },
    {
      name: "Bird",
      id: 34,
    },
    {
      name: "i-mate",
      id: 35,
    },
    {
      name: "BlackBerry",
      id: 36,
    },
    {
      name: "VK Mobile",
      id: 37,
    },
    {
      name: "Qtek",
      id: 38,
    },
    {
      name: "Vertu",
      id: 39,
    },
    {
      name: "Eten",
      id: 40,
    },
    {
      name: "HP",
      id: 41,
    },
    {
      name: "BenQ-Siemens",
      id: 42,
    },
    {
      name: "XCute",
      id: 43,
    },
    {
      name: "Toshiba",
      id: 44,
    },
    {
      name: "HTC",
      id: 45,
    },
    {
      name: "Asus",
      id: 46,
    },
    {
      name: "Gigabyte",
      id: 47,
    },
    {
      name: "Apple",
      id: 48,
    },
    {
      name: "Thuraya",
      id: 49,
    },
    {
      name: "Fujitsu Siemens",
      id: 50,
    },
    {
      name: "WND",
      id: 51,
    },
    {
      name: "i-mobile",
      id: 52,
    },
    {
      name: "Vodafone",
      id: 53,
    },
    {
      name: "Sonim",
      id: 54,
    },
    {
      name: "T-Mobile",
      id: 55,
    },
    {
      name: "MWg",
      id: 56,
    },
    {
      name: "AT&T",
      id: 57,
    },
    {
      name: "Huawei",
      id: 58,
    },
    {
      name: "Acer",
      id: 59,
    },
    {
      name: "iNQ",
      id: 60,
    },
    {
      name: "Dell",
      id: 61,
    },
    {
      name: "ZTE",
      id: 62,
    },
    {
      name: "Modu",
      id: 63,
    },
    {
      name: "Microsoft",
      id: 64,
    },
    {
      name: "Garmin-Asus",
      id: 65,
    },
    {
      name: "Micromax",
      id: 66,
    },
    {
      name: "BLU",
      id: 67,
    },
    {
      name: "Spice",
      id: 68,
    },
    {
      name: "Icemobile",
      id: 69,
    },
    {
      name: "verykool",
      id: 70,
    },
    {
      name: "Orange",
      id: 71,
    },
    {
      name: "Plum",
      id: 72,
    },
    {
      name: "Lenovo",
      id: 73,
    },
    {
      name: "Meizu",
      id: 74,
    },
    {
      name: "Celkon",
      id: 75,
    },
    {
      name: "Amazon",
      id: 76,
    },
    {
      name: "Casio",
      id: 77,
    },
    {
      name: "Yezz",
      id: 78,
    },
    {
      name: "NIU",
      id: 79,
    },
    {
      name: "Xiaomi",
      id: 80,
    },
    {
      name: "Parla",
      id: 81,
    },
    {
      name: "Oppo",
      id: 82,
    },
    {
      name: "Karbonn",
      id: 83,
    },
    {
      name: "Jolla",
      id: 84,
    },
    {
      name: "XOLO",
      id: 85,
    },
    {
      name: "Prestigio",
      id: 86,
    },
    {
      name: "Maxwest",
      id: 87,
    },
    {
      name: "Allview",
      id: 88,
    },
    {
      name: "Cat",
      id: 89,
    },
    {
      name: "Archos",
      id: 90,
    },
    {
      name: "Unnecto",
      id: 91,
    },
    {
      name: "Gionee",
      id: 92,
    },
    {
      name: "Emporia",
      id: 93,
    },
    {
      name: "Lava",
      id: 94,
    },
    {
      name: "OnePlus",
      id: 95,
    },
    {
      name: "Wiko",
      id: 96,
    },
    {
      name: "Nvidia",
      id: 97,
    },
    {
      name: "vivo",
      id: 98,
    },
    {
      name: "Yota",
      id: 99,
    },
    {
      name: "YU",
      id: 100,
    },
    {
      name: "Posh",
      id: 101,
    },
    {
      name: "Intex",
      id: 102,
    },
    {
      name: "QMobile",
      id: 103,
    },
    {
      name: "EE",
      id: 104,
    },
    {
      name: "Coolpad",
      id: 105,
    },
    {
      name: "Energizer",
      id: 106,
    },
    {
      name: "Google",
      id: 107,
    },
    {
      name: "BQ",
      id: 108,
    },
    {
      name: "LeEco",
      id: 109,
    },
    {
      name: "",
      id: 110,
    },
    {
      name: "Blackview",
      id: 116,
    },
    {
      name: "Razer",
      id: 117,
    },
    {
      name: "Realme",
      id: 118,
    },
    {
      name: "Infinix",
      id: 119,
    },
    {
      name: "Tecno",
      id: 120,
    },
    {
      name: "Honor",
      id: 121,
    },
    {
      name: "TCL",
      id: 123,
    },
    {
      name: "Ulefone",
      id: 124,
    },
    {
      name: "Fairphone",
      id: 127,
    },
    {
      name: "Nothing",
      id: 128,
    },
    {
      name: "Doogee",
      id: 129,
    },
    {
      name: "Cubot",
      id: 130,
    },
  ]);

  const [specificFault, setSpecificFault] = useState([]);
  const [fault, setFault] = useState([]);
  // const [solutionWorked, setSolutionWorked] = useState("");
  // const [other, setOther] = useState(false);
  const [other, setOther] = useState({
    othervoltageDC: false,
    voltageDCtxt: "",
    otherampereDC: false,
    ampereDCtxt: "",
    otherDM: false,
    DMtxt: "",
    otherDP: false,
    DPtxt: "",
    otherbatteryGR: false,
    batteryGRtxt: "",
    otherchargingGR: false,
    chargingGRtxt: "",
  });
  const [showBrandDropdown, setShowBrandDropdown] = useState(false);

  const handleBrandDropdownToggle = () => {
    setShowBrandDropdown(!showBrandDropdown);
  };

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [getSuggestionbtn, setGetSuggestionbtn] = useState(false);
  const [isNext1, setIsNext1] = useState(false);
  const [result, setResult] = useState(null);
  const [AIresponse, setAIresponse] = useState(null);
  const [AI, setAI] = useState(false);
  const [AImessage, setAImessage] = useState(true);
  const [SFResult, setSFResult] = useState(null);
  const [showError, setShowError] = useState(false);
  const [uniqueValues, setUniqueValues] = useState({
    voltageDC: [],
    ampereDC: [],
    DM: [],
    DP: [],
    batteryGR: [],
    chargingGR: [],
    port: [],
    powerKeyPress: [],
    brand: [],
    fault: [],
    specificFault: [],
  });

  // useEffect(() => {
  //   // Extract unique values for each field
  //   const uniqueVoltageDC = [...new Set(data.map((item) => item.voltageDC))];
  //   const uniqueAmpereDC = [...new Set(data.map((item) => item.ampereDC))];
  //   const uniqueDM = [...new Set(data.map((item) => item.DM))];
  //   const uniqueDP = [...new Set(data.map((item) => item.DP))];
  //   const uniqueBatteryGR = [...new Set(data.map((item) => item.batteryGR))];
  //   const uniqueChargingGR = [...new Set(data.map((item) => item.chargingGR))];
  //   const uniquePort = [...new Set(data.map((item) => item.port))];
  //   const uniquePowerKeyPress = [
  //     ...new Set(data.map((item) => item.powerKeyPress)),
  //   ];

  //   setUniqueValues({
  //     voltageDC: uniqueVoltageDC,
  //     ampereDC: uniqueAmpereDC,
  //     DM: uniqueDM,
  //     DP: uniqueDP,
  //     batteryGR: uniqueBatteryGR,
  //     chargingGR: uniqueChargingGR,
  //     port: uniquePort,
  //     powerKeyPress: uniquePowerKeyPress,
  //   });
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const faultSolutionEndpoint = "/api/faultSolution";
        const faultsEndpoint = "/faults";
        const specificFaultsEndpoint = "/api/specificFaults";

        const [faultSolutionResponse, faultsResponse, specificFaultsResponse] =
          await Promise.all([
            axios.get(`${REACT_APP_API_BASE_URL}${faultSolutionEndpoint}`),
            axios.get(`${REACT_APP_API_BASE_URL}${faultsEndpoint}`),
            axios.get(`${REACT_APP_API_BASE_URL}${specificFaultsEndpoint}`),
          ]);

        setData(faultSolutionResponse.data);
        setFault(faultsResponse.data);
        setSpecificFault(specificFaultsResponse.data);
        setFetching(true);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (
      fault &&
      Array.isArray(fault) &&
      specificFault &&
      Array.isArray(specificFault)
    ) {
      const uniqueFault = [...new Set(fault.map((item) => item.fault))];
      const uniqueSpecificFault = [
        ...new Set(specificFault.map((item) => item.specificFault)),
      ];
      const uniqueVoltageDC = [...new Set(data.map((item) => item.voltageDC))];
      const uniqueAmpereDC = [...new Set(data.map((item) => item.ampereDC))];
      const uniqueDM = [...new Set(data.map((item) => item.DM))];
      const uniqueDP = [...new Set(data.map((item) => item.DP))];
      const uniqueBatteryGR = [...new Set(data.map((item) => item.batteryGR))];
      const uniqueChargingGR = [
        ...new Set(data.map((item) => item.chargingGR)),
      ];
      const uniquePort = [...new Set(data.map((item) => item.port))];
      const uniquePowerKeyPress = [
        ...new Set(data.map((item) => item.powerKeyPress)),
      ];
      const uniqueBrand = [...new Set(brands.map((item) => item.name))];
      setUniqueValues({
        fault: uniqueFault,
        specificFault: uniqueSpecificFault,
        voltageDC: uniqueVoltageDC,
        ampereDC: uniqueAmpereDC,
        DM: uniqueDM,
        DP: uniqueDP,
        batteryGR: uniqueBatteryGR,
        chargingGR: uniqueChargingGR,
        port: uniquePort,
        powerKeyPress: uniquePowerKeyPress,
        brand: uniqueBrand,
      });
    }
  }, [fault, specificFault, data]);

  // previously used code
  // useEffect(() => {
  //   const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
  //   const API_ENDPOINT = "/api/faultSolution";
  //   const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
  //   axios
  //     .get(API)
  //     .then((response) => {
  //       // console.log(response.data);
  //       setData(response.data);
  //       setFetching(true);
  //       console.log(data);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  //   const ENDPOINT = "/faults";
  //   const faultAPI = `${REACT_APP_API_BASE_URL}${ENDPOINT}`;
  //   axios
  //     .get(faultAPI)
  //     .then((response) => {
  //       setFault(response.data);
  //       setFetching(true);
  //       console.log(fault);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  //   const SFENDPOINT = "/api/specificFaults";
  //   const SfaultAPI = `${REACT_APP_API_BASE_URL}${SFENDPOINT}`;
  //   axios
  //     .get(SfaultAPI)
  //     .then((response) => {
  //       setSpecificFault(response.data);
  //       setFetching(true);
  //       console.log(specificFault);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  //   // const uniqueVoltageDC = [...new Set(data.map((item) => item.voltageDC))];
  //   // const uniqueAmpereDC = [...new Set(data.map((item) => item.ampereDC))];
  //   // const uniqueDM = [...new Set(data.map((item) => item.DM))];
  //   // const uniqueDP = [...new Set(data.map((item) => item.DP))];
  //   // const uniqueBatteryGR = [...new Set(data.map((item) => item.batteryGR))];
  //   // const uniqueChargingGR = [...new Set(data.map((item) => item.chargingGR))];
  //   // const uniquePort = [...new Set(data.map((item) => item.port))];
  //   // const uniquePowerKeyPress = [
  //   //   ...new Set(data.map((item) => item.powerKeyPress)),
  //   // ];
  //   // const uniqueBrand = [...new Set(brands.map((item) => item.name))];
  //   // setUniqueValues({
  //   //   voltageDC: uniqueVoltageDC,
  //   //   ampereDC: uniqueAmpereDC,
  //   //   DM: uniqueDM,
  //   //   DP: uniqueDP,
  //   //   batteryGR: uniqueBatteryGR,
  //   //   chargingGR: uniqueChargingGR,
  //   //   port: uniquePort,
  //   //   powerKeyPress: uniquePowerKeyPress,
  //   //   brand: uniqueBrand,
  //   // });
  // }, [fetching]);

  // useEffect(() => {
  //   if (
  //     fault &&
  //     Array.isArray(fault) &&
  //     specificFault &&
  //     Array.isArray(specificFault)
  //   ) {
  //     const uniqueFault = [...new Set(fault.map((item) => item.fault))];
  //     const uniqueSpecificFault = [
  //       ...new Set(specificFault.map((item) => item.specificFault)),
  //     ];
  //     const uniqueVoltageDC = [...new Set(data.map((item) => item.voltageDC))];
  //     const uniqueAmpereDC = [...new Set(data.map((item) => item.ampereDC))];
  //     const uniqueDM = [...new Set(data.map((item) => item.DM))];
  //     const uniqueDP = [...new Set(data.map((item) => item.DP))];
  //     const uniqueBatteryGR = [...new Set(data.map((item) => item.batteryGR))];
  //     const uniqueChargingGR = [
  //       ...new Set(data.map((item) => item.chargingGR)),
  //     ];
  //     const uniquePort = [...new Set(data.map((item) => item.port))];
  //     const uniquePowerKeyPress = [
  //       ...new Set(data.map((item) => item.powerKeyPress)),
  //     ];
  //     const uniqueBrand = [...new Set(brands.map((item) => item.name))];
  //     setUniqueValues((prevData) => ({
  //       ...prevData,
  //       fault: uniqueFault,
  //       specificFault: uniqueSpecificFault,
  //       voltageDC: uniqueVoltageDC,
  //       ampereDC: uniqueAmpereDC,
  //       DM: uniqueDM,
  //       DP: uniqueDP,
  //       batteryGR: uniqueBatteryGR,
  //       chargingGR: uniqueChargingGR,
  //       port: uniquePort,
  //       powerKeyPress: uniquePowerKeyPress,
  //       brand: uniqueBrand,
  //     }));
  //     // console.log(uniqueFault);
  //     // console.log(uniqueSpecificFault);
  //   }
  // }, [fault]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("value", value);
    // console.log("name", name);
    if (name === "solutionWorked") {
      document.querySelectorAll(
        'button[name="solutionWorked"]'
      )[1].style.backgroundColor = "#26ae60";
      document.querySelectorAll(
        'button[name="solutionWorked"]'
      )[0].style.backgroundColor = "#26ae60";
      e.target.style.backgroundColor = "lightgrey";
      e.target.style.color = "black";
    }
    if (name === "specificFault" && value === "") {
      setGetSuggestionbtn(false);
      setSFResult(null);
    }
    if (name === "specificFault" && value !== "") {
      setGetSuggestionbtn(true);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "voltageDC" && value === "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        othervoltageDC: true,
      }));
    }
    if (name === "voltageDC" && value !== "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        othervoltageDC: false,
      }));
    }
    if (name === "ampereDC" && value === "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        otherampereDC: true,
      }));
    }
    if (name === "ampereDC" && value !== "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        otherampereDC: false,
      }));
    }
    if (name === "DM" && value === "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        otherDM: true,
      }));
    }
    if (name === "DM" && value !== "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        otherDM: false,
      }));
    }
    if (name === "DP" && value === "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        otherDP: true,
      }));
    }
    if (name === "DP" && value !== "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        otherDP: false,
      }));
    }
    if (name === "batteryGR" && value === "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        otherbatteryGR: true,
      }));
    }
    if (name === "batteryGR" && value !== "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        otherbatteryGR: false,
      }));
    }
    if (name === "chargingGR" && value === "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        otherchargingGR: true,
      }));
    }
    if (name === "chargingGR" && value !== "Other") {
      // console.log(other.othervoltageDC);
      setOther((prevData) => ({
        ...prevData,
        otherchargingGR: false,
      }));
    }
    // console.log(other);
    // console.log(formData);
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setOther((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // console.log(other);
    // console.log(formData);
  };
  const handleForm = () => {
    if (formData.brand && formData.model && formData.fault) {
      setIsFormVisible(true);
    }
  };
  const handleMore = () => {
    setIsNext(true);
    document.getElementById("more").style.display = "none";
  };
  const handleMore1 = () => {
    setIsNext1(true);
    document.getElementById("more1").style.display = "none";
  };

  const handleUpdateSolutionCount = async () => {
    try {
      const token = localStorage.getItem("authenticationToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/shop/update-solution-count";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.put(API, null, config);
      console.log("Response:", response.data);
      localStorage.setItem("solutionCount", response.data.solutionCount);
      localStorage.setItem("paymentStatus", response.data.paymentStatus);
      return response;
    } catch (error) {
      console.error("Error:", error);
      // setShowModal(true);
      return error; // error api trigger and after payment modal should be close  and paymnetupdate error rollback
    }
  };

  const handleSubmit = async (e) => {
    // console.log(formData);
    e.preventDefault();
    setIsButtonPressed(true);
    setLoading(true);
    // console.log(formData.specificFault);
    // console.log(formData);
    // handleUpdateSolutionCount();

    const response = await handleUpdateSolutionCount();
    console.log(response);
    if (response.status === 200) {
      const responseData = response.data;

      if (
        responseData.solutionCount > 5 &&
        responseData.paymentStatus === false
      ) {
        setShowModal(true);
        setIsButtonPressed(false);
        setLoading(false);
      } else {
        try {
          if (!formData.specificFault) {
            setAI(true);
            HandleAI();
            formData.voltageDC =
              formData.voltageDC != "Other"
                ? formData.voltageDC
                : other.voltageDCtxt;
            formData.ampereDC =
              formData.ampereDC != "Other"
                ? formData.ampereDC
                : other.ampereDCtxt;
            formData.DM = formData.DM != "Other" ? formData.DM : other.DMtxt;
            formData.DP = formData.DP != "Other" ? formData.DP : other.DPtxt;
            formData.batteryGR =
              formData.batteryGR != "Other"
                ? formData.batteryGR
                : other.batteryGRtxt;
            formData.chargingGR =
              formData.chargingGR != "Other"
                ? formData.chargingGR
                : other.chargingGRtxt;

            const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
            const API_ENDPOINT = "/diagnosis";
            const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
            const response = await axios.post(
              // "https://repairmymobile.net/diagnosis",
              API,
              formData
            );
            setResult(response.data);
            // console.log(response.data);
            // setFormData((prevData) => ({
            //   ...prevData,
            //   TechSuggestedSol: JSON.stringify(response.data),
            // }));
            formData.TechSuggestedSol = JSON.stringify(response.data);
            setShowError(false);
            // setIsButtonPressed(false);
          } else {
            // HandleAI();
            const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
            const API_ENDPOINT = "/api/specificfaultSol";
            const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
            const response = await axios.post(API, formData);
            setSFResult(response.data);
            // console.log(response.data);
            // setFormData((prevData) => ({
            //   ...prevData,
            //   TechSuggestedSol: JSON.stringify(response.data),
            // }));
            formData.TechSuggestedSol = JSON.stringify(response.data);
            setShowError(false);
            setIsButtonPressed(false);
            setLoading(false);
            await handleAllData();
          }
        } catch (error) {
          console.error("Error:", error);

          setShowError(true);
          setFormData((prevData) => ({
            ...prevData,
            solutionWorked: "Solution not found",
          }));
          setResult(null);
          setSFResult(null);
          // setIsButtonPressed(false);
        }
      }
    } else {
      alert("Something went wrong");
      setIsButtonPressed(false);
      setLoading(false);
    }
  };

  const handleClose = () => setShowModal(false);

  const HandleAIsolution = async () => {
    let AIdata = JSON.stringify({
      brand: formData.brand,
      model: formData.model,
      fault: formData.fault,
      prompt:
        "based on given data above analyse and give me solution1,solution2 from faultSolution provided you, which solved this fault.and do not give explanations,give solutions in list format",
    });
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/apiAI";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API,
      headers: {
        "Content-Type": "application/json",
      },
      data: AIdata,
    };

    await axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        console.log(response.data);
        // setFormData((prevData) => ({
        //   ...prevData,
        //   AISuggestedSol: response.data,
        // }));
        formData.AISuggestedSol = JSON.stringify(response.data);
        setAIresponse(response.data);
        setAImessage(false);
      })
      .catch((error) => {
        console.log(error);
        setIsButtonPressed(false);
      });
  };

  const HandleAI = async () => {
    setIsButtonPressed(true);
    let AIdata = JSON.stringify({
      brand: formData.brand,
      model: formData.model,
      fault: formData.fault,
      prompt:
        "based on given data above analyse and give me shop ids of shops,which solved this fault. give me only shop ids in array format which have ids as string ,and do not give explanation and choose shop Ids only from data provided you shopId Column of FaultFinderdata and please do not give random ids.",
    });
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/apiAI";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: API,
      headers: {
        "Content-Type": "application/json",
      },
      data: AIdata,
    };
    await HandleAIsolution();
    await handleAllData();

    try {
      const response = await axios.request(config);
      setAImessage(false);
      // const APIsolutioResponse = await HandleAIsolution();
      console.log(response.data);
      const formattedData = { _id: response.data };
      const shopAPIResponse = await handleShopId(formattedData);
      setshopDetails(shopAPIResponse);
      setIsButtonPressed(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setIsButtonPressed(false);
      setLoading(false);
    }

    setFormData((prevData) => ({
      ...prevData,
      voltageDC: "",
      ampereDC: "",
      DM: "",
      DP: "",
      batteryGR: "",
      chargingGR: "",
      port: "",
      powerKeyPress: "",
      AISuggestedSol: "",
      TechSuggestedSol: "",
    }));
    setOther({
      othervoltageDC: false,
      voltageDCtxt: "",
      otherampereDC: false,
      ampereDCtxt: "",
      otherDM: false,
      DMtxt: "",
      otherDP: false,
      DPtxt: "",
      otherbatteryGR: false,
      batteryGRtxt: "",
      otherchargingGR: false,
      chargingGRtxt: "",
    });
  };
  const handleShopId = async (data) => {
    console.log(data);
    try {
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/shop/getShopsBy";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.post(API, data);
      console.log(response.data);
      return response.data;
      // setshopDetails(response.data);
    } catch (error) {
      console.error("Error fetching shop details:", error);
      // alert("Error: Something went wrong.");
      setIsButtonPressed(false);
      setLoading(false);
    }
  };
  const handleAllData = async (e) => {
    console.log(formData);
    try {
      formData.voltageDC =
        formData.voltageDC != "Other" ? formData.voltageDC : other.voltageDCtxt;
      formData.ampereDC =
        formData.ampereDC != "Other" ? formData.ampereDC : other.ampereDCtxt;
      formData.DM = formData.DM != "Other" ? formData.DM : other.DMtxt;
      formData.DP = formData.DP != "Other" ? formData.DP : other.DPtxt;
      formData.batteryGR =
        formData.batteryGR != "Other" ? formData.batteryGR : other.batteryGRtxt;
      formData.chargingGR =
        formData.chargingGR != "Other"
          ? formData.chargingGR
          : other.chargingGRtxt;
      formData.solutionWorked = "";
      formData.comment = "";
      formData.steps = "steps-1";
      // formData.AISuggestedSol = AIresponse;
      // if (SFResult !== null) {
      //   formData.TechSuggestedSol = `${SFResult.solution1},${SFResult.solution2},${SFResult.solution3}`;
      // } else if (result !== null) {
      //   formData.TechSuggestedSol = `${result.solution1},${result.solution2}`;
      // }
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/faultFinder";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.post(API, formData);
      console.log(response.data);
      localStorage.setItem("faultId", response.data._id);
      // alert("Data Saved.");
      // setIsFormVisible(false);
      // setShowError(false);
      // setIsNext(false);
      // setIsNext1(false);
      // setResult(null);
      // setSFResult(null);
      // setAIresponse(null);
      // setAI(false);
      // navigate(0);
    } catch (error) {
      console.error("Error:", error);
      // alert("Error: Something went wrong.");
    }
  };
  const handleFormDataUpdate = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const Id = localStorage.getItem("faultId");
      formData.steps = "steps-2";
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/faultFinder";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

      const response = await axios.put(API, {
        id: Id,
        solutionWorked: formData.solutionWorked,
        comment: formData.comment,
        steps: formData.steps,
      });

      console.log(response.data);
      localStorage.removeItem("faultId");
      setIsFormVisible(false);
      setShowError(false);
      setIsNext(false);
      setIsNext1(false);
      setResult(null);
      setSFResult(null);
      setAIresponse(null);
      setAI(false);
      // setFormData({
      //   voltageDC: "",
      //   ampereDC: "",
      //   DM: "",
      //   DP: "",
      //   batteryGR: "",
      //   chargingGR: "",
      //   port: "",
      //   powerKeyPress: "",
      //   brand: "",
      //   model: "",
      //   fault: "",
      //   solutionWorked: "",
      //   comment: "",
      // });
      // setOther({
      //   othervoltageDC: false,
      //   voltageDCtxt: "",
      //   otherampereDC: false,
      //   ampereDCtxt: "",
      //   otherDM: false,
      //   DMtxt: "",
      //   otherDP: false,
      //   DPtxt: "",
      //   otherbatteryGR: false,
      //   batteryGRtxt: "",
      //   otherchargingGR: false,
      //   chargingGRtxt: "",
      // });
      navigate(0);
    } catch (error) {
      console.error("Error:", error);
      // alert("Oops! Something went wrong. Please try again later.");
    }
  };
  const onSearch = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      brand: value,
    }));
    // console.log(formData.brand);
  };
  const onSearchFault = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      fault: value,
    }));
    // console.log(formData.brand);
  };

  // const [paymentId, setPaymentId] = useState("");
  // const [paymentStatus, setPaymentStatus] = useState(false);

  const onSuccess = async (paymentData) => {
    console.log("Payment success:", paymentData);
    console.log(paymentData.razorpay_payment_id);
    // setPaymentId(paymentData.razorpay_payment_id);
    // setPaymentStatus(true); // Update payment status to true
    await updatePaymentDetails(paymentData.razorpay_payment_id); // Wait for updatePaymentDetails to complete
    setShowModal(false);
  };

  const onError = (error) => {
    console.error("Payment error:", error);
  };
  const updatePaymentDetails = async (razorpay_payment_id) => {
    try {
      const token = localStorage.getItem("authenticationToken");
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/shop/update-payment";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      // Use the updated state values directly in the axios PUT request
      const response = await axios.put(
        API,
        { paymentId: razorpay_payment_id, paymentStatus: true }, // Updated state values
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowModal(false);
      console.log("Payment details updated:", response.data);
    } catch (error) {
      console.error("Error updating payment details:", error);
    }
  };
  const handlePayment = useCallback(() => {
    // const order = createOrder(params);

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: "19900",
      currency: "INR",
      name: "RepairMyMobile",
      description: "RMMServiceCenter",
      image: "https://i.ibb.co/BwxV3ws/logo.png",
      // order_id: order.id,

      prefill: {
        name: localStorage.getItem("Name"),
        email: localStorage.getItem("Email"),
      },
      notes: {
        address: "RMM Office Address",
      },
      theme: {
        color: "#3399cc",
      },
      handler: onSuccess,
      onError: onError,
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  return (
    <>
      {localStorage.getItem("paymentStatus") === "false" && (
        <div
          aria-live="polite"
          aria-atomic="true"
          className="position-relative"
          // style={{ maxHeight: "240px" }}
        >
          <ToastContainer
            className="p-3"
            position="top-end"
            style={{ zIndex: 1 }}
          >
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={10000}
              autohide
            >
              <Toast.Header>
                <strong className="me-auto">Notification</strong>
              </Toast.Header>
              <Toast.Body className="fw-bold">
                You are currently on a free trial. You have 5 free trials.
                Upgrade now to get unlimited solutions!
                <Button
                  variant="primary"
                  onClick={handlePayment}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    marginTop: "10px",
                  }}
                >
                  Upgrade Plan
                </Button>
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </div>
      )}
      <div className="fault-finder-div" style={{ paddingTop: "30px" }}>
        {/* Bootstrap modal */}
        <Modal show={showModal} onHide={handleClose} centered backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "blue" }}>
              Upgrade Your Plan
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#f0f0f0" }}>
            <p className="fw-bolder fs-5">
              You have used 5 free solutions. Upgrade now to get unlimited
              solutions!
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="btn btn-outline-success fw-semibold"
              onClick={handlePayment}
            >
              Upgrade Plan
            </Button>
            <Button
              variant="btn btn-outline-dark fw-semibold"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {!isFormVisible && (
          <div className="grid justify-items-center">
            <div className="flex flex-col space-y-4 mb-3">
              <div className="flex flex-row space-x-2 ">
                <label htmlFor="brand">Brand:</label>
                <div className="search-container">
                  <input
                    type="text"
                    placeholder=" Enter Brand"
                    name="brand"
                    value={formData.brand}
                    style={{ color: "black" }}
                    onChange={handleChange}
                    onFocus={handleBrandDropdownToggle}
                    onBlur={handleBrandDropdownToggle}
                  />

                  <div className="dropdown">
                    {uniqueValues.brand &&
                      uniqueValues.brand
                        .filter((item) => {
                          const searchTerm = formData.brand.toLowerCase();
                          // const brandName = item.toLowerCase();
                          // const brandName = (item ?? "").toLowerCase(); // Providing a default value using ??
                          const brandName = item ? item.toLowerCase() : ""; // Checking existence before calling toLowerCase()
                          return (
                            searchTerm &&
                            brandName.startsWith(searchTerm) &&
                            brandName !== searchTerm
                          );
                        })
                        .map((value, index) => (
                          <div
                            className="dropdown-row"
                            key={index}
                            onClick={() => onSearch(value)}
                          >
                            {value}
                          </div>
                        ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-row space-x-1 ">
                <label htmlFor="model">Model:</label>
                <input
                  type="text"
                  placeholder=" Enter Model"
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-row space-x-4">
                <label htmlFor="fault">Fault:</label>
                <div
                  className={`${showBrandDropdown ? "" : "search-container"}`}
                >
                  <input
                    type="text"
                    placeholder=" Enter Fault"
                    name="fault"
                    value={formData.fault}
                    style={{ color: "black" }}
                    onChange={handleChange}
                  />{" "}
                  <div className="dropdown">
                    {uniqueValues.fault &&
                      uniqueValues.fault
                        .filter((item) => {
                          const searchTerm = formData.fault.toLowerCase();
                          const faultName = item.toLowerCase();
                          return (
                            searchTerm &&
                            faultName.startsWith(searchTerm) &&
                            faultName !== searchTerm
                          );
                        })
                        .map((value, index) => (
                          <div
                            className="dropdown-row"
                            key={index}
                            onClick={() => onSearchFault(value)}
                          >
                            {value}
                          </div>
                        ))}
                  </div>
                </div>
              </div>
              <div>OR</div>
              <div className="flex flex-row space-x-2">
                <label htmlFor="specificFault">
                  Specific Fault:
                  <br />
                  <span className="text-xs font-bold">
                    (Without reading)
                  </span>{" "}
                </label>
                <div className="flex flex-col text-black">
                  <select
                    id="specificFault"
                    name="specificFault"
                    value={formData.specificFault}
                    onChange={handleChange}
                  >
                    <option value="">Select...</option>
                    {uniqueValues.specificFault &&
                      uniqueValues.specificFault.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            {getSuggestionbtn === false ? (
              <button
                type="button"
                onClick={handleForm}
                className="fault-button mt-1"
              >
                Next
              </button>
            ) : (
              <button
                type="button"
                onClick={handleSubmit}
                className="fault-button mt-1"
                style={{
                  pointerEvents: isButtonPressed ? "none" : "auto",
                  backgroundColor: isButtonPressed ? "grey" : "",
                }}
              >
                Get Suggestion
              </button>
            )}
          </div>
        )}
        {isFormVisible && (
          <>
            <div
              className="flex space-x-10"
              style={{
                display: "flex",
                justifyContent: "center",
                // border: "2px solid black",
                borderRadius: "30px",
                padding: "20px",
                margin: "20px",
              }}
            >
              <h5 className="underline decoration-green-500 decoration-double underline-offset-4 ">
                Brand: {formData.brand}
              </h5>
              <h5 className="underline decoration-green-500 decoration-double underline-offset-4 ">
                Model: {formData.model}
              </h5>
              <h5 className="underline decoration-green-500 decoration-double underline-offset-4 ">
                Fault: {formData.fault}
              </h5>
            </div>

            <form onSubmit={handleSubmit} className="fault-finder-form">
              <h2 className="fault-finder-head text-center">
                Fault Diagnosis Form
              </h2>

              <div className="fault-finder-field">
                <label htmlFor="voltageDC">Voltage DC:</label>
                <div className="flex flex-col">
                  <select
                    id="voltageDC"
                    name="voltageDC"
                    value={formData.voltageDC}
                    onChange={handleChange}
                  >
                    <option value="">Select...</option>
                    {uniqueValues.voltageDC.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                    <option value="Other">Other</option>
                  </select>
                  {/* {other.othervoltageDC && (
                  <input
                    type="text"
                    name="voltageDC"
                    placeholder="Enter Your Value"
                    onChange={handleChange}
                    value={formData.voltageDC}
                    style={{ marginTop: "10px" }}
                  />
                  // ) : (
                  //   <select
                  //     id="voltageDC"
                  //     name="voltageDC"
                  //     value={formData.voltageDC}
                  //     onChange={handleChange}
                  //   >
                  //     <option value="">Select...</option>
                  //     {uniqueValues.voltageDC.map((value, index) => (
                  //       <option key={index} value={value}>
                  //         {value}
                  //       </option>
                  //     ))}
                  //     <option value="Other">Other</option>
                  //   </select>
                )} */}

                  {other.othervoltageDC ? (
                    <input
                      type="text"
                      name="voltageDCtxt"
                      placeholder="Enter Your Value"
                      onChange={handleInput}
                      value={other.voltageDCtxt}
                      style={{ marginTop: "10px" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="fault-finder-field">
                <label htmlFor="ampereDC">Ampere DC:</label>
                <div className="flex flex-col">
                  <select
                    id="ampereDC"
                    name="ampereDC"
                    value={formData.ampereDC}
                    onChange={handleChange}
                  >
                    <option value="">Select...</option>
                    {uniqueValues.ampereDC.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                    <option value="Other">Other</option>
                  </select>
                  {other.otherampereDC && (
                    <input
                      type="text"
                      name="ampereDCtxt"
                      placeholder="Enter Your Value"
                      onChange={handleInput}
                      value={other.ampereDCtxt}
                      style={{ marginTop: "10px" }}
                    />
                  )}
                </div>
              </div>
              <button
                type="button"
                id="more"
                onClick={handleMore}
                className="fault-button mt-1"
                style={{ float: "right" }}
              >
                More...
              </button>
              {isNext && (
                <>
                  <div className="fault-finder-field">
                    <label htmlFor="port">Port:</label>
                    <div className="flex flex-col">
                      <select
                        id="port"
                        name="port"
                        value={formData.port}
                        onChange={handleChange}
                      >
                        <option value="">Select...</option>
                        {uniqueValues.port.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="fault-finder-field">
                    <label htmlFor="powerKeyPress">Power Key Press:</label>
                    <div className="flex flex-col">
                      <select
                        id="powerKeyPress"
                        name="powerKeyPress"
                        value={formData.powerKeyPress}
                        onChange={handleChange}
                      >
                        <option value="">Select...</option>
                        {uniqueValues.powerKeyPress.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button
                    type="button"
                    id="more1"
                    onClick={handleMore1}
                    className="fault-button mt-1"
                    style={{ float: "right" }}
                  >
                    More...
                  </button>
                </>
              )}
              {isNext1 && (
                <>
                  <div className="fault-finder-field">
                    <label htmlFor="DM">DM:</label>
                    <div className="flex flex-col">
                      <select
                        id="DM"
                        name="DM"
                        value={formData.DM}
                        onChange={handleChange}
                      >
                        <option value="">Select...</option>
                        {uniqueValues.DM.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                        <option value="Other">Other</option>
                      </select>
                      {other.otherDM && (
                        <input
                          type="text"
                          name="DMtxt"
                          placeholder="Enter Your Value"
                          onChange={handleInput}
                          value={other.DMtxt}
                          style={{ marginTop: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="fault-finder-field">
                    <label htmlFor="DP">DP:</label>
                    <div className="flex flex-col">
                      <select
                        id="DP"
                        name="DP"
                        value={formData.DP}
                        onChange={handleChange}
                      >
                        <option value="">Select...</option>
                        {uniqueValues.DP.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                        <option value="Other">Other</option>
                      </select>
                      {other.otherDP && (
                        <input
                          type="text"
                          name="DPtxt"
                          placeholder="Enter Your Value"
                          onChange={handleInput}
                          value={other.DPtxt}
                          style={{ marginTop: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="fault-finder-field">
                    <label htmlFor="batteryGR">Battery GR:</label>
                    <div className="flex flex-col">
                      <select
                        id="batteryGR"
                        name="batteryGR"
                        value={formData.batteryGR}
                        onChange={handleChange}
                      >
                        <option value="">Select...</option>
                        {uniqueValues.batteryGR.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                        <option value="Other">Other</option>
                      </select>
                      {other.otherbatteryGR && (
                        <input
                          type="text"
                          name="batteryGRtxt"
                          placeholder="Enter Your Value"
                          onChange={handleInput}
                          value={other.batteryGRtxt}
                          style={{ marginTop: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="fault-finder-field">
                    <label htmlFor="chargingGR">Charging GR:</label>
                    <div className="flex flex-col">
                      <select
                        id="chargingGR"
                        name="chargingGR"
                        value={formData.chargingGR}
                        onChange={handleChange}
                      >
                        <option value="">Select...</option>
                        {uniqueValues.chargingGR.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                        <option value="Other">Other</option>
                      </select>
                      {other.otherchargingGR && (
                        <input
                          type="text"
                          name="chargingGRtxt"
                          placeholder="Enter Your Value"
                          onChange={handleInput}
                          value={other.chargingGRtxt}
                          style={{ marginTop: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="fault-finder-button">
                <button
                  type="submit"
                  style={{
                    pointerEvents: isButtonPressed ? "none" : "auto",
                    backgroundColor: isButtonPressed ? "grey" : "",
                  }}
                >
                  Get Suggestion
                </button>
              </div>
            </form>
          </>
        )}
        {/* {AI === true && (
        <>
          {AImessage === true ? (
            <div
              className="fault-finder-result"
              style={{ padding: "5px 30px" }}
            >
              <h6 style={{ color: "#b3ffe6" }}>
                Our AI is generating solutions for you...
              </h6>
            </div>
          ) : (
            <div
              className="fault-finder-result"
              style={{ padding: "5px 30px" }}
            >
              <h6 style={{ color: "#b3ffe6" }}>AI-generated solution...</h6>
              <div style={{ whiteSpace: "pre-line" }}>{AIresponse}</div>
            </div>
          )}
        </>
      )} */}
        {AI === true && (
          <>
            {AImessage === true ? (
              <div
                className="fault-finder-result"
                style={{ padding: "5px 30px" }}
              >
                <h6 style={{ color: "#b3ffe6", textAlign: "center" }}>
                  Our AI is generating solutions for you...
                </h6>
                {loading && <Loader />}
              </div>
            ) : (
              <div
                className="fault-finder-result"
                style={{ padding: "5px 30px" }}
              >
                <h6 style={{ color: "#b3ffe6", textAlign: "center" }}>
                  AI-generated solution...
                </h6>
                {loading && <Loader />}
                <h6 style={{ color: "black" }}>Shop Details...</h6>

                <table className="excel-table">
                  <thead>
                    <tr>
                      <th>Shop Name</th>
                      <th>Email</th>
                      <th>Contact Number</th>
                      <th>Shop Address</th>
                      <th>Shop Pincode</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shopDetails &&
                      shopDetails.map((shop) => (
                        <tr key={shop._id}>
                          <td>{shop.shopName}</td>
                          <td>{shop.email}</td>
                          <td>{shop.contactNumber}</td>
                          <td>{shop.shopAddress}</td>
                          <td>{shop.shopAddressZipcode}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <h6 style={{ color: "black" }}>Solutions....</h6>
                <div style={{ whiteSpace: "pre-line" }}>{AIresponse}</div>
              </div>
            )}
          </>
        )}
        {result && (
          <>
            <div
              className="fault-finder-result"
              style={{ padding: " 20px 80px" }}
            >
              <h5
                style={{
                  color: "#b3ffe6",
                  textAlign: "center",
                  marginBottom: "1rem",
                }}
              >
                Technician-Recommended Solutions...
              </h5>
              <h4>Diagnosis Result</h4>
              <p>Fault: {result.fault}</p>
              <p>Solution 1: {result.solution1}</p>
              <p>Solution 2: {result.solution2}</p>
            </div>
            <div
              className="fault-finder-result grid justify-items-center"
              style={{ marginTop: "auto", padding: "20px 30px" }}
            >
              <h5>Are these solutions worked for you?</h5>
              <div>
                <button
                  type="button"
                  onClick={(e) => {
                    handleChange(e);
                  }}
                  name="solutionWorked"
                  value="yes"
                  style={{ marginRight: "15px" }}
                  className="fault-button"
                >
                  Yes
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    handleChange(e);
                  }}
                  name="solutionWorked"
                  value="no"
                  className="fault-button"
                >
                  No
                </button>
              </div>
              <div className="flex flex-row space-x-1 m-2">
                {/* <label htmlFor="comment">Comment...</label> */}
                <textarea
                  id="comment"
                  name="comment"
                  placeholder="Add your comment..."
                  rows="2"
                  className="comment"
                  value={formData.comment}
                  onChange={handleChange}
                  style={{
                    border: "lightgrey solid 2px",
                    marginLeft: "1rem",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <button
                type="submit"
                onClick={handleFormDataUpdate}
                className="mt-2"
              >
                Submit Your Data
              </button>
            </div>
          </>
        )}
        {SFResult && (
          <>
            <div className="fault-finder-result">
              <h3>Diagnosis Result</h3>
              <p>Fault: {SFResult.specificFault}</p>
              <p>Solution 1: {SFResult.solution1}</p>
              <p>Solution 2: {SFResult.solution2}</p>
              <p>Solution 3: {SFResult.solution3}</p>
            </div>
            <div
              className="fault-finder-result grid justify-items-center"
              style={{ marginTop: "auto", padding: "20px 30px" }}
            >
              <h5>Are these solutions worked for you?</h5>
              <div>
                <button
                  type="button"
                  onClick={(e) => {
                    handleChange(e);
                  }}
                  name="solutionWorked"
                  value="yes"
                  style={{ marginRight: "15px" }}
                  className="fault-button"
                >
                  Yes
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    handleChange(e);
                  }}
                  name="solutionWorked"
                  value="no"
                  className="fault-button"
                >
                  No
                </button>
              </div>
              <div className="flex flex-row space-x-1 m-2">
                {/* <label htmlFor="comment">Comment...</label> */}
                <textarea
                  id="comment"
                  name="comment"
                  placeholder="Add your comment..."
                  rows="2"
                  className="comment"
                  value={formData.comment}
                  onChange={handleChange}
                  style={{
                    border: "lightgrey solid 2px",
                    marginLeft: "1rem",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <button
                type="submit"
                onClick={handleFormDataUpdate}
                className="mt-2"
              >
                Submit Your Data
              </button>
            </div>
          </>
        )}
        {showError && (
          <div
            className="fault-finder-result text-center"
            // style={{ padding: "20px 65px" }}
          >
            <h5 style={{ color: "#fafafa" }} className="fst-italic fw-bold">
              {/* No solution found for this reading. Our algorithm is generating
              solution, solution will be updated in 45 minutes, Please check
              after some time. */}
            </h5>
            <div className=" m-3 text-center">
              {/* <label htmlFor="comment">Comment...</label> */}
              <textarea
                id="comment"
                name="comment"
                placeholder="Add your comment..."
                rows="2"
                className="comment"
                value={formData.comment}
                onChange={handleChange}
                style={{
                  border: "lightgrey solid 2px",
                  // marginLeft: "1rem",
                  borderRadius: "5px",
                }}
              />
            </div>
            <button
              type="submit"
              onClick={handleFormDataUpdate}
              className="mt-1 fault-finder-button"
              style={{ display: "inline" }}
            >
              Submit Your Data
            </button>
          </div>
        )}
        <div className="grid md:grid-cols-2 md:gap-6 mt-4">
          <div className="max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-2">
            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 ">
              Call us directly at
            </h5>

            <p className="mb-3 font-medium text-gray-800 dark:text-gray-800">
              +91-9060 2999 19
            </p>
          </div>
          <div className="max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 ">
              Contact customer support
            </h5>

            <a
              href="https://us02web.zoom.us/j/82843448892?pwd=S3QxWXcyczd0NXN1TzlqRUFwQ1U0Zz09"
              target="blank"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Join Live Class
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaultFinder;
