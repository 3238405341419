import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tabulator/lib/styles.css";
import { ReactTabulator } from "react-tabulator";
export default function AdminDashboard() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [currentShop, setCurrentShop] = useState(null);
  const [creditToAdd, setCreditToAdd] = useState("");
  useEffect(() => {
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/shop";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    axios
      .get(API)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const handleOpenCreditModal = (shop) => {
    setCurrentShop(shop);
    setShowCreditModal(true);
  };

  const handleCloseCreditModal = () => {
    setShowCreditModal(false);
  };
  const handleAddCredit = () => {
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/shop/addCredit";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

    const body = {
      shopId: currentShop._id,
      creditToAdd: creditToAdd,
    };
    axios
      .put(API, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "authenticationToken"
          )}`,
        },
      })
      .then((response) => {
        alert("Credit added successfully");
        // toast.success("Credit added successfully");
        handleCloseCreditModal();
        navigate(0);
      })
      .catch((error) => {
        console.error("Failed to add credit:", error);
        // alert("Failed to add credit");
        toast.error("Failed to add credit");
      });
  };
  const AddCreditFormatter = (cell) => {
    const rowData = cell.getRow().getData();

    const buttonNode = document.createElement("button");
    buttonNode.className = "credit-button";

    const creditAmountSpan = document.createElement("span");
    creditAmountSpan.className = "credit-amount";
    creditAmountSpan.textContent = rowData.credit;
    buttonNode.appendChild(creditAmountSpan);

    const addCreditSpan = document.createElement("span");
    addCreditSpan.className = "add-credit";
    addCreditSpan.textContent = "➕ Credit";
    buttonNode.appendChild(addCreditSpan);

    buttonNode.addEventListener("click", () => {
      handleOpenCreditModal(rowData);
    });

    return buttonNode;
  };

  const columns = [
    { title: "S_No.", formatter: "rownum", hozAlign: "left", width: 40 },
    {
      title: "Name",
      field: "name",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
      // eventResizeFeature: true,
    },
    {
      title: "Email",
      field: "email",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Shop_Name",
      field: "shopName",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Shop_Address",
      field: "shopAddress",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "ShopAddress_Zipcode",
      field: "shopAddressZipcode",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Contact_Number",
      field: "contactNumber",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    // {
    //   title: "CreatedAt",
    //   field: "createdAt",
    //   hozAlign: "left",
    //   resizable: true,
    //   headerFilter: "input",
    // },
    {
      title: "View_Credit",
      field: "credit",
      hozAlign: "left",
      resizable: true,
      formatter: AddCreditFormatter,
    },
  ];
  return (
    <div>
      <ReactTabulator
        data={data}
        columns={columns}
        options={{
          layout: "fitColumns",
          pagination: "local", // Enable pagination
          paginationSize: 16, // Number of rows per page
          movableColumns: true, // Allow columns to be reordered
          paginationCounter: "rows",
          tooltips: true,
          resizableColumnWidths: false, // Set to false to prevent resize errors
          resizableRowWidths: false,
          movableRows: true,
          resizableColumns: true,
          // resizableRows: true,
          resizableColumnFit: true,
        }}
      />
      <Modal
        show={showCreditModal}
        onHide={handleCloseCreditModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Credit to Shop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Current Credit: {currentShop ? currentShop.credit : 0}</p>
          <InputGroup>
            <FormControl
              placeholder="Credit to add"
              aria-label="Credit to add"
              type="number"
              value={creditToAdd}
              onChange={(e) => setCreditToAdd(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreditModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddCredit}>
            Add Credit
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}
