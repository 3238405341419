import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import { useEffect, useState } from "react";
import axios from "axios";



export default function PaymentRazorpay({ orderId, handleClick }) {
  const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`
  const [Razorpay, isLoaded] = useRazorpay();
  
  const [orderDetails, setOrderDetails] = useState({});
  
  const getOrder = (async () => {
    const loggedInUser = localStorage.getItem("authenticationToken");
    
    let data = JSON.stringify({
      "orderId": orderId
    });
    
    let API_ENDPOINT = "/api/orders/getOrdersBy"
    let API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: API,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loggedInUser}`
      },
      data: data
    };

    axios.request(config).then(function (response) {
      console.log(response.data);
      setOrderDetails(response.data[0]);

    }).catch(function (error) {
      console.error(error);
    });
  });

  const handlePayment = useCallback(async () => {

    await getOrder(orderId);
    console.log(orderDetails);

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: "3000",
      currency: "INR",
      name: "RepairMyMobile",
      description: "RMMSupport ",
      image: "https://i.ibb.co/BwxV3ws/logo.png",
      order_id: orderDetails.orderId,
      handler: (res) => {
        console.log({res});
        if (res["razorpay_payment_id"]) {

          const loggedInUser = localStorage.getItem("authenticationToken");
          let API_ENDPOINT = "/api/orders/modify"
          let API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: API,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${loggedInUser}`
            },
            data: { "orderId": orderId,"paymentStatus":1, "paymentId": res["razorpay_payment_id"] }
          };

          axios.request(config).then(function (response) {
            console.log(response.data);
            handleClick("next");
            document.getElementById("next-btn").style.display = "block"

          }).catch(function (error) {
            console.error(error);
            alert("err payment failed")
            
            
          });

        }
        else {
          handleClick("back");
          document.getElementById("next-btn").remove()
        }
      },
      prefill: {
        name: orderDetails.name,
        email: orderDetails.email,
        contact: orderDetails.userContactNumber,
      },
      notes: {
        address: "RMM Office Address",
      },
      theme: {
        color: "#3399cc",
      },
    };
    console.log(orderDetails);

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  // useEffect(() => {
  //   console.log(isLoaded);
  //   // document.getElementById("next-btn")?.remove()
  //   document.getElementById("next-btn").style.display = "none"
  //   if (isLoaded) {
  //     handlePayment();
  //   }
  // }, [isLoaded, handlePayment]);
  useEffect(() => {

  })

  return (
    // <div className="text-3xl grid place-items-center">
    //   Upon Payment, you will automatically be redirected...
    //   <div>
    //   <button onClick={handlePayment} className="bg-blue-800 text-white uppercase py-2 px-2 rounded-xs font-semibold 
    //                 cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hower:text-white transition 
    //                 duration-2oo ease-in-out">
    //                 Pay</button>
    //   </div>
    // </div>
    <div>
      No need of Payment
    </div>
  );
}