import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import GoogleLogin, { useGoogleLogin } from "react-google-login";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Login({
  setRoleId,
  setName,
  setMeetLink,
  setIsTrainer,
  setPaymentStatus,
}) {
  const logo = require("../assets/bg.png");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") || false
  );
  const location = useLocation();
  useEffect(() => {
    console.log("location.pathname", location.pathname);
    localStorage.setItem("prevPath", location.pathname);
  });

  if (authenticated) {
    const roleIdLocalStorage = localStorage.getItem("role");
    const roleId = parseInt(roleIdLocalStorage);
    console.log(roleId);
    if (roleId === 2) return <Navigate replace to="/supportOrders" />;
    else if (roleId === 1) {
      return <Navigate replace to="/neworder" />;
    } else if (roleId === 9) {
      return <Navigate replace to="/adminDashboard" />;
    }
  }

  function togglePassword(event) {
    setPasswordType(passwordType === "password" ? "text" : "password");
  }

  function loginUser() {
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    // const API_ENDPOINT = "/api/users/login";
    var API_ENDPOINT = "";

    if (location.pathname == "/shoplogin") {
      API_ENDPOINT = "/api/shop/login";
    } else {
      API_ENDPOINT = "/api/users/login";
    }

    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

    const options = {
      method: "POST",
      mode: "no-cors",
      url: API,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ email, password }),
    };

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
      alert("You have entered an invalid email address!");
    } else {
      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
          if (response.data.token) {
            localStorage.setItem("authenticated", true);
            localStorage.setItem("authenticationToken", response.data.token);
            localStorage.setItem("role", response.data.roleId);
            localStorage.setItem("shopName", response.data.shopName);
            localStorage.setItem("shopAddress", response.data.shopAddress);
            localStorage.setItem("Email", response.data.email);
            localStorage.setItem("contactNumber", response.data.contactNumber);
            localStorage.setItem("Id", response.data._id);
            localStorage.setItem("Name", response.data.name);
            localStorage.setItem("isTrainer", response.data.isTrainer);
            localStorage.setItem("solutionCount", response.data.solutionCount);
            localStorage.setItem("paymentStatus", response.data.paymentStatus);

            setAuthenticated(true);
            setIsTrainer(response.data.isTrainer);
            setPaymentStatus(response.data.paymentStatus);
            setRoleId(response.data.roleId);
            setName(response.data.name);
            if (response.data.roleId == 1) {
              // setMeetLink(response.data.meetLink);
            }

            console.log(response.data.roleId);
            if (response.data.roleId === 0) navigate("/newOrder");
            if (response.data.roleId === 1) navigate("/neworder");
            if (response.data.roleId === 2) navigate("/supportOrders");
            // navigate("/FaultFinder");
            if (response.data.roleId === 9) navigate("/adminDashboard");
          } else {
            alert("Username or password is wrong!! Please try again !!");
            toast.error("Invalid email or Password");
          }
        })
        .catch(function (error) {
          console.error(error);
          // alert("Username or password is wrong!! Please try again !!");
          toast.error("Username or password is wrong!! Please try again !!");
        });
    }
  }
  // Data for Google Login
  // const clientId=process.env.REACT_APP_GOOGLE_CLIENT_ID
  // Functions for Google Login
  // const onSuccess = (res) => {
  //     console.log('Login Success:currentUser:', res.profileObj);
  // }

  // const onFailure = (res) => {
  //     console.log('login failed: res', res)
  // }

  return (
    <div
      className="flex mt-5 content-center items-center md:w-1/2 mx-auto "
      style={{ marginTop: "190px" }}
    >
      <div className="row mx-auto place-items-center content-center py-10 pr-10 pb-10 bg-gray-50 shadow-xl rounded-2xl">
        <div className="col-md-6 ">
          <div className=" text-black-400 text-xl font-bold pt-5 px-1">
            Log in to your Account
          </div>
          <div className="flex flex-col text-black-400 text-lg py-0 pb-2 px-1">
            Welcome back!
          </div>
          {/* <div className="grid grid-cols-2 place-items-center">
            <div>
              <GoogleLogin
                            clientId={clientId}
                            buttonText="Login With Google"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={"single_host_origin"}
                            isSignedIn={true}
                            /> 
            </div>
          </div> */}
          <div class="inline-flex items-center justify-center w-full">
            <hr class="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
          </div>

          <div>
            <div className="text-black-400 p-2">
              <input
                placeholder="Email"
                onChange={(event) => setEmail(event.target.value)}
                className="rounded-lg bg-gray-200 mt- p-2 focus:border-indigo-800  "
                type="text"
              />
            </div>
            <div className="flex text-black-400 p-2">
              <input
                placeholder="Password"
                onChange={(event) => setPassword(event.target.value)}
                className="rounded-lg bg-gray-200 mt- p-2 focus:border-indigo-800  "
                type={passwordType}
              />
              <button className="h-10" onClick={togglePassword}>
                <EyeIcon className="text-grey-100 ml-1 h-5" />
              </button>
            </div>
          </div>
          <div className="flex  py-2">
            {/* <p className="flex items-center">
              <input className="mr-2" type="checkbox" />
              Remember Me
            </p> */}
            <p className="flex items-center">Forgot your password? </p>
            <Link to="/reset-password" class="authPageLink">
              Reset here
            </Link>
          </div>
          {location.pathname == "/shoplogin" && (
            <div style={{ fontSize: "1.2rem" }}>
              <Link to="/signup">
                <span>New Here? create your account</span>
              </Link>
            </div>
          )}
          <button
            onClick={loginUser}
            className=" text-white w-full justify-center my-5 py-2  bg-indigo-500 shadow-lg shadow-indigo-800/50 hover:shadow-indigo-800/20 rounded-lg"
          >
            Log In{" "}
          </button>
        </div>

        <div className="col-md-6  d-none d-md-block">
          <img
            className="w-full h-full object-cover"
            src={logo}
            alt="RMM Logo"
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
