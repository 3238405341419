import React, { useEffect, useState } from "react";
import SidebarItem from "./SidebarItem";
import { Link, NavLink } from "react-router-dom";
import {
  HomeIcon,
  BuildingStorefrontIcon,
  NewspaperIcon,
  Square3Stack3DIcon,
  ArrowRightOnRectangleIcon,
  ComputerDesktopIcon,
  Squares2X2Icon,
  UserPlusIcon,
  PresentationChartBarIcon,
  DocumentPlusIcon,
  PresentationChartLineIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  IdentificationIcon,
  CreditCardIcon,
  AdjustmentsHorizontalIcon,
  ClipboardDocumentListIcon,
  WalletIcon,
} from "@heroicons/react/24/solid";

export default function SideBar({ roleId, meetLink }) {
  const [roleIdLocalStorage, setRoleIdLocalStorage] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    setRoleIdLocalStorage(localStorage.getItem("role"));
    console.log({ roleIdLocalStorage });
  });

  if (roleIdLocalStorage == 0) {
    return (
      <div
        className="w-60  p-5 bg-blue-800 font-bold"
        style={{ flex: "1", paddingBottom: "0", height: "87vh" }}
      >
        <SidebarItem path={"newOrder"} desc={"Home"} tooltip="Go to home page">
          {" "}
          <HomeIcon className="h-20 w-8 mx-2" />{" "}
        </SidebarItem>
        {/* <SidebarItem path={"payment"} desc={"Payment History"}> <BuildingOfficeIcon className="h-20 w-8 mx-2" /> </SidebarItem> */}
        <SidebarItem
          path={"orders"}
          desc={"Order History"}
          tooltip="View your order history"
        >
          {" "}
          <NewspaperIcon className="h-20 w-8 mx-2" />{" "}
        </SidebarItem>
        {/* <SidebarItem path={"history"} desc={"Appointments"}> <CalendarIcon className="h-20 w-8 mx-2" /> </SidebarItem> */}
        <SidebarItem
          path={"logout"}
          desc={"Logout"}
          tooltip="Log out of your account"
        >
          {" "}
          <ArrowRightOnRectangleIcon className="h-20 w-8 mx-2" />{" "}
        </SidebarItem>
      </div>
    );
  } else if (roleIdLocalStorage == 1) {
    return (
      <div
        className={`w-60 px-md-5 bg-blue-800 font-bold ${
          isSidebarOpen
            ? "flex basis-1/5 flex-column"
            : "flex flex-column basis-1/12"
        }`}
        style={{
          // flex: "1",
          paddingBottom: "0",
          // height: "87vh",
          position: "relative",
        }}
      >
        <button
          className="relative  top-2 mt-2 mx-2 right-0"
          style={{
            alignSelf: "end",
            // position: "absolute",
            // right: "45px" ,
            marginBottom: "25px",
          }}
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? (
            <ChevronDoubleLeftIcon className="h-8 w-8 text-white" />
          ) : (
            <ChevronDoubleRightIcon className="h-8 w-8 text-white" />
          )}
        </button>
        <SidebarItem
          path={"neworder"}
          desc={!isSidebarOpen ? "" : "Home"}
          tooltip="Go to home page"
        >
          {" "}
          <HomeIcon className="h-20 w-8 mx-2" />{" "}
        </SidebarItem>
        {/* <SidebarItem path={"payment"} desc={"Payment History"}> <BuildingOfficeIcon className="h-20 w-8 mx-2" /> </SidebarItem> */}
        <SidebarItem
          path={"orders"}
          desc={!isSidebarOpen ? "" : "Order History"}
          tooltip="View your order history"
        >
          {" "}
          <NewspaperIcon className="h-20 w-8 mx-2" />{" "}
        </SidebarItem>
        {/* <div>
          <button
            className="space-x items-center text-white flex"
            onClick={() => {
              window.open(meetLink, "_blank", "noreferrer");
            }}
          >
            <ComputerDesktopIcon className="h-20 w-8 mx-2" />
            Google Meet
          </button>
        </div> */}

        {/* <SidebarItem path={"history"} desc={"Appointments"}> <CalendarIcon className="h-20 w-8 mx-2" /> </SidebarItem> */}

        <SidebarItem
          path={"logout"}
          desc={!isSidebarOpen ? "" : "Logout"}
          tooltip="Log out of your account"
        >
          {" "}
          <ArrowRightOnRectangleIcon className="h-20 w-8 mx-2" />{" "}
        </SidebarItem>
      </div>
    );
  }
  // shop login
  else if (roleIdLocalStorage == 2) {
    return (
      <div
        className={`w-60 px-md-5 bg-blue-800 font-bold ${
          isSidebarOpen
            ? "flex basis-1/5 flex-column"
            : "flex flex-column basis-1/12"
        }`}
        style={{
          // flex: "1",
          paddingBottom: "0",
          // height: "87vh",
          position: "relative",
        }}
      >
        <button
          className="relative  top-2 mt-2 mx-2 right-0"
          style={{
            alignSelf: "end",
            // position: "absolute",
            // right: "45px" ,
            marginBottom: "25px",
          }}
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? (
            <ChevronDoubleLeftIcon className="h-8 w-8 text-white" />
          ) : (
            <ChevronDoubleRightIcon className="h-8 w-8 text-white" />
          )}
        </button>
        <SidebarItem
          path={"supportOrders"}
          desc={!isSidebarOpen ? "" : "Home"}
          tooltip="Go to home page"
        >
          {" "}
          <HomeIcon className="h-20 w-8 mx-2" />{" "}
        </SidebarItem>
        {/* <SidebarItem path={"payment"} desc={"Payment History"}> <BuildingOfficeIcon className="h-20 w-8 mx-2" /> </SidebarItem> */}
        <SidebarItem
          path={"orders"}
          desc={!isSidebarOpen ? "" : "Order History"}
          tooltip="View your order history"
        >
          {" "}
          <NewspaperIcon className="h-20 w-8 mx-2" />{" "}
        </SidebarItem>
        <SidebarItem
          path={"shopCredit"}
          desc={!isSidebarOpen ? "" : "Shop Credit"}
          tooltip="View your Credit"
        >
          {" "}
          <WalletIcon className="h-20 w-8 mx-2" />{" "}
        </SidebarItem>
        {/* <div>
          <button
            className="space-x items-center text-white flex"
            onClick={() => {
              window.open(meetLink, "_blank", "noreferrer");
            }}
          >
            <ComputerDesktopIcon className="h-20 w-8 mx-2" />
            Google Meet
          </button>
        </div> */}
        <div>
          {/* <button className="space-x items-center text-white flex" onClick={()=>{window.open(meetLink, "_blank", "noreferrer")}}>
                        <ComputerDesktopIcon className="h-20 w-8 mx-2" />
                        Invoices
                    </button> */}
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "space-x items-center text-white flex bg-blue-500 sidebarLink"
                : "space-x items-center text-white flex sidebarLink"
            }
            to={`/${"FaultFinder"}`}
            title="Fault Finder"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-20 w-8 mx-2"
            >
              <path
                fillRule="evenodd"
                d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z"
                clipRule="evenodd"
              />
            </svg>{" "}
            {/* FaultFinder */}
            {!isSidebarOpen ? "" : "FaultFinder"}
          </NavLink>
          <SidebarItem
            path={"Payment"}
            desc={!isSidebarOpen ? "" : "Upgrade Plan"}
            tooltip="Upgrade your plan"
          >
            {" "}
            <CreditCardIcon className="h-20 w-8 mx-2" />{" "}
          </SidebarItem>
          {/* {localStorage.getItem("paymentStatus") === "true" && (
            <> */}
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "space-x items-center text-white flex bg-blue-500 sidebarLink"
                : "space-x items-center text-white flex sidebarLink"
            }
            to={`/${"Invoice"}`}
            // style={{ marginTop: "50px" }}
            title="Your Invoice"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-20 w-8 mx-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>{" "}
            {!isSidebarOpen ? "" : "Invoice"}
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "space-x items-center text-white flex bg-blue-500 sidebarLink"
                : "space-x items-center text-white flex sidebarLink"
            }
            to={`/${"InvoiceHistory"}`}
            // style={{ marginTop: "50px" }}
            title="View Your Invoice History"
          >
            {/* <ComputerDesktopIcon className="h-20 w-8 mx-2" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-20 w-8 mx-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
              />
            </svg>
            {/* InvoiceHistory */}
            {!isSidebarOpen ? "" : "InvoiceHistory"}
          </NavLink>
          {/* </>
          )} */}
        </div>
        {/* <SidebarItem path={"history"} desc={"Appointments"}> <CalendarIcon className="h-20 w-8 mx-2" /> </SidebarItem> */}
        {/* {localStorage.getItem("isTrainer") === "true" && (
          <SidebarItem
            path={"SellorBuy"}
            desc={!isSidebarOpen ? "" : "Buy/Sell"}
          >
            {" "}
            <BuildingStorefrontIcon className="h-20 w-8 mx-2" />{" "}
          </SidebarItem>
        )} */}
        <SidebarItem
          path={"shopFaultData"}
          desc={!isSidebarOpen ? "" : "ShopFaultData"}
          tooltip="Your FaultFinder data"
        >
          {" "}
          <AdjustmentsHorizontalIcon className="h-20 w-8 mx-2" />{" "}
        </SidebarItem>
        <SidebarItem
          path={"logout"}
          desc={!isSidebarOpen ? "" : "Logout"}
          tooltip="Log out of your account"
        >
          {" "}
          <ArrowRightOnRectangleIcon className="h-20 w-8 mx-2" />{" "}
        </SidebarItem>
      </div>
    );
  } else if (roleIdLocalStorage == 9) {
    return (
      <div
        // className="w-60 space-y-10 p-5 h-screen bg-blue-800 font-bold"
        // style={{ flex: "1", maxHeight: "91vh" }}
        className={`w-60 h-auto px-md-5 bg-blue-800 font-bold ${
          isSidebarOpen
            ? "flex basis-1/5 flex-column"
            : "flex flex-column basis-1/12"
        }`}
        style={{
          // flex: "1",
          paddingBottom: "0",

          position: "relative",
        }}
      >
        <button
          className="relative  top-2 mt-2 mx-2 right-0"
          style={{
            alignSelf: "end",
            // position: "absolute",
            // right: "45px" ,
            marginBottom: "25px",
          }}
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? (
            <ChevronDoubleLeftIcon className="h-8 w-8 text-white" />
          ) : (
            <ChevronDoubleRightIcon className="h-8 w-8 text-white" />
          )}
        </button>
        <SidebarItem
          path={"adminDashboard"}
          desc={!isSidebarOpen ? "" : "Dashboard"}
          tooltip="Your dashboard"
        >
          {" "}
          <Squares2X2Icon className="h-14 w-8 mx-2" />{" "}
        </SidebarItem>
        <SidebarItem
          path={"addProfile"}
          desc={!isSidebarOpen ? "" : "Add Profile"}
          tooltip="Add Shop/Support profile"
        >
          {" "}
          <UserPlusIcon className="h-14 w-8 mx-2" />{" "}
        </SidebarItem>
        <SidebarItem
          path={"faultFinderData"}
          desc={!isSidebarOpen ? "" : "FaultFinder Data"}
          tooltip="Fault Finder Data"
        >
          {" "}
          <PresentationChartBarIcon className="h-14 w-8 mx-2" />{" "}
        </SidebarItem>
        <SidebarItem
          path={"userFaultFinderData"}
          desc={!isSidebarOpen ? "" : "User_FaultFinder Data"}
          tooltip="User_FaultFinder Data"
        >
          {" "}
          <IdentificationIcon className="h-14 w-8 mx-2" />{" "}
        </SidebarItem>
        <SidebarItem
          path={"addFaultSolution"}
          desc={!isSidebarOpen ? "" : "Add Solution"}
          tooltip="Add Fault Solution"
        >
          {" "}
          <DocumentPlusIcon className="h-14 w-8 mx-2" />{" "}
        </SidebarItem>
        <SidebarItem
          path={"allSolution"}
          desc={!isSidebarOpen ? "" : "All Solutions"}
          tooltip="All Fault Solution"
        >
          {" "}
          <Square3Stack3DIcon className="h-14 w-8 mx-2" />{" "}
        </SidebarItem>
        <SidebarItem
          path={"addSpecificFaultSolution"}
          // desc={"Add SpecificFault Solution"}
          desc={!isSidebarOpen ? "" : "Add SpecificFault Solution"}
          tooltip="Add SpecificFault Solution"
        >
          <DocumentPlusIcon className="h-14 w-8 mx-2" />{" "}
        </SidebarItem>
        <SidebarItem
          path={"SpecificFaultData"}
          desc={!isSidebarOpen ? "" : "SpecificFault Data"}
          tooltip="SpecificFault Data"
        >
          <PresentationChartLineIcon className="h-14 w-8 mx-2" />{" "}
        </SidebarItem>
        <SidebarItem
          path={"orderData"}
          desc={!isSidebarOpen ? "" : "Orders Data"}
          tooltip="Orders Data"
        >
          <ClipboardDocumentListIcon className="h-14 w-8 mx-2" />{" "}
        </SidebarItem>
        <SidebarItem
          path={"logout"}
          desc={!isSidebarOpen ? "" : "Logout"}
          tooltip="Log out of your account"
        >
          {" "}
          <ArrowRightOnRectangleIcon className="h-14 w-8 mx-2" />{" "}
        </SidebarItem>
      </div>
    );
  }
}
