import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import CustomerQuery from "./SupportOrderItem";
import { Navigate } from "react-router";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";
import { PhoneArrowDownLeftIcon } from "@heroicons/react/24/solid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";
export default function SupportOrders() {
  const [orders, setOrders] = useState([]);
  const [isCredit, setIsCredit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [data, setData] = useState([]);
  const getCreditDetails = async () => {
    setLoading(true);
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/shop/credit";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    axios
      .get(API, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "authenticationToken"
          )}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        if (response.data.credit < 10) {
          setIsCredit(false);
          setLoading(false);
          setShowCreditModal(true);
        } else {
          setIsCredit(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to get credit balance");
        setLoading(false);
      });
  };
  useEffect(() => {
    getCreditDetails();
    const loggedInUser = localStorage.getItem("authenticationToken");
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

    // const API_ENDPOINT = "/api/orders/getMyOrders"
    const API_ENDPOINT = "/api/orders/getShopOrders";

    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    if (loggedInUser) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: API,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      };
      axios
        .request(config)
        .then(function (response) {
          console.log(response.data);
          setOrders(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      return <Navigate replace to="/login" />;
    }
  }, []);

  const handleCloseCreditModal = () => {
    setShowCreditModal(false);
  };

  if (orders.length > 0) {
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <div className="w-full md:w-5/6 text-base md:text-xl lg:text-2xl mx-auto">
            <div className="text-3xl lg:text-4xl p-5">Customer Queries</div>
            <div className="grid grid-rows-flow gap-5  ">
              {Array.from(orders)
                .filter(
                  (order) =>
                    !order.shopQuotationIDs.includes(localStorage.getItem("Id"))
                )
                .map((order) => (
                  <CustomerQuery
                    key={order.orderId}
                    orderDetails={order}
                    isCredit={isCredit}
                  />
                ))}
            </div>
            <Modal
              show={showCreditModal}
              onHide={handleCloseCreditModal}
              centered
              backdrop="static"
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Your Credit Balance</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5 className="text-danger fw-bold">Insufficient Credits</h5>
                <p className="mt-3">
                  You currently have <strong>{data ? data.credit : 0}</strong>{" "}
                  credits. Additional credits are required to continue accessing
                  our services.
                </p>
                <p className="mt-2 fw-medium text-primary-emphasis">
                  Please contact our customer support to discuss additional
                  credit packages or for any billing inquiries. Our team is
                  ready to assist you with various options to suit your needs.
                </p>
                <div className="mt-4">
                  <Button
                    variant="outline-primary"
                    className="d-flex align-items-center"
                  >
                    <PhoneArrowDownLeftIcon className="h-6 w-6 mr-2" />
                    <span className="fw-bold">+91-9060 2999 19</span>
                  </Button>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseCreditModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </>
    );
  } else {
    return (
      <div>
        <h1> No Orders Available, Try again later!!!</h1>
      </div>
    );
  }
}
