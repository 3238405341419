import React from "react";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import OrderHistoryItem from "./OrderHistoryItem";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function OrderHistory() {
  const [orders, setOrders] = useState([]);
  const [solutions, setSolutions] = useState([]);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticationToken");

    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/orders/orderHistory";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

    if (loggedInUser) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: API,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      };
      axios
        .request(config)
        .then(function (response) {
          console.log(response.data);
          setOrders(response.data.orders);
          setSolutions(response.data.solutions);
          if (response.data.orders.length === 0) {
            toast.error("No orders found for this shop.");
          }
        })
        .catch(function (error) {
          console.error(error);
          toast.error("Server Error");
        });
    } else {
      return <Navigate replace to="/login" />;
    }
  }, []);

  return (
    <div className="w-[100%] flex flex-col items-center">
      <div className="text-4xl p-5 ">Order History</div>
      <div className="grid grid-cols gap-5 p-5 shadow-xl w-[100%] items-center rounded-2xl pb-2 ">
        {orders.map((order) => {
          const orderSolution = solutions.find(
            (solution) => solution.orderId === order.orderId
          );
          return (
            <OrderHistoryItem
              key={order._id}
              order={order}
              solution={orderSolution}
            />
          );
        })}
      </div>

      <ToastContainer />
    </div>
  );
}
