import React from "react";
import { useState, useEffect } from "react";
import ShopItem from "./ShopItem";
import axios from "axios";

export default function Shops() {
    
    const [shops, setShops] = useState([]);
    
    useEffect(() => {
        const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`
        const API_ENDPOINT = "/api/shop/"
        const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`

        const loggedInUser = localStorage.getItem("authenticationToken");

        const data = JSON.stringify({
            "roleId": 2
        });

        const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: API,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${loggedInUser}`
            },
            data: data
        };

        axios.request(config).then(function (response) {

            console.log(response.data);
            setShops(response.data);

        }).catch(function (error) {
            console.error(error);
        });
    }, []);

    const loggedInUser = localStorage.getItem("authenticationToken");
    if (loggedInUser) {
        if (shops.length > 0) {
            return (
                <div className="overflow-y-scroll">
                    <div className="text-4xl p-5">
                        Available Shops
                    </div>
                    <div className="grid grid-rows-flow gap-5 p-10 ">
                        {

                            Array.from(shops).map((shop) => { return <ShopItem shopId={shop._id} shopDetails={shop} /> })

                        }
                    </div>
                    <div>

                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <h1> No Shops Available, Try again later!!!</h1>
                </div>
            );
        }
    } else {
        // navigate to /login
    }
}