import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-tabulator/lib/styles.css";
import { ReactTabulator } from "react-tabulator";
import { useNavigate } from "react-router-dom";
const AllSolution = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/faultSolution";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    axios
      .get(API)
      .then((response) => {
        setData(response.data);
        // console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const EditFormatter = (cell) => {
    const rowData = cell.getRow().getData();

    const buttonNode = document.createElement("button");
    buttonNode.textContent = "Edit";
    buttonNode.classList.add("edit-button");
    buttonNode.addEventListener("click", () => {
      navigate("/editAllSolution", { state: { rowData } });
    });

    return buttonNode;
  };
  const DeleteFormatter = (cell) => {
    const rowData = cell.getRow().getData();

    const buttonNode = document.createElement("button");
    buttonNode.textContent = "Delete";
    buttonNode.classList.add("delete-button");
    buttonNode.addEventListener("click", () => {
      // console.log(rowData._id);
      deleteData(rowData._id);
    });

    return buttonNode;
  };
  const deleteData = async (_id) => {
    try {
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = `/api/faultSolution/${_id}`;
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      await axios.delete(API);
      console.log("Data deleted successfully.");
      alert("Data deleted successfully.");
      navigate(0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const columns = [
    { title: "S_No.", formatter: "rownum", hozAlign: "left", width: 80 },
    {
      title: "Voltage DC",
      field: "voltageDC",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
      // eventResizeFeature: true,
    },
    {
      title: "Ampere DC",
      field: "ampereDC",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "DM",
      field: "DM",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "DP",
      field: "DP",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Battery GR",
      field: "batteryGR",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Charging GR",
      field: "chargingGR",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Port",
      field: "port",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Power Key Press",
      field: "powerKeyPress",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Fault",
      field: "fault",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Solution 1",
      field: "solution1",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Solution 2",
      field: "solution2",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    // {
    //   title: "Edit",
    //   formatter: (cell, formatterParams, onRendered) => {
    //     return "<button class='edit-button'>Edit</button>";
    //   },
    //   cellClick: (e, cell) => {
    //     // Get the row data when the "Edit" button is clicked
    //     const rowData = cell.getRow().getData();
    //     console.log("Edit button clicked, Row Data:", rowData);
    //     navigate("/editAllSolution", { state: { rowData } });
    //     // Add your edit logic here
    //   },
    //   headerSort: false, // Disable sorting on this column
    //   width: 80, // Adjust width as needed
    // },
    {
      title: "Edit",
      resizable: true,
      formatter: EditFormatter,
      headerSort: false, // Disable sorting on this column
      width: 80,
    },
    {
      title: "Delete",
      resizable: true,
      formatter: DeleteFormatter,
      headerSort: false,
      width: 80,
    },
  ];
  return (
    <div>
      <ReactTabulator
        data={data}
        columns={columns}
        options={{
          layout: "fitColumns",
          pagination: "local", // Enable pagination
          paginationSize: 15, // Number of rows per page
          movableColumns: true, // Allow columns to be reordered
          paginationCounter: "rows",
          tooltips: true,
          resizableColumnWidths: false, // Set to false to prevent resize errors
          resizableRowWidths: false,
          movableRows: true,
          resizableColumns: true,
          // resizableRows: true,
          resizableColumnFit: true,
        }}
      />
    </div>
  );
};
export default AllSolution;
