import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { EyeIcon } from "@heroicons/react/24/solid";

export default function SignUp() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordType, setPasswordType] = useState("password");
  function togglePassword(event) {
    setPasswordType(passwordType === "password" ? "text" : "password");
  }
  const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
  const API_ENDPOINT = "/api/shop/register";
  const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

  function registerNewUser() {
    const isTrainer = false;
    const options = {
      method: "POST",
      mode: "no-cors",
      url: API,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ name, email, password, isTrainer }),
    };
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
      alert("You have entered an invalid email address!");
    } else {
      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
          if (response.data.token) {
            navigate("/shoplogin");
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  }
  const logo = require("../assets/bg.png");
  return (
    <div className="flex mt-5 content-center items-center md:w-1/2 mx-auto " style={{marginTop:"190px"}}>
      <div className="row mx-auto place-items-center content-center py-10 pr-10 pb-10 bg-gray-50 shadow-xl rounded-2xl">
      <div className="col-md-6 ">
          <div className=" text-black-400 text-xl font-bold pt-5 px-1">
            Register your Account
          </div>
          <div className="flex flex-col text-black-400 text-lg py-0 pb-2 px-1">
            Welcome to RMM Support, Enter your details to begin!
          </div>
          {/* <div className="grid grid-cols-2 place-items-center">
            <button
              type="button"
              class="py-2 px-4 mr-4 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            >
              <svg
                width="20"
                height="20"
                fill="currentColor"
                class="mr-2"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"></path>
              </svg>
              Facebook
            </button>

            <button
              type="button"
              class="py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            >
              <svg
                width="20"
                height="20"
                fill="currentColor"
                class="mr-2"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M896 786h725q12 67 12 128 0 217-91 387.5t-259.5 266.5-386.5 96q-157 0-299-60.5t-245-163.5-163.5-245-60.5-299 60.5-299 163.5-245 245-163.5 299-60.5q300 0 515 201l-209 201q-123-119-306-119-129 0-238.5 65t-173.5 176.5-64 243.5 64 243.5 173.5 176.5 238.5 65q87 0 160-24t120-60 82-82 51.5-87 22.5-78h-436v-264z"></path>
              </svg>
              Google
            </button>
          </div> */}
          <div class="inline-flex items-center justify-center w-full">
            <hr class="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
          </div>

          <div>
            <div className="flex flex-col text-black-400 py-0 px-5 pb-1">
              <input
                onChange={(event) => setName(event.target.value)}
                className="mb-2 rounded-lg bg-gray-200 mt- p-2 focus:border-indigo-800"
                type="text"
                placeholder="Name"
              />
            </div>
            <div className="flex flex-col text-black-400 py-0 px-5 pb-1">
              <input
                onChange={(event) => setEmail(event.target.value)}
                className="mb-2 rounded-lg bg-gray-200 mt- p-2 focus:border-indigo-800  "
                type="email"
                placeholder="Email"
              />
            </div>
            <div
              className="flex flex-row text-black-400 py-0 px-5 pb-1"
              style={{ position: "relative" }}
            >
              <input
                onChange={(event) => setPassword(event.target.value)}
                className="mb-2 rounded-lg bg-gray-200 mt- p-2 focus:border-indigo-800 basis-full"
                type={passwordType}
                placeholder="Password"
              />
              <button
                className="h-10"
                onClick={togglePassword}
                style={{ position: "absolute", right: "60px" }}
              >
                <EyeIcon className="text-grey-100 ml-1 h-5" />
              </button>
            </div>
          </div>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to="/login">
              <span>Already have account? login here</span>
            </Link>
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "1.2rem",
            }}
          >
            <Link to="/shoplogin">
              <span>Already have account? login here</span>
            </Link>
          </div>
          <button
            onClick={registerNewUser}
            className="text-white w-full justify-center my-5 py-2  bg-indigo-500 shadow-lg shadow-indigo-800/50 hover:shadow-indigo-800/20 rounded-lg"
          >
            Register
          </button>
        </div>

        <div className="col-md-6  d-none d-md-block">

          <img
            className="w-full h-full object-cover"
            src={logo}
            alt="RMM Logo"
          />
        </div>
      </div>
    </div>
  );
}
