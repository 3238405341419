import React, { useState, useEffect } from "react";
import axios from "axios";

const SellorBuy = () => {
  const [formData, setFormData] = useState({
    brand: "",
    model: "",
    itemName: "",
    buyorSell: "",
    shopName: localStorage.getItem("shopName"),
    shopId: localStorage.getItem("Id"),
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleButtonClick = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      buyorSell: value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/sellorBuy";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.post(API, formData);
      console.log(response.data);
      alert("Data submitted successfully");
      setFormData({
        brand: "",
        model: "",
        itemName: "",
        buyorSell: "",
        shopName: localStorage.getItem("shopName"),
        shopId: localStorage.getItem("Id"),
      });
    } catch (error) {
      console.error("Error:", error);
      alert("Error:", error.message);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="fault-finder-div" style={{ paddingTop: "30px" }}>
        <div className="grid justify-items-center">
          <div className="flex flex-col space-y-4 mb-5">
            <div className="flex flex-row space-x-12 ">
              <label htmlFor="brand">Brand:</label>
              <input
                type="text"
                placeholder=" Enter Brand"
                name="brand"
                value={formData.brand}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-row space-x-11 ">
              <label htmlFor="model">Model:</label>
              <input
                type="text"
                placeholder=" Enter Model"
                name="model"
                value={formData.model}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-row space-x-3 ">
              <label htmlFor="itemName">Item Name:</label>
              <input
                type="text"
                placeholder=" Enter Item Name"
                name="itemName"
                value={formData.itemName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-6">
            <button
              type="button"
              className={`fault-button ${
                formData.buyorSell === "BUY" ? "active" : ""
              }`}
              style={{ backgroundColor: "#228B22" }}
              onClick={() => handleButtonClick("BUY")}
              name="buyorSell"
              value="BUY"
            >
              BUY
            </button>
            <button
              type="button"
              className={`fault-button ${
                formData.buyorSell === "SELL" ? "active" : ""
              }`}
              style={{ backgroundColor: "#D2042D" }}
              onClick={() => handleButtonClick("SELL")}
              value="SELL"
              name="buyorSell"
            >
              SELL
            </button>
          </div>

          <button
            type="submit"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-3"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};
export default SellorBuy;
