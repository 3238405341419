import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function SupportOrderItem({ orderDetails, isCredit }) {
  const navigate = useNavigate();

  return (
    <>
      <div
        key={orderDetails.orderId}
        className="grid grid-cols-1 md:grid-cols-3 border-t-2 border-b-2 border-gray-300 gap-10 p-5 border-y-5"
      >
        <div className="font-bold rtl lg:text-2xl">Name:</div>
        <div className="md:col-span-2 lg:text-2xl">{orderDetails.name}</div>
        <div className="font-bold rtl lg:text-2xl">Queries:</div>

        <div className="md:col-span-2 lg:text-2xl">
          {orderDetails.mobileBrand} - {orderDetails.userDeviceIssue}
        </div>
        <div className="font-bold rtl lg:text-2xl">Pincode:</div>
        <div className="md:col-span-2 lg:text-2xl">{orderDetails.zipcode}</div>
        <div className="text-gray-600">
          <div>Date: {orderDetails.createdAt.split("T")[0]}</div>

          <div>Time: {orderDetails.createdAt.split("T")[1].split(".")[0]}</div>
          {/* <div>Pincode: {orderDetails.zipcode}</div> */}
        </div>

        <div>
          {isCredit ? (
            <button
              className="bg-blue-800 text-white uppercase 
                            py-2 px-4 rounded-xs font-semibold 
                            cursor-pointer border-2 border-slate-300 
                            hover:bg-slate-700 hover:text-white 
                            transition duration-200 ease-in-out"
              onClick={() => {
                navigate(
                  `/addSolution/${orderDetails.orderId}/${orderDetails.userContactNumber}/${orderDetails.name}`
                );
              }}
            >
              Add Solution
            </button>
          ) : (
            <button
              className="bg-blue-800 text-white uppercase 
            py-2 px-4 rounded-xs font-semibold 
            cursor-pointer border-2 border-slate-300 
            hover:bg-slate-700 hover:text-white 
            transition duration-200 ease-in-out opacity-50 cursor-not-allowed"
            >
              Add Solution
            </button>
          )}
        </div>
      </div>
    </>
  );
}
