import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Account({ setOrderId, handleClick }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [deviceModel, setDeviceModel] = useState("");
  const [deviceIssue, setDeviceIssue] = useState("");
  const [deviceImage, setDeviceImage] = useState("");
  const [userAddress, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");

  const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
  const API_ENDPOINT = "/api/orders/create";
  const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    console.log(base64);
    console.log(base64.length);
    setDeviceImage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function createOrder() {
    if (
      name == "" ||
      number == "" ||
      deviceIssue == "" ||
      deviceModel == "" ||
      zipcode == ""
    ) {
      alert("Please fill all the required fields");
    }

    const loggedInUser = localStorage.getItem("authenticationToken");
    const options = {
      method: "POST",
      mode: "no-cors",
      url: API,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loggedInUser}`,
      },
      data: JSON.stringify({
        name: name,
        email: email,
        userContactNumber: number,
        userDeviceIssue: deviceIssue,
        mobileBrand: deviceModel,
        userAddress: userAddress,
        zipcode: zipcode,
      }),
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        setOrderId(response.data.orderId);
        handleClick("next");
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  return (
    <div className="grid grid-cols-2  grid-flow-col gap-3 ">
      <div className="col-start-1 mt-2">
        <div className="flex flex-col text-black-400 font-bold py-2 px-1">
          Please fill in your information
        </div>
        <div>
          <form>
            <div className="bg-gray-100 border border-1 border-gray-300 rounded-lg grid grid-cols-2 ">
              <div className="flex flex-col text-black-400 py-5 px-5 pb-1">
                <label>Name</label>
                <input
                  value={name}
                  required
                  onChange={(event) => setName(event.target.value)}
                  className="rounded-lg bg-white mt-2 p-2 border border-1 border-gray-300 focus:border-indigo-800  "
                  type="text"
                />
              </div>
              <div className="flex flex-col col-start-1 text-black-400 py-5 px-5">
                <label>Email Address</label>
                <input
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  className="rounded-lg bg-white mt-2 p-2 border border-1 border-gray-300 focus:border-indigo-800  "
                  type="text"
                />
              </div>
              <div className="flex flex-col text-black-400 py-5 px-5 ">
                <label>Phone Number</label>
                <input
                  onChange={(event) =>
                    setNumber(event.target.value.substring(0, 10))
                  }
                  className="rounded-lg bg-white mt-2 p-2 border border-1 border-gray-300 focus:border-indigo-800  "
                  type="text"
                  value={number}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Address */}
      <div className="col-start-2">
        <div className=" grid grid-col-end-2 text-black-400 font-bold py-4 px-1">
          {/* <br></br> */}
        </div>
        <div className="grid grid-cols-1">
          <form>
            <div className="bg-gray-100 border border-1 border-gray-300 rounded-lg grid grid-cols-2 ">
              <div className="flex flex-col col-span-2 text-black-400 py-5 px-5 pb-1">
                <label>Address</label>
                <textarea
                  onChange={(event) => setAddress(event.target.value)}
                  name=""
                  id=""
                  cols="30"
                  rows="2"
                  className="rounded-lg bg-white mt-2 p-3 border border-1 border-gray-300 focus:border-indigo-800  "
                ></textarea>
              </div>
              <div className="flex flex-col col-span-2 text-black-400 py-2 px-5 pb-5">
                <label>PinCode</label>
                <input
                  onChange={(event) =>
                    setZipcode(event.target.value.substring(0, 6))
                  }
                  type="text"
                  className="rounded-lg bg-white mt-2 p-2 border border-dashed border-1 border-gray-300 focus:border-indigo-800"
                  value={zipcode}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/**Mobile details */}
      <div className="col-start-1 ">
        <div className="flex flex-col col text-black-400 font-bold py-5 px-1">
          Device Details
        </div>
        <div className="grid grid-cols-1">
          <form>
            <div className="bg-gray-100 rounded-lg border border-1 border-gray-300 grid grid-cols-2 ">
              <div className="flex flex-col col-span-2 text-black-400 py-5 px-5 pb-1">
                <label>Device Model</label>
                <input
                  onChange={(event) => setDeviceModel(event.target.value)}
                  className="rounded-lg bg-white mt-2 p-2 border border-1 border-gray-300 focus:border-indigo-800  "
                  type="textbox"
                />
              </div>
              <div className="flex flex-col col-span-2 text-black-400 py-5 px-5">
                <label>Issue Explanation</label>
                <textarea
                  onChange={(event) => setDeviceIssue(event.target.value)}
                  name=""
                  id=""
                  cols="30"
                  rows="3"
                  className="rounded-lg bg-white mt-2 p-4 border border-1 border-gray-300 focus:border-indigo-800  "
                ></textarea>
                {/* <input className='rounded-lg bg-white mt-2 p-10 border border-1 border-gray-300 focus:border-indigo-800  ' type="text"/> */}
              </div>
            </div>
          </form>
        </div>
      </div>

      {/**upload */}
      <div className="col-start-2">
        <div className=" grid grid-col-end-2 text-black-400 font-bold py-5 px-5">
          <br></br>
        </div>
        <div className="grid grid-cols-1">
          <form>
            <div className="bg-gray-100 border border-1 border-gray-300 rounded-lg grid grid-cols-2 ">
              <div className="flex flex-col col-span-2 text-black-400 py-5 px-5 pb-1">
                <label>Upload Image</label>
                {/* <input className='rounded-lg bg-white mt-2 p-6 border border-dashed border-1 border-gray-300 focus:border-indigo-800  ' type="file" /> */}
                <input
                  id="originalFileName"
                  className="rounded-lg bg-white mt-2 p-6 border border-dashed border-1 border-gray-300 focus:border-indigo-800"
                  type="file"
                  accept="image/*"
                  label="Device Image"
                  name="originalFileName"
                  onChange={(e) => handleFileRead(e)}
                  size="small"
                  variant="standard"
                />
                <i className="fa-solid fa-upload"></i>
              </div>
              <div className="flex flex-col col-span-2 text-black-400 py-5 mb-5 px-5 pb-2">
                <label>
                  Upload Video{" "}
                  <i className="text-red-500 italic"> (Coming Soon!) </i>{" "}
                </label>
                <input
                  className="rounded-lg bg-white mt-2 p-6 border border-dashed border-1 border-gray-300 focus:border-indigo-800  "
                  type="file"
                  disabled="true"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="grid col-start-2 items-center py-2 px-2">
        <button
          onClick={createOrder}
          className="bg-blue-800 text-white uppercase py-2 px-2 rounded-xs font-semibold 
                    cursor-pointer border-2 border-slate-300 hover:bg-slate-700 hower:text-white transition 
                    duration-2oo ease-in-out"
        >
          Submit
        </button>
      </div>
    </div>
  );
}
