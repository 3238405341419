import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPassword() {
  const { id, token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [tokenValid, setTokenValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const previousPath = localStorage.getItem("prevPath");
  const isShopLogin = previousPath === "/shoplogin";
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        console.log(isShopLogin);
        const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const API_ENDPOINT = isShopLogin
          ? "/api/shop/checkTokenValidity"
          : "/api/users/checkTokenValidity";
        // const API_ENDPOINT = "/api/shop/checkTokenValidity";
        const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
        const response = await axios.post(API, {
          id,
          token,
        });
        // console.log(response);
        if (response.status === 200) {
          setTokenValid(true);
        } else {
          setErrorMessage(
            "The reset password link has expired. Please request a new link."
          );
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(
          "The reset password link has expired. Please request a new link."
        );
      }
    };

    checkTokenValidity();
  }, [id, token]);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      toast.error("Passwords do not match");
      return;
    }

    setIsLoading(true);

    try {
      const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const API_ENDPOINT = isShopLogin
        ? "/api/shop/resetPassword"
        : "/api/users/resetPassword";
      // const API_ENDPOINT = "/api/shop/resetPassword";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.post(API, {
        id,
        token,
        password,
      });
      if (response.status === 200) {
        setPassword("");
        setConfirmPassword("");
        toast.success(response.data.message);
      } else {
        setErrorMessage(
          "The reset password link has expired. Please request a new link."
        );
        toast.error(
          "The reset password link has expired. Please request a new link."
        );
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      // localStorage.removeItem("prevPath");
      setIsLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow p-4">
            <div className="card-body">
              <h3 className="card-title text-center mb-4 fw-bolder">
                Create new password
              </h3>
              {errorMessage && (
                <p className="text-danger text-center my-3  fw-bold font-monospace">
                  {errorMessage}
                </p>
              )}
              {tokenValid && (
                <form onSubmit={handleResetPassword}>
                  <div className="form-group mb-4">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="New Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mb-2">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="Confirm New Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="showPassword"
                      checked={showPassword}
                      onChange={() => setShowPassword(!showPassword)}
                    />
                    <label className="form-check-label" htmlFor="showPassword">
                      Show Password
                    </label>
                  </div>
                  <button
                    type="submit"
                    className=" text-white w-full justify-center my-4 py-2  bg-indigo-500 shadow-lg shadow-indigo-800/50 hover:shadow-indigo-800/20 rounded-lg fw-bold"
                    disabled={isLoading}
                  >
                    {isLoading ? "Resetting..." : "Reset Password"}
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ForgotPassword;
