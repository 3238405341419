import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const ShopFaultRemark = () => {
  const location = useLocation();
  if (location.state !== null) {
    var { rowData } = location.state;
  }
  const navigate = useNavigate();
  const initialFormData = {
    voltageDC: "",
    ampereDC: "",
    DM: "",
    DP: "",
    batteryGR: "",
    chargingGR: "",
    port: "",
    powerKeyPress: "",
    fault: "",
    brand: "",
    model: "",
    TechSuggestedSol: "",
    AISuggestedSol: "",
    solutionWorked: "",
    comment: "",
    specificFault: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    if (location.state && location.state.rowData) {
      const data = location.state.rowData;

      const parsedTechSuggestedSol = JSON.parse(data.TechSuggestedSol || "{}");
      const parsedAISuggestedSol = data.AISuggestedSol
        ? JSON.parse(data.AISuggestedSol)
        : "";

      setFormData({
        ...data,
        TechSuggestedSol: parsedTechSuggestedSol.solution1
          ? `${parsedTechSuggestedSol.solution1}, ${parsedTechSuggestedSol.solution2}`
          : "",
        AISuggestedSol: parsedAISuggestedSol,
      });
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    // console.log(formData);
    e.preventDefault();
    const { _id, ...formDataWithoutId } = rowData;

    try {
      formData.steps = "steps-2";
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/faultFinder";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

      const response = await axios.put(API, {
        id: _id,
        solutionWorked: formData.solutionWorked,
        comment: formData.comment,
        steps: formData.steps,
      });
      console.log(response.data);
      alert("Thank You For Your Remark.");
      navigate("/shopFaultData");
    } catch (error) {
      console.error("Error:", error);
    }
    setFormData({
      voltageDC: "",
      ampereDC: "",
      DM: "",
      DP: "",
      batteryGR: "",
      chargingGR: "",
      port: "",
      powerKeyPress: "",
      fault: "",
      brand: "",
      model: "",
      TechSuggestedSol: "",
      AISuggestedSol: "",
      solutionWorked: "",
      comment: "",
      specificFault: "",
    });
  };
  return (
    <>
      <form class="max-w-md mx-auto my-16 px-4 md:px-0" onSubmit={handleSubmit}>
        <h3 className="text-center text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-6">
          Fill all the details
        </h3>
        <div class="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.brand}
            name="brand"
            id="brand"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            for="brand"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Brand
          </label>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.model}
            name="model"
            id="model"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            for="model"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Model
          </label>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.fault}
            name="fault"
            id="fault"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            for="fault"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Fault
          </label>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.specificFault}
            name="specificFault"
            id="specificFault"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            for="specificFault"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            specific_Fault
          </label>
        </div>

        <div class="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.voltageDC}
            name="voltageDC"
            id="voltageDC"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            for="voltageDC"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Voltage DC
          </label>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.ampereDC}
            name="ampereDC"
            id="ampereDC"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            for="ampereDC"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Ampere DC
          </label>
        </div>
        <div class="grid md:grid-cols-2 md:gap-6">
          <div class="relative z-0 w-full mb-5 group">
            <input
              type="text"
              value={formData.DM}
              name="DM"
              id="DM"
              onChange={handleChange}
              class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              for="DM"
              class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              DM
            </label>
          </div>

          <div class="relative z-0 w-full mb-5 group">
            <input
              type="text"
              value={formData.DP}
              name="DP"
              id="DP"
              onChange={handleChange}
              class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
            />
            <label
              for="DP"
              class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              DP
            </label>
          </div>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.batteryGR}
            name="batteryGR"
            id="batteryGR"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            for="batteryGR"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Battery GR
          </label>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.chargingGR}
            name="chargingGR"
            id="chargingGR"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            for="chargingGR"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Charging GR
          </label>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <select
            type="text"
            value={formData.port}
            name="port"
            id="port"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          >
            <option value="">Select Port</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <label
            for="port"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Port
          </label>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <select
            type="text"
            value={formData.powerKeyPress}
            name="powerKeyPress"
            id="powerKeyPress"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          >
            <option value="">Select Power Key Press</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <label
            for="powerKeyPress"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Power Key Press
          </label>
        </div>

        <div class="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.AISuggestedSol}
            name="AISuggestedSol"
            id="AISuggestedSol"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            for="AISuggestedSol"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            AI_Suggested Solution
          </label>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.TechSuggestedSol}
            name="TechSuggestedSol"
            id="TechSuggestedSol"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            for="TechSuggestedSol"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Technician Suggested Solution
          </label>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <select
            type="text"
            value={formData.solutionWorked}
            name="solutionWorked"
            id="solutionWorked"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
          >
            <option value="">Solution Worked (Yes/No)</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          <label
            for="solutionWorked"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Solution Worked
          </label>
        </div>
        <div class="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.comment}
            name="comment"
            id="comment"
            onChange={handleChange}
            class="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
          />
          <label
            for="comment"
            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Add Your Comment
          </label>
        </div>
        <div className="text-center">
          <button
            type="submit"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};
export default ShopFaultRemark;
