import React from "react";
import back1 from "../assets/images/back1.png";
import back2 from "../assets/images/bck2.png";
import girlwphn from "../assets/images/girlwithphone.png";
import logo from "../assets/logo.png";
import dotted from "../assets/images/dotted.png";
import want1 from "../assets/images/want1.png";
import want2 from "../assets/images/want2.png";
import one from "../assets/images/01.png";
import two from "../assets/images/02.png";
import three from "../assets/images/03.png";
import svg3 from "../assets/images/happyCustomer.webp";
import svg2 from "../assets/images/NewSVG2.jpeg";
// import svg3 from "../assets/images/svg3.png";
import svg1 from "../assets/images/NEWSVG4.jpeg";
import arrow from "../assets/images/arrow.png";
import startupindia from "../assets/images/start-up.png";
import iitPatna from "../assets/images/iitPatna1.png";
import iimCalcutta from "../assets/images/immCalcutta.png";
import razorpay from "../assets/images/Razorpaylogo1.png";
import { Link } from "react-router-dom";
import "../App.css";
import {
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaYoutube,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
export default function LandingPage() {
  return (
    <div>
      {/* Landing Page
           <button type="button" class="btn btn-primary">Primary</button> */}
      <div
        className="container-fluid px-0"
        style={{ backgroundColor: "#ceddda5c" }}
      >
        <div className="section-0">
          <div className="col-lg-12 ">
            <div className="section-1">
              <div className="logo-img-div">
                <img src={logo} className="logo-img" alt="" />
              </div>
              <div
                style={{
                  height: "67%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div>
                  <h3 className="phone-text" style={{ marginTop: "30px" }}>
                    Welcome to <br /> RMM Support Portal
                  </h3>
                </div>

                <br />
                <Link to="/shoplogin">
                  <button
                    className="get-started"
                    style={{
                      borderRadius: "80px",
                      fontSize: "25px",
                      padding: "20px 40px",
                    }}
                  >
                    {/* Shop Login */}
                    GET STARTED
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="sectionn ">
          <div className="row">
            <div className=" section-3 ">
              <img src={want1} className="want1" alt="" />
              <div className="want2-outer">
                <img src={want2} className="want2" alt="" />
                <img src={dotted} className="dotted-img-1" alt="" />
              </div>
            </div>
            <div className=" section-4">
              <div>
                <h2 className="want-head">Want it repaired?</h2>
                <p className="want-p">
                  Welcome to Repair My Mobile, your go-to destination for
                  AI-powered mobile repair services. By leveraging advanced AI
                  technology, we ensure efficient diagnosis and precise repairs,
                  delivering quality results in record time.
                </p>
                <p className="want-p">

                  At RMM, we utilize cuttin-edge AI technology to diagnose and
                  identify issues with customer's mobile devices accurately.
                  This ensures that we can pinpoint the root cause of the
                  problem efficiently, leading to swift and effective repairs.
                </p>
              </div>
            </div>
          </div>
        </div> */}
<div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 count-box">
            <div className="count-img">
              <img src={one} className="count" alt="" />
              <img src={arrow} className="count" alt="" />
            </div>
            <div className="count-img">
              <img
                src={svg1}
                className="count"
                alt=""
                width="65%"
                height="100%"
              />
            </div>
            <div className="step-text-1">Diagnose Customer Devices</div>
          </div>
          <div className="col-lg-4 count-box">
            <div className="count-img">
              <img src={arrow} className="count" alt="" />
              <img src={two} className="count" alt="" />
              <img src={arrow} className="count" alt="" />
            </div>
            <div className="count-img">
              <img
                src={svg2}
                className="count"
                alt=""
                width="65%"
                height="100%"
              />
            </div>
            <div className="step-text-2">Access AI-Generated Solutions</div>
          </div>
          <div className="col-lg-4 count-box">
            <div className="count-img">
              <img src={arrow} className="count" alt="" />
              <img src={three} className="count" alt="" />
            </div>
            <div className="count-img">
              <img
                src={svg3}
                className="count"
                alt=""
                width="65%"
                height="100%"
              />
            </div>
            <div className="step-text-3">Provide Efficient Repairs</div>
          </div>
        </div>
        </div>
        <div className="container">
        <div style={{ fontFamily: "Poppins", marginTop: "2rem" }}>
          <h3 className="text-center fw-bolder ">Our Technology Partners</h3>
          <div class="grid md:grid-cols-3 sm:grid-cols-1 md:gap-4 mt-3 ">
            <a
              href="https://iciitp.com/"
              target="blank"
              class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 no-underline hover:underline tech"
              style={{ padding: "2rem" }}
            >
              <img
                class="object-contain w-full rounded-t-lg  md:h-auto md:w-48 md:rounded-none md:rounded-s-lg techImg"
                src={iitPatna}
                alt=""
              />
              <div class="flex flex-col justify-between p-4 leading-normal">
                <p class="mb-3 font-semibold text-gray-700 dark:text-gray-400 text-lg">
                  Technology Support
                </p>
                <h1 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                  IIT Patna Incubation
                </h1>
              </div>
            </a>
            <a
              href="https://www.iimcal.ac.in/faculty/centers-of-excellence/centre-for-entrepreneurship-innovation/iim-calcutta-innovation-park"
              target="blank"
              class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 no-underline hover:underline tech"
              style={{ padding: "2rem" }}
            >
              <img
                class="object-contain w-full rounded-t-lg  md:h-auto md:w-48 md:rounded-none md:rounded-s-lg techImg"
                src={iimCalcutta}
                alt=""
              />
              <div class="flex flex-col justify-between p-4 leading-normal">
                <p class="mb-3 font-semibold text-gray-700 dark:text-gray-400 text-lg">
                  Incubated by
                </p>
                <h1 class=" text-2xl font-bold tracking-tight text-gray-900 ">
                  IIM Calcutta Innovation Park
                </h1>
              </div>
            </a>
            <a
              href="https://www.startupindia.gov.in/"
              target="blank"
              class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 no-underline hover:underline tech"
              style={{ padding: "2rem" }}
            >
              <img
                class="object-contain w-full rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-s-lg techImg"
                src={startupindia}
                alt=""
              />
              <div class="flex flex-col justify-between p-4 leading-normal">
                <p class="mb-3 font-semibold text-gray-700 dark:text-gray-400 text-lg">
                  Register under
                </p>
                <h1 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                  # Startupindia
                </h1>
              </div>
            </a>
          </div>
        </div>
        </div>
        <div class="container-fluid mt-12" style={{ fontFamily: "Poppins" }}>
          <footer class="pt-5">
            <div class="row fw-bold mx-0">
              <div className="col-12 col-md-3 mb-4 mb-md-0 ">
                <h5 className="fw-bolder">RMM Other website & App</h5>
                <ul className="nav flex-column mt-3">
                  <li className="nav-item mb-2 footerLi ">
                    <a
                      href="https://training.repairmymobile.co/"
                      target="blank"
                      className="nav-link p-0 text-body-secondary"
                    >
                      training.repairmymobile.co
                    </a>
                  </li>
                  <li className="nav-item mb-2 footerLi">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.repairmymobile.courses"
                      target="blank"
                      className="nav-link p-0 text-body-secondary"
                    >
                      RMM Android App
                    </a>
                  </li>
                  <li className="nav-item mb-2 footerLi">
                    <a
                      href="https://t.me/repairmymobile"
                      target="blank"
                      className="nav-link p-0 text-body-secondary"
                    >
                      Join Telegram Group
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-3 mb-4 mb-md-0">
                <h5 className="fw-bolder">Contact</h5>
                <ul className="nav flex-column mt-3">
                  <li className="nav-item mb-2 footerLi">
                    <a
                      href="mailto:sidhartha@repairmymobile.co"
                      className="nav-link p-0 text-body-secondary"
                    >
                      sidhartha@repairmymobile.co
                    </a>
                  </li>
                  <li className="nav-item mb-2 footerLi">
                    <a
                      href="tel:+91-8935939022"
                      className="nav-link p-0 text-body-secondary"
                    >
                      +91-9060 2999 19
                    </a>
                  </li>
                  <li className="nav-item mb-2 footerLi">
                    <a className="nav-link p-0 text-body-secondary">
                      Mon-Sat:10:00am to 6.00pm
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-3 mb-4 mb-md-0">
                <h5 className="fw-bolder">Keep In Touch</h5>
                <ul class="list-unstyled d-flex mt-3">
                  <li class="ms-3">
                    <a
                      className="link-body-emphasis"
                      href="https://twitter.com/repairmy_mobile"
                      target="blank"
                    >
                      <FaTwitter
                        style={{ color: "#1DA1F2", fontSize: "30px" }}
                      />
                    </a>
                  </li>
                  <li class="ms-3">
                    <a
                      className="link-body-emphasis"
                      href="https://www.instagram.com/repairmymobile/"
                      target="blank"
                    >
                      <FaInstagram
                        style={{ color: "#E4405F", fontSize: "30px" }}
                      />
                    </a>
                  </li>
                  <li class="ms-3">
                    <a
                      className="link-body-emphasis"
                      href="https://www.facebook.com/RepairMyMobile.in"
                      target="blank"
                    >
                      <FaFacebook
                        style={{ color: "#3b5998", fontSize: "30px" }}
                      />
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className="link-body-emphasis"
                      href="https://www.youtube.com/channel/UCXJnWhiX_jgaC8x9RdoCmJQ"
                      target="blank"
                    >
                      <FaYoutube style={{ color: "red", fontSize: "30px" }} />
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className="link-body-emphasis"
                      href="https://www.linkedin.com/company/wejoy-phonofix/"
                      target="blank"
                    >
                      <FaLinkedin style={{ color: "blue", fontSize: "30px" }} />
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className="link-body-emphasis"
                      href="https://www.whatsapp.com/channel/0029Va4M1tDL7UVYit6GJc3d"
                      target="blank"
                    >
                      <FaWhatsapp
                        style={{ color: "#25D366", fontSize: "30px" }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-md-3 mb-4 mb-md-0">
                <h5 className="fw-bolder">Payment Partner</h5>
                <ul class="list-unstyled d-flex mt-3">
                  <li class="ms-3">
                    <img src={razorpay} alt="" loading="lazy" />
                  </li>
                </ul>
              </div>
            </div>

            <div class=" text-center py-4 mt-4 border-top fw-bold">
              <p> Copyright © 2023 RMM. Designed by RMM All rights reserved.</p>
            </div>
          </footer>
        </div>
      </div>
      {/* <div className="footer">
        Copyright © 2023 RMM. Designed by RMM All rights reserved.
      </div> */}
    </div>
  );
}
