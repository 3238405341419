import React, { useState } from "react";
import axios from "axios";
const AddSpecificFaultSolution = () => {
  const [formData, setFormData] = useState({
    specificFault: "",
    solution1: "",
    solution2: "",
    solution3: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log("value", value);
    // console.log("name", name);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/specificFaults";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.post(API, formData);
      console.log(response.data);
      alert("Data Submitted.");
    } catch (error) {
      console.error("Error:", error);
    }
    setFormData({
      specificFault: "",
      solution1: "",
      solution2: "",
      solution3: "",
    });
  };
  return (
    <>
      <form className="max-w-md mx-auto my-16" onSubmit={handleSubmit}>
        <h3 style={{ marginBottom: "25px", textAlign: "center" }}>
          Fill all the details
        </h3>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.specificFault}
            name="specificFault"
            id="specificFault"
            onChange={handleChange}
            className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            htmlFor="specificFault"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Specific Fault
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.solution1}
            name="solution1"
            id="solution1"
            onChange={handleChange}
            className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            htmlFor="solution1"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Solution 1
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.solution2}
            name="solution2"
            id="solution2"
            onChange={handleChange}
            className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            htmlFor="solution2"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Solution 2
          </label>
        </div>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            value={formData.solution3}
            name="solution3"
            id="solution3"
            onChange={handleChange}
            className="block py-2.5 px-0 w-full text-base text-gray-900 bg-transparent  border-b-2 border-gray-400 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
          />
          <label
            htmlFor="solution3"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Solution 3
          </label>
        </div>
        <div className="text-center">
          <button
            type="submit"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};
export default AddSpecificFaultSolution;
