import React from "react";
import { useState } from "react";

import Stepper from "./Stepper.jsx";
import StepperControl from "./StepperControl.jsx";
import { StepperContext } from "../contexts/StepperContext";

import Account from "./steps/Account.jsx";
import Solutions from "./steps/Solutions.jsx";
import Payment from "./steps/Payment.jsx";
import PaymentRazorpay from "./steps/PaymentRazorpay.jsx";

import CustomerSupport from "./steps/CustomerSupport.jsx";
import Shops from "./steps/Shops.jsx";

export default function NewOrder() {
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState("");
  const [orderId, setOrderId] = useState();
  const [finalData, setFinalData] = useState([]);

  const steps = [
    "Your Details",
    "Payment",
    "Customer Support",
    "Solution",
    "Shops",
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return (
          <Account
            orderId=""
            setOrderId={setOrderId}
            handleClick={handleClick}
          />
        );
      case 2:
        return <PaymentRazorpay orderId={orderId} handleClick={handleClick} />;
      case 3:
        return <CustomerSupport orderId={orderId} handleClick={handleClick} />;
      case 4:
        return <Solutions orderId={orderId} />;
      case 5:
        return <Shops />;
      default:
        return <div>An error occurred... Please try again later!!</div>;
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    //check boundary
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  const sendSolution = () => {
    alert("sendSolution");
    // const url =
    //   "https://live-server-100184.wati.io/api/v1/sendTemplateMessages";
    // const options = {
    //   method: "POST",
    //   headers: {
    //     "content-type": "text/json",
    //     Authorization:
    //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5MTA1YmE5Ny1mYTg3LTRjN2UtODJmZi1kOWNkMzM5MzkyZmMiLCJ1bmlxdWVfbmFtZSI6ImRldi5yZXBhaXJteW1vYmlsZUBnbWFpbC5jb20iLCJuYW1laWQiOiJkZXYucmVwYWlybXltb2JpbGVAZ21haWwuY29tIiwiZW1haWwiOiJkZXYucmVwYWlybXltb2JpbGVAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDgvMTUvMjAyMyAxMjowNToyOCIsImRiX25hbWUiOiIxMDAxODQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.sH0AG-rtpPUR-PJxZiG6wx_gHkRwdI4Q_ZuFSDRsSyE",
    //   },
    //   body: JSON.stringify({
    //     broadcast_name: "test",
    //     template_name: "test",
    //     receivers: [{ whatsappNumber: "8409213373" }],
    //     source: [{ whatsappNumber: "9123293062" }],
    //   }),
    // };
    // fetch(url, options)
    //   .then((res) => res.json())
    //   .then((json) => console.log(json))
    //   .catch((err) => console.error("error:" + err));
    const url =
      "https://live-server-100184.wati.io/api/v1/sendTemplateMessage?whatsappNumber=919123293062";
    const options = {
      method: "POST",
      headers: {
        "content-type": "text/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5MTA1YmE5Ny1mYTg3LTRjN2UtODJmZi1kOWNkMzM5MzkyZmMiLCJ1bmlxdWVfbmFtZSI6ImRldi5yZXBhaXJteW1vYmlsZUBnbWFpbC5jb20iLCJuYW1laWQiOiJkZXYucmVwYWlybXltb2JpbGVAZ21haWwuY29tIiwiZW1haWwiOiJkZXYucmVwYWlybXltb2JpbGVAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDgvMTUvMjAyMyAxMjowNToyOCIsImRiX25hbWUiOiIxMDAxODQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.sH0AG-rtpPUR-PJxZiG6wx_gHkRwdI4Q_ZuFSDRsSyE",
      },
      body: JSON.stringify({
        broadcast_name: "skillcamreview",
        template_name: "skillcamreview",
      }),
    };

    fetch(url, options)
      .then((res) => res.json())
      .then((json) => console.log(json))
      .catch((err) => console.error("error:" + err));
  };

  return (
    <div>
      <div className="shadow-xl  items-center rounded-2xl pb-2 bg-gray-50">
        {/*Stepper */}
        <div className="container mx-auto horizontal mt-5">
          <Stepper steps={steps} currentStep={currentStep} />
        </div>

        {/**Display components */}
        <div className="my-5 p-10">
          <StepperContext.Provider
            value={{
              userData,
              setUserData,
              finalData,
              setFinalData,
              orderId,
              setOrderId,
            }}
          >
            {displayStep(currentStep)}
          </StepperContext.Provider>
        </div>

        {/*Navigation controls */}

        <StepperControl
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
        />
      </div>
    </div>
  );
}
