import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "react-tabulator/lib/styles.css";
import { ReactTabulator } from "react-tabulator";

const SpecificFaultData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/specificFaults";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    axios
      .get(API)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const columns = [
    { title: "S_No.", formatter: "rownum", hozAlign: "left", width: 40 },
    {
      title: "Specific Fault",
      field: "specificFault",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
      // eventResizeFeature: true,
    },
    {
      title: "Solution 1",
      field: "solution1",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Solution 2",
      field: "solution2",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Solution 3",
      field: "solution3",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
  ];
  return (
    <>
      <div>
        <ReactTabulator
          data={data}
          columns={columns}
          options={{
            layout: "fitColumns",
            pagination: "local", // Enable pagination
            paginationSize: 15, // Number of rows per page
            movableColumns: true, // Allow columns to be reordered
            paginationCounter: "rows",
            tooltips: true,
            resizableColumnWidths: false, // Set to false to prevent resize errors
            resizableRowWidths: false,
            movableRows: true,
            resizableColumns: true,
            // resizableRows: true,
            resizableColumnFit: true,
          }}
        />
      </div>
    </>
  );
};
export default SpecificFaultData;
