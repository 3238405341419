import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useCallback } from "react";
import useRazorpay from "react-razorpay";

import Loader from "./Loader";

const Payment = () => {
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/FaultFinder");
  };
  const onSuccess = async (paymentData) => {
    console.log("Payment success:", paymentData);
    console.log(paymentData.razorpay_payment_id);
    // setPaymentId(paymentData.razorpay_payment_id);
    // setPaymentStatus(true); // Update payment status to true
    await updatePaymentDetails(paymentData.razorpay_payment_id); // Wait for updatePaymentDetails to complete
    setShowModal(false);
  };

  const onError = (error) => {
    console.error("Payment error:", error);
  };
  const updatePaymentDetails = async (razorpay_payment_id) => {
    try {
      const token = localStorage.getItem("authenticationToken");
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/shop/update-payment";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

      const response = await axios.put(
        API,
        { paymentId: razorpay_payment_id, paymentStatus: true }, // Updated state values
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowModal(false);
      console.log("Payment details updated:", response.data);
      localStorage.setItem("paymentStatus", response.data.paymentStatus);
      localStorage.setItem("solutionCount", response.data.solutionCount);
      navigate("/FaultFinder");
    } catch (error) {
      console.error("Error updating payment details:", error);
    }
  };
  const handlePayment = useCallback(() => {
    // const order = createOrder(params);

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: "19900",
      currency: "INR",
      name: "RepairMyMobile",
      description: "RMMServiceCenter",
      image: "https://i.ibb.co/BwxV3ws/logo.png",
      // order_id: order.id,

      prefill: {
        name: localStorage.getItem("Name"),
        email: localStorage.getItem("Email"),
      },
      notes: {
        address: "RMM Office Address",
      },
      theme: {
        color: "#3399cc",
      },
      handler: onSuccess,
      onError: onError,
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);
  // useEffect(() => {
  //   if (localStorage.getItem("paymentStatus") === "false") {
  //     setShowModal(true);
  //   }
  // }, []);
  useEffect(() => {
    const paymentStatus = localStorage.getItem("paymentStatus");
    if (paymentStatus === "true") {
      setShowSuccessModal(true);
    } else if (paymentStatus === "false") {
      setShowModal(true);
    }
  }, []);

  return (
    <>
      <Modal show={showModal} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "blue" }}>Upgrade Your Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#f0f0f0" }}>
          <p className="fw-bolder fs-5">
            You are currently on a free trial. You have 5 free trials. Upgrade
            now to get unlimited solutions!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-outline-success fw-semibold"
            onClick={handlePayment}
          >
            Upgrade Plan
          </Button>
          <Button
            variant="btn btn-outline-dark fw-semibold"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showSuccessModal}
        onHide={handleCloseSuccessModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "green" }}>
            Payment Successful
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#f0f0f0" }}>
          <p className="fw-bolder fs-5">
            Your payment has been successfully processed. You now have unlimited
            solutions for 30 days!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-outline-dark fw-semibold"
            onClick={handleCloseSuccessModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Payment;
