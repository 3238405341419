import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import Modal from "react-modal";
import { XCircleIcon } from "@heroicons/react/24/outline";
import logo from "../assets/logo.png";
import "../Invoice.css";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import defaultShopImage from "../assets/images/shopImage2.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InvoiceHistory = () => {
  const [formData, setFormData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImageURL, setModalImageURL] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [shopData, setShopData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const defaultShopImage = "../assets/images/shopImage2.jpg";
  useEffect(() => {
    const token = localStorage.getItem("authenticationToken");
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/invoices";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    axios
      .get(API, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setFormData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const GetShopDetails = async () => {
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = "/api/shop/getShopById";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      try {
        const response = await axios.get(API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "authenticationToken"
            )}`,
          },
        });
        setShopData(response.data);
        // console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
        alert("Failed to get shop details");
      }
    };
    GetShopDetails();
  }, []);

  const openInvoiceModal = (data) => {
    setSelectedInvoice(data);
    console.log(data);
    setModalOpen(true);
  };

  const closeInvoiceModal = () => {
    setModalOpen(false);
    setSelectedInvoice(null);
  };
  const imageFormatter = (cell) => {
    const imageURL = `${process.env.REACT_APP_API_BASE_URL}/${cell.getValue()}`;
    // const REACT_APP = "http://localhost:5002";
    // const imageURL = `${REACT_APP}/${cell.getValue()}`;
    console.log(imageURL);
    if (cell.getValue() === null) {
      return "---";
    } else {
      return `<img src="${imageURL}" alt="Image" style="max-width:50px;max-height:50px;" />`;
    }
  };
  const columns = [
    { title: "S_No.", formatter: "rownum", hozAlign: "left", width: 40 },
    {
      title: "Full Name",
      field: "fullName",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    // {
    //   title: "Address",
    //   field: "address",
    //   hozAlign: "left",
    //   resizable: true,
    //   headerFilter: "input",
    // },
    {
      title: "Phone Number",
      field: "phoneNumber",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Mobile Brand Model",
      field: "mobileBrandModel",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    // {
    //   title: "IMEI Number 1",
    //   field: "imeiNumber1",
    //   hozAlign: "left",
    //   resizable: true,
    //   headerFilter: "input",
    // },
    // {
    //   title: "IMEI Number 2",
    //   field: "imeiNumber2",
    //   hozAlign: "left",
    //   resizable: true,
    //   headerFilter: "input",
    // },
    // {
    //   title: "Fault Details",
    //   field: "faultDetails",
    //   hozAlign: "left",
    //   resizable: true,
    //   headerFilter: "input",
    // },
    // {
    //   title: "Cost Of Repairing",
    //   field: "costOfRepairing",
    //   hozAlign: "left",
    //   resizable: true,
    //   headerFilter: "input",
    // },
    // {
    //   title: "Email ID",
    //   field: "emailId",
    //   hozAlign: "left",
    //   resizable: true,
    //   headerFilter: "input",
    // },
    // {
    //   title: "Order ID",
    //   field: "orderId",
    //   hozAlign: "left",
    //   resizable: true,
    //   headerFilter: "input",
    // },
    {
      title: "Aadhaar Card",
      field: "aadhaarCard",
      // formatter: "image",
      formatter: imageFormatter,
      cellClick: openModal,
    },
    {
      title: "Consent Form",
      field: "consentForm",
      formatter: imageFormatter,
      cellClick: openModal,
    },
    {
      title: "Customer Photo",
      field: "customerPhoto",
      formatter: imageFormatter,
      cellClick: openModal,
    },
    {
      title: "View Invoice",
      formatter: () => '<button class="edit-button">View Invoice</button>',
      align: "center",

      cellClick: (e, cell) => openInvoiceModal(cell.getRow().getData()),
    },
  ];

  function openModal(e, cell) {
    // const REACT_APP = "http://localhost:5002";
    // const imageURL = cell.getRow().getData()[cell.getColumn().getField()];
    const imageURL = `${process.env.REACT_APP_API_BASE_URL}/${cell.getValue()}`;
    // const imageURL = `${REACT_APP}/${cell.getValue()}`;
    setModalImageURL(imageURL);
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
    setModalImageURL("");
  }

  const handleMailInvoice = async (data) => {
    setIsLoading(true);
    try {
      const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const API_ENDPOINT = "/api/invoices/sendinvoicemail";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.post(API, data);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(
          "The reset password link has expired. Please request a new link."
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadImage = async () => {
    try {
      const response = await fetch(modalImageURL);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", modalImageURL.split("/").pop());
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const downloadInvoice = () => {
    const invoice = document.getElementById("invoiceContent");
    html2canvas(invoice).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("invoice.pdf");
    });
  };

  return (
    <div>
      <ReactTabulator
        data={formData}
        columns={columns}
        options={{
          layout: "fitColumns",
          pagination: "local", // Enable pagination
          paginationSize: 15, // Number of rows per page
          movableColumns: true, // Allow columns to be reordered
          paginationCounter: "rows",
          tooltips: true,
          resizableColumnWidths: false, // Set to false to prevent resize errors
          resizableRowWidths: false,
          movableRows: true,
          resizableColumns: true,
          // resizableRows: true,
          resizableColumnFit: true,
        }}
      />
      {/* Modal to display image */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        style={{
          content: {
            width: "50%",
            height: "50%",
            margin: "auto",
          },
        }}
      >
        <button
          onClick={closeModal}
          style={{
            padding: "5px 10px",
            cursor: "pointer",
            position: "absolute",
            right: "0",
          }}
        >
          <XCircleIcon className=" w-8 mr-2" />
        </button>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={modalImageURL}
            alt="Image"
            style={{
              maxWidth: "100%",
              maxHeight: "300px",
            }}
            className="object-fill"
          />
        </div>
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            onClick={downloadImage}
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
            </svg>
            <span>Download</span>
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeInvoiceModal}
        contentLabel="Invoice Modal"
        style={{
          content: {
            width: "70%",
            height: "70%",
            margin: "auto",
          },
        }}
      >
        <button
          onClick={closeInvoiceModal}
          style={{
            padding: "5px 10px",
            cursor: "pointer",
            position: "absolute",
            right: "0",
          }}
        >
          <XCircleIcon className="w-8 mr-2" />
        </button>
        {selectedInvoice && (
          <div id="invoiceContent">
            <div class="container bootstrap snippets bootdeys">
              <div class="row">
                <div class="col-12">
                  <div class="panel panel-default invoice" id="invoice">
                    <div class="panel-body">
                      {/* <div class="invoice-ribbon">
                        <div class="ribbon-inner">PAID</div>
                      </div> */}
                      <div class="row">
                        <div class="col-6 top-left">
                          <img src={logo} width="250px" alt="RMM_LOGO" />
                        </div>

                        <div class="col-6 top-right mt-5">
                          <h3 class="marginright">
                            INVOICE-{selectedInvoice?.orderId}
                          </h3>
                          <span class="marginright">
                            Date:{" "}
                            {moment(
                              selectedInvoice?.createdAt,
                              "YYYY-MM-DD"
                            ).format("DD MMM YYYY")}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-xs-6 col-sm-6 col-md-6">
                          <div class="receipt-left">
                            <img
                              className="img-responsive"
                              alt="Customer Photo"
                              src={
                                `${process.env.REACT_APP_API_BASE_URL}/${selectedInvoice?.customerPhoto}` ||
                                defaultShopImage
                              }
                              onError={(e) => {
                                e.target.onerror = null; // Prevents an infinite loop in case the default image also fails to load
                                e.target.src = defaultShopImage;
                              }}
                              style={{ width: "60%", borderRadius: "45px" }}
                            />
                          </div>
                        </div>
                        <div class="col-6 text-right">
                          <p class="lead marginbottom fw-bold">
                            Customer Details :
                          </p>
                          <p>{selectedInvoice?.fullName}</p>
                          <p>{selectedInvoice?.address}</p>
                          <p>
                            <strong>Phone:</strong>{" "}
                            {selectedInvoice?.phoneNumber}
                          </p>
                          <p>
                            <strong>Email:</strong> {selectedInvoice?.emailId}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-6 from">
                          <p class="lead marginbottom fw-bold">Repaired By :</p>
                          <p>{shopData?.shopName}</p>
                          <p>
                            {shopData?.shopAddress},{" "}
                            {shopData?.shopAddressZipcode}
                          </p>
                          {/* <p>{shopData?.shopAddressZipcode}</p> */}
                          <p>
                            <strong>Phone:</strong> {shopData?.contactNumber}
                          </p>
                          <p>
                            <strong>Email:</strong> {shopData?.email}
                          </p>
                        </div>

                        <div class="col-6 text-right payment-details">
                          <p class="lead marginbottom payment-info  fw-bold">
                            Device Details :
                          </p>
                          <p>
                            <strong>Order ID:</strong>{" "}
                            {selectedInvoice?.orderId}
                          </p>
                          <p>
                            <strong>IMEI Number 1:</strong>{" "}
                            {selectedInvoice?.imeiNumber1}
                          </p>
                          <p>
                            <strong>IMEI Number 2:</strong>{" "}
                            {selectedInvoice?.imeiNumber2}
                          </p>
                          <p>
                            <strong>Mobile Details:</strong>{" "}
                            {selectedInvoice?.mobileBrandModel}
                          </p>
                        </div>
                      </div>

                      <div class="row table-row">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th class="text-center" style={{ width: "5%" }}>
                                #
                              </th>
                              <th style={{ width: "30%" }}>Order Id</th>
                              <th class="text-left" style={{ width: "20%" }}>
                                Mobile Details
                              </th>
                              <th class="text-center" style={{ width: "30%" }}>
                                Fault Details
                              </th>

                              <th class="text-right" style={{ width: "15%" }}>
                                Cost of Repair
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="text-center">1</td>
                              <td> {selectedInvoice?.orderId}</td>
                              <td class="text-left">
                                {selectedInvoice?.mobileBrandModel}
                              </td>
                              <td class="text-center">
                                {selectedInvoice?.faultDetails}
                              </td>
                              <td class="text-right">
                                {selectedInvoice?.costOfRepairing}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="row">
                        <div class="col-6 mt-3">
                          <p
                            class="lead marginbottom text-mute fw-semibold"
                            style={{ color: "rgb(140, 140, 140)" }}
                          >
                            THANK YOU!
                          </p>
                        </div>
                        <div class="col-6 text-right pull-right invoice-total mt-3">
                          <p className="fw-semibold">
                            {" "}
                            <i class="fa fa-inr"></i>
                            {selectedInvoice?.costOfRepairing}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <button
          class="btn btn-white border border-dark"
          id="invoice-print"
          onClick={downloadInvoice}
        >
          <i class="fa fa-file t-plus-1 text-danger fa-fw fa-lg"></i> Export as
          PDF
        </button>
        <button
          class="btn btn-info mx-2"
          onClick={() => handleMailInvoice(selectedInvoice)}
        >
          <i class="fa fa-envelope-o"></i> Mail Invoice
        </button>
      </Modal>
    </div>
  );
};

export default InvoiceHistory;

// import React, { useState } from 'react';

// const InvoiceHistory = () => {
//   const [modalImage, setModalImage] = useState(null);

//   const openModal = (image) => {
//     setModalImage(image);
//   };

//   const closeModal = () => {
//     setModalImage(null);
//   };
// sampleData
// const sampleData = [
//   {
//     _id: "65cc674d75311060666f8ba9",
//     fullName: "John Doe",
//     address: "123 Main St",
//     phoneNumber: "555-1234",
//     mobileBrandModel: "Brand X Model Y",
//     imeiNumber1: "123456789012345",
//     imeiNumber2: "543210987654321",
//     faultDetails: "Screen cracked",
//     costOfRepairing: 100,
//     aadhaarCard: null,
//     consentForm: null,
//     customerPhoto: null,
//     __v: 0,
//   },
//   {
//     _id: "65cc684075311060666f8bac",
//     fullName: "John Doe1",
//     address: "123 Main St1",
//     phoneNumber: "555-1234",
//     mobileBrandModel: "Brand X Model Y",
//     imeiNumber1: "123456789012345",
//     imeiNumber2: "543210987654321",
//     faultDetails: "Screen cracked",
//     costOfRepairing: 100,
//     aadhaarCard: null,
//     consentForm: null,
//     customerPhoto: null,
//     __v: 0,
//   },
//   {
//     _id: "65cc695475311060666f8bb1",
//     fullName: "Ravi Prakash",
//     address: "50 h",
//     phoneNumber: "+919123293062",
//     mobileBrandModel: "dsf",
//     imeiNumber1: "sdf",
//     imeiNumber2: "sf",
//     faultDetails: "sf",
//     costOfRepairing: 85,
//     aadhaarCard: null,
//     consentForm: null,
//     customerPhoto: null,
//     __v: 0,
//   },
//   {
//     _id: "65cc698775311060666f8bb4",
//     fullName: "dsf",
//     address: "adsf",
//     phoneNumber: "asf",
//     mobileBrandModel: "dfs",
//     imeiNumber1: "saef",
//     imeiNumber2: "fs",
//     faultDetails: "sdef",
//     costOfRepairing: 34,
//     aadhaarCard: "C:\\fakepath\\LYR-CHR-38421.jpeg",
//     consentForm: null,
//     customerPhoto: null,
//     __v: 0,
//   },
// ];
//   // Hardcoded form data
//   const formData = {
//     fullName: 'John Doe',
//     address: '123 Main St, City, Country',
//     phoneNumber: '123-456-7890',
//     aadhaarCard: 'path_to_aadhaar_card_image.jpg',
//     consentForm: 'path_to_consent_form_image.jpg',
//     customerPhoto: 'path_to_customer_photo_image.jpg',
//   };

//   return (
//     <>
//       <table>
//         <thead>
//           <tr>
//             <th>Field</th>
//             <th>Value</th>
//             <th>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td>Full Name</td>
//             <td>{formData.fullName}</td>
//             <td></td>
//           </tr>
//           <tr>
//             <td>Address</td>
//             <td>{formData.address}</td>
//             <td></td>
//           </tr>
//           <tr>
//             <td>Phone Number</td>
//             <td>{formData.phoneNumber}</td>
//             <td></td>
//           </tr>
//           <tr>
//             <td>Aadhaar Card</td>
//             <td>{formData.aadhaarCard ? <button onClick={() => openModal(formData.aadhaarCard)}>View</button> : 'No image'}</td>
//             <td></td>
//           </tr>
//           <tr>
//             <td>Consent Form</td>
//             <td>{formData.consentForm ? <button onClick={() => openModal(formData.consentForm)}>View</button> : 'No image'}</td>
//             <td></td>
//           </tr>
//           <tr>
//             <td>Customer Photo</td>
//             <td>{formData.customerPhoto ? <button onClick={() => openModal(formData.customerPhoto)}>View</button> : 'No image'}</td>
//             <td></td>
//           </tr>
//         </tbody>
//       </table>

//       {/* Modal */}
//       {modalImage && (
//         <div className="modal">
//           <div className="modal-content">
//             <span className="close" onClick={closeModal}>&times;</span>
//             <img src={modalImage} alt="Modal" style={{ maxWidth: '100%', maxHeight: '100%' }} />
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default InvoiceHistory;
// import React from 'react';
// import 'react-tabulator/lib/styles.css';
// import { ReactTabulator } from 'react-tabulator';

// const InvoiceHistory = () => {
//   // Define your column definitions
//   const columns = [
//     { title: "Name", field: "name", width: 150 },
//     { title: "Age", field: "age", hozAlign: "left", formatter: "progress" },
//     { title: "Favourite Color", field: "col" },
//     { title: "Date Of Birth", field: "dob", hozAlign: "center" },
//     { title: "Rating", field: "rating", hozAlign: "center", formatter: "star" },
//     { title: "Passed?", field: "passed", hozAlign: "center", formatter: "tickCross" }
//   ];

//   // Define your data
//   const data = [
//     { id: 1, name: "Oli Bob", age: "12", col: "red", dob: "" },
//     { id: 2, name: "Mary May", age: "1", col: "blue", dob: "14/05/1982" },
//     { id: 3, name: "Christine Lobowski", age: "42", col: "green", dob: "22/05/1982" },
//     { id: 4, name: "Brendon Philips", age: "125", col: "orange", dob: "01/08/1980" },
//     { id: 5, name: "Margret Marmajuke", age: "16", col: "yellow", dob: "31/01/1999" },
//   ];

//   return (
//     <ReactTabulator
//       data={data}
//       columns={columns}
//       layout={"fitData"}
//     />
//   );
// };

// export default InvoiceHistory;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { React15Tabulator } from 'react-tabulator';
// import 'react-tabulator/lib/styles.css'; // default theme
// import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css'; // use Theme(s)

// const InvoiceHistory = () => {
//     const [formData, setFormData] = useState({
//         fullName: '',
//         address: '',
//         phoneNumber: '',
//         mobileBrandModel: '',
//         imeiNumber1: '',
//         imeiNumber2: '',
//         faultDetails: '',
//         aadhaarCard: null,
//         consentForm: null,
//         customerPhoto: null
//     });
//     const [tableData, setTableData] = useState([]);

//     const handleChange = (e) => {
//         const { name, value, files } = e.target;
//         setFormData(prevState => ({
//             ...prevState,
//             [name]: files ? files[0] : value
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Create FormData object
//         const formDataToSend = new FormData();
//         for (let key in formData) {
//             formDataToSend.append(key, formData[key]);
//         }

//         try {
//             // Send form data to API endpoint
//             const response = await axios.post('your_api_endpoint', formDataToSend);
//             console.log(response.data);
//             // Optionally, reset the form after successful submission
//             setFormData({
//                 fullName: '',
//                 address: '',
//                 phoneNumber: '',
//                 mobileBrandModel: '',
//                 imeiNumber1: '',
//                 imeiNumber2: '',
//                 faultDetails: '',
//                 aadhaarCard: null,
//                 consentForm: null,
//                 customerPhoto: null
//             });
//             // Reload table data
//             fetchData();
//         } catch (error) {
//             console.error('Error submitting form:', error);
//         }
//     };

//     const fetchData = async () => {
//         try {
//             // Fetch sample data from API endpoint
//             const response = await axios.get('sample_data_endpoint');
//             setTableData(response.data);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     useEffect(() => {
//         // Fetch initial data when component mounts
//         fetchData();
//     }, []);

//     useEffect(() => {
//         // Initialize table data with sample data
//         const sampleData = [
//             { id: 1, fullName: 'John Doe', address: '123 Main St', phoneNumber: '123-456-7890', mobileBrandModel: 'iPhone X', imeiNumber1: '123456789012345', imeiNumber2: '987654321098765', faultDetails: 'Screen cracked' },
//             { id: 2, fullName: 'Jane Smith', address: '456 Elm St', phoneNumber: '234-567-8901', mobileBrandModel: 'Samsung Galaxy S10', imeiNumber1: '543216789012345', imeiNumber2: '456789012345678', faultDetails: 'Battery draining quickly' },
//             // Add more sample data objects as needed
//         ];
//         setTableData(sampleData);
//     }, []);

//     const columns = [
//         { title: 'Full Name', field: 'fullName' },
//         { title: 'Address', field: 'address' },
//         { title: 'Phone Number', field: 'phoneNumber' },
//         { title: 'Mobile Brand Model', field: 'mobileBrandModel' },
//         { title: 'IMEI Number 1', field: 'imeiNumber1' },
//         { title: 'IMEI Number 2', field: 'imeiNumber2' },
//         { title: 'Fault Details', field: 'faultDetails' }
//         // Add more columns for Aadhaar Card, Consent Form, Customer Photo if needed
//     ];

//     return (
//         <div>
//             <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
//                 {/* Form fields */}
//                 {/* Submit button */}
//             </form>
//             <React15Tabulator
//                 data={tableData}
//                 columns={columns}
//                 layout={'fitColumns'}
//                 options={{
//                     pagination: 'local', // Enable pagination
//                     paginationSize: 10, // Number of rows per page
//                     paginationSizeSelector: [5, 10, 20, 50], // Dropdown options for rows per page
//                     movableColumns: true // Allow columns to be reordered
//                 }}
//             />
//         </div>
//     );
// };

// export default InvoiceHistory;
