import React from "react";
import { useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import axios from "axios";

export default function AdminAddProfile({ roleId }) {
  // Personal Details
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [accountType, setAccountType] = useState("");

  // Support Details
  const [meetLink, setMeetLink] = useState("");

  // Shop Details
  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [shopAddressZipcode, setShopAddressZipcode] = useState("");
  const [shopImage, setShopImage] = useState("");
  const [shopBannerImage, setshopBannerImage] = useState("");
  const [shopGoogleMapLink, setshopGoogleMapLink] = useState("");

  // const handleFileRead = async (event) => {
  //   const file = event.target.files[0];
  //   const base64 = await convertBase64(file);
  //   console.log(base64);
  //   console.log(base64.length);
  //   setShopImage(base64);
  // };
  const handleFileRead = async (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    setShopImage(file);
  };
  const handleFileRead1 = async (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    setshopBannerImage(file);
  };
  // const convertBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result);
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  function createUser(event) {
    event.preventDefault();
    // const password = Math.random().toString(36).substring(2, 8)
    // const password = shopName + "1234";

    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT =
      accountType === "support"
        ? "/api/users/registerSupport"
        : "/api/shop/register";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    const data = {
      name,
      email,
      contactNumber,
      password,
      meetLink,
      shopDetails: {
        shopName,
        shopAddress,
        shopAddressZipcode,
        shopImage,
        shopBannerImage,
        shopGoogleMapLink,
      },
    };
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("contactNumber", contactNumber);
    formData.append("meetLink", meetLink);
    formData.append("shopName", shopName);
    formData.append("shopAddress", shopAddress);
    formData.append("shopAddressZipcode", shopAddressZipcode);
    formData.append("shopGoogleMapLink", shopGoogleMapLink);
    formData.append("shopImage", shopImage);
    formData.append("shopBannerImage", shopBannerImage);
    formData.append("isTrainer", true);

    const loggedInUser = localStorage.getItem("authenticationToken");
    const options = {
      method: "POST",
      mode: "no-cors",
      url: API,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("authenticationToken")}`,
      },
      data: formData,
    };

    axios
      .request(options)
      .then(async function (response) {
        console.log(response.data);
        if (response.data.token) {
          await navigator.clipboard.writeText(password);
          alert("Account created successfully!! Password copied to clipboard.");
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  // if (roleId !== 9)
  //     return <Navigate replace to="/" />;

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="mt-16 text-3xl font-bold mb-10 flex flex-col justify-center items-center">
        {" "}
        Admin Account Creation Portal{" "}
      </div>
      <form onSubmit={createUser}>
        <div id="register-main" className="grid grid-cols-2 gap-4">
          <fieldset
            className={`mt-1 mr-1.5 pl-2 pb-2 pr-2 border border-solid border-slate-950 flex flex-col ${
              accountType === "shop" ? "col-span-1" : "col-span-2"
            }`}
          >
            <legend className="m-2 text-2xl"> Personal Details </legend>
            <input
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder="Name"
              className="flex justify-end mb-4 w-[100%] h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none"
            />
            <input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Email Id"
              className="flex justify-end mb-4 w-[100%] h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none"
            />
            <input
              //   type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Password"
              className="flex justify-end mb-4 w-[100%] h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none"
            />
            <div className="mb-2">
              <label htmlFor="contactNumber">+91</label>
              <input
                value={contactNumber}
                onChange={(event) =>
                  setContactNumber(event.target.value.substring(0, 10))
                }
                type="text"
                placeholder="Phone Number"
                className="inline-flex justify-end mb-4 w-96 h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none"
              />
            </div>
            <div className="mb-2">
              <label htmlFor="">Account Type: </label>
              <select
                value={accountType}
                onChange={(event) => setAccountType(event.target.value)}
                className="inline-flex justify-end mb-px w-80 h-10 p-2 border-b border-solid border-slate-950"
              >
                <option value="" className="text-base">
                  {" "}
                  Account Type{" "}
                </option>
                <option value="support" className="text-base">
                  {" "}
                  Support{" "}
                </option>
                <option value="shop" className="text-base">
                  {" "}
                  Shop{" "}
                </option>
              </select>
            </div>
            <input
              type="text"
              value={meetLink}
              onChange={(event) => setMeetLink(event.target.value)}
              placeholder="Google Meet Link"
              className={`flex justify-end mb-4 w-[100%] h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none ${
                accountType === "support" ? "" : "hidden"
              }`}
            />
          </fieldset>
          <fieldset
            className={`mt-1 mr-1.5 pl-2 pb-2 pr-2 border border-solid border-slate-950 ${
              accountType === "shop" ? "" : "hidden"
            }`}
          >
            <legend className="mb-2 text-2xl"> Shop Details </legend>
            <input
              value={shopName}
              onChange={(event) => setShopName(event.target.value)}
              className="flex justify-end mb-4 h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none w-[100%]"
              placeholder="Shop Name"
            />
            <input
              value={shopAddress}
              onChange={(event) => setShopAddress(event.target.value)}
              className="flex justify-end mb-4 h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none w-[100%]"
              placeholder="Shop Address"
            />
            <input
              value={shopAddressZipcode}
              onChange={(event) => setShopAddressZipcode(event.target.value)}
              className="flex justify-end mb-4 h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none w-[100%]"
              placeholder="ZipCode"
            />

            <textarea
              value={shopGoogleMapLink}
              onChange={(event) => setshopGoogleMapLink(event.target.value)}
              className="flex justify-end mb-4 h-10 pl-2 border-b border-solid border-slate-950 text-base focus:outline-none w-[100%]"
              placeholder="Google_Map Link"
              rows="3"
            ></textarea>

            <div className="flex flex-row justify-between w-[100%] mt-10">
              <label htmlFor="">Shop Image: </label>
              {/* <input type="file" value={shopImage} onChange={event => setShopImage(event.target.value)} /> */}
              <input
                id="originalFileName"
                type="file"
                accept="image/*"
                label="Shop Image"
                name="originalFileName"
                onChange={(e) => handleFileRead(e)}
                variant="standard"
              />
            </div>
            <div className="flex flex-row justify-between w-[100%] mt-10">
              <label htmlFor="">Shop Banner Image: </label>
              {/* <input type="file" value={shopImage} onChange={event => setShopImage(event.target.value)} /> */}
              <input
                type="file"
                accept="image/*"
                label="Shop Banner Image"
                onChange={(e) => handleFileRead1(e)}
                variant="standard"
              />
            </div>
          </fieldset>
        </div>
        <div className="flex flex-col justify-center items-center">
          <button className="text-white font-sans border border-solid border-blue-700 text-center w-96 h-10 bg-blue-800 text-base mt-3.5 mb-6">
            {" "}
            Create Account{" "}
          </button>
          <br />
        </div>
      </form>
    </div>
  );
}
