import React from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

export default function WindowLayoutOutlet({ roleId, name, meetLink }) {
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticated");
    console.log(loggedInUser);
    if (loggedInUser) {
      setAuthenticated(loggedInUser);
      console.log(`auth: ${authenticated}`);
    }
  }, [authenticated, setAuthenticated]);

  // if (!authenticated) {
  if (!localStorage.getItem("authenticated")) {
    return <Navigate replace to="/login" />;
  } else {
    return (
      <div className="flex flex-col h-full w-full">
        <Header name={name} />
        <div className="flex h-auto">
          {/* <SideBar roleId={roleId} meetLink={meetLink} /> */}
          <SideBar roleId={roleId} meetLink={meetLink} />
          {/* <SideBar /> */}
          <div
            className=""
            style={{ flex: "6.5",
            //  maxHeight: "85vh",
             overflow: "auto" }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    );
  }
}
