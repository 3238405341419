import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";
import { PhoneArrowDownLeftIcon } from "@heroicons/react/24/solid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";

export default function ShopCredit() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/shop/credit";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    axios
      .get(API, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "authenticationToken"
          )}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setShowCreditModal(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        toast.error("Failed to get credit balance");
      });
  }, []);

  const handleCloseCreditModal = () => {
    setShowCreditModal(false);
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal
        show={showCreditModal}
        onHide={handleCloseCreditModal}
        centered
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Your Credit Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Current Credit: {data ? data.credit : 0}</h5>
          <p className="mt-4 fw-medium text-primary-emphasis fs-4">
            Need more credits? Please contact customer support to discuss
            additional credit packages or any billing inquiries.
          </p>
          <div className="mt-4">
            <Button
              variant="outline-primary "
              className="d-flex align-items-center"
            >
              <PhoneArrowDownLeftIcon className="h-8 w-8 mr-2" />
              <span className="fw-bold">+91-9060 2999 19</span>
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreditModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}
