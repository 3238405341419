import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "react-tabulator/lib/styles.css";
import { ReactTabulator } from "react-tabulator";
const FaultFinderData = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/faultFinder";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
    axios
      .get(API)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const solutionWorkedFormatter = (cell) => {
    const rowData = cell.getRow().getData();

    const buttonNode = document.createElement("button");
    buttonNode.textContent = `${rowData.solutionWorked} ➕`;
    buttonNode.addEventListener("click", () => {
      navigate("/addFaultSolution", { state: { rowData } });
    });

    return buttonNode;
  };
  const DeleteFormatter = (cell) => {
    const rowData = cell.getRow().getData();

    const buttonNode = document.createElement("button");
    buttonNode.textContent = "Delete";
    buttonNode.classList.add("delete-button");
    buttonNode.addEventListener("click", () => {
      // console.log(rowData._id);
      if (window.confirm("Are You Sure To Delete This Fault ?")) {
        deleteData(rowData._id);
      }
    });

    return buttonNode;
  };
  const deleteData = async (_id) => {
    // console.log(_id);
    // const updatedItems = items.filter(item => item.id !== deletedItemId);
    // setItems(updatedItems);
    try {
      const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
      const API_ENDPOINT = `/api/faultFinder/${_id}`;
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      await axios.delete(API);
      console.log("Data deleted successfully.");
      alert("Data deleted successfully.");
      navigate(0);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const columns = [
    { title: "S_No.", formatter: "rownum", hozAlign: "left", width: 40 },
    {
      title: "Voltage DC",
      field: "voltageDC",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
      // eventResizeFeature: true,
    },
    {
      title: "Ampere DC",
      field: "ampereDC",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "DM",
      field: "DM",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "DP",
      field: "DP",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Battery GR",
      field: "batteryGR",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Charging GR",
      field: "chargingGR",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Port",
      field: "port",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Power Key Press",
      field: "powerKeyPress",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Brand",
      field: "brand",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Model",
      field: "model",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Fault",
      field: "fault",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Specific Fault",
      field: "specificFault",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Solution Worked",
      field: "solutionWorked",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
      formatter: solutionWorkedFormatter,
      // formatter: (cell) => {
      //   const rowData = cell.getRow().getData();

      //   // Pass the entire rowData object to the next route
      //   return (
      //     // <Link to={{ pathname: "/addFaultSolution", state: { rowData } }}>
      //     <div>
      //       <button
      //         onClick={() => {
      //           navigate("/addFaultSolution", { state: { rowData } });
      //         }}
      //       >
      //         View Details
      //       </button>
      //     </div>
      //     // </Link>
      //   );
      // },
    },
    {
      title: "Comments",
      field: "comment",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
      // width: 150,
    },
    {
      title: "Shop Name",
      field: "shopName",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
      // width: 150,
    },
    {
      title: "Shop Email",
      field: "shopEmail",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
      // width: 150,
    },
    {
      title: "Delete",
      resizable: true,
      formatter: DeleteFormatter,
      headerSort: false, // Disable sorting on this column
      width: 80,
    },
  ];
  // function debounce(func, timeout = 3000) {
  //   let timer;
  //   return (...args) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       func.apply(this, args);
  //     }, timeout);
  //   };
  // }
  // const handleResize = debounce(() => {}, 3000);

  // window.addEventListener("resize", function () {
  //   table.redraw(true);
  // });

  return (
    <div>
      <ReactTabulator
        data={data}
        columns={columns}
        options={{
          layout: "fitColumns",
          pagination: "local", // Enable pagination
          paginationSize: 15, // Number of rows per page
          movableColumns: true, // Allow columns to be reordered
          paginationCounter: "rows",
          tooltips: true,
          resizableColumnWidths: false, // Set to false to prevent resize errors
          resizableRowWidths: false,
          movableRows: true,
          resizableColumns: true,
          // resizableRows: true,
          resizableColumnFit: true,
        }}
      />
    </div>
  );
};
export default FaultFinderData;
