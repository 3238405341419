import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "react-tabulator/lib/styles.css";
import { ReactTabulator } from "react-tabulator";
import { Navigate } from "react-router-dom";
const OrderData = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const loggedInUser = localStorage.getItem("authenticationToken");

    const REACT_APP_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;
    const API_ENDPOINT = "/api/orders";
    const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;

    if (loggedInUser) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: API,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      };
      axios
        .request(config)
        .then(function (response) {
          // console.log(response.data);
          setData(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      return <Navigate replace to="/login" />;
    }
  }, []);
  const OrderFormatter = (cell) => {
    const rowData = cell.getRow().getData();

    const buttonNode = document.createElement("button");
    buttonNode.textContent = "View Quotation";
    buttonNode.classList.add("edit-button");
    buttonNode.addEventListener("click", () => {
      console.log(rowData.orderId);
      navigate("/viewQuotation", { state: { rowData } });
    });

    return buttonNode;
  };

  const columns = [
    { title: "S_No.", formatter: "rownum", hozAlign: "left", width: 40 },
    {
      title: "Name",
      field: "name",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
      // eventResizeFeature: true,
    },
    {
      title: "Email",
      field: "email",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Contact Number",
      field: "userContactNumber",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Device Issue",
      field: "userDeviceIssue",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Mobile Brand",
      field: "mobileBrand",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "Zipcode",
      field: "zipcode",
      hozAlign: "left",
      resizable: true,
      headerFilter: "input",
    },
    {
      title: "View Quotation",
      // field: "orderId",
      headerSort: false,
      width: 150,
      formatter: OrderFormatter,
      resizable: true,
    },
  ];

  return (
    <div>
      <ReactTabulator
        data={data}
        columns={columns}
        options={{
          layout: "fitColumns",
          pagination: "local", // Enable pagination
          paginationSize: 15, // Number of rows per page
          movableColumns: true, // Allow columns to be reordered
          paginationCounter: "rows",
          tooltips: true,
          resizableColumnWidths: false, // Set to false to prevent resize errors
          resizableRowWidths: false,
          movableRows: true,
          resizableColumns: true,
          // resizableRows: true,
          resizableColumnFit: true,
        }}
      />
    </div>
  );
};
export default OrderData;
