import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleResetPasswordLink = async (e) => {
    e.preventDefault();

    try {
      const previousPath = localStorage.getItem("prevPath");
      const isShopLogin = previousPath === "/shoplogin";
      console.log(isShopLogin);
      const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const API_ENDPOINT = isShopLogin
        ? "/api/shop/forgotPasswordLink"
        : "/api/users/forgotPasswordLink";
      const API = `${REACT_APP_API_BASE_URL}${API_ENDPOINT}`;
      const response = await axios.post(API, { email });
      setMessage(response.data.message);
      toast.success(response.data.message);
      setEmail("");
    } catch (error) {
      console.error(error);
      // setMessage("An error occurred");
      toast.error("Invalid User");
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow p-4">
            <div className="card-body">
              <h3 className="card-title text-center mb-4 fw-bolder">
                Reset Password
              </h3>
              <p className="text-muted text-center mb-4">
                Enter your email and we’ll send a link to reset your password.
              </p>
              {message && (
                <p className="my-3 text-center fw-bold text-success font-monospace">
                  {message}
                </p>
              )}
              <form onSubmit={handleResetPasswordLink}>
                <div className="form-group">
                  {/* <label htmlFor="email">Email address</label> */}
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className=" text-white w-full justify-center my-4 py-2  bg-indigo-500 shadow-lg shadow-indigo-800/50 hover:shadow-indigo-800/20 rounded-lg fw-bold"
                >
                  SEND LINK
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ResetPassword;
